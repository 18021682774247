import React, { useEffect, useState } from 'react';
import { makeStyles, ThemeProvider, createMuiTheme, responsiveFontSizes, useTheme} from '@material-ui/core/styles';
import {
    Typography,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    TableContainer,
    TablePagination,
    Paper,
    Grid,
    Button,
    TextField
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Visibility } from '@material-ui/icons';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import EsLocale from 'date-fns/locale/es';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import {
    Route,
    Switch,
    Link,
    Redirect
} from "react-router-dom";
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';
import DialogoDetalle from './DialogoDetalle'



const useStyles = makeStyles({
    table: {
        minWidth: 985,
    },
    menuItem: {
        fontSize: 14
    }
});

let themeDownloadButton = createMuiTheme({
    palette: {
        primary: {
            main: '#EF6C00'
        }
    },
    typography: {
        fontFamily: 'Roboto'
    }
});
themeDownloadButton = responsiveFontSizes(themeDownloadButton);

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                color='inherit'
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} color='inherit'>
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                color='inherit'
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                color='inherit'
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

function ConsultarAnticipos(props) {
    const classes = useStyles();
    const { theme, validRole, getAccessTokenWithRefresh } = props;
    const paths = props.paths;
    const [accessToken, setAccessToken] = props.accessToken;
    const API_DEFAULT = props.API_DEFAULT;
    const [anticipos, setAnticipos] = useState([]);
    const [anticipo, setAnticipo] = useState([]);
    const setSnack = props.setSnack;
    const setSeverity = props.setSeverity;
    const setMessage = props.setMessage;
    const setShowBackdrop = props.setShowBackdrop;
    const [openDetalle, setOpenDetalle] = useState(false);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [filtering, setFiltering] = useState(false);
    const [filtros, setFiltros] = useState(Array(7).fill(''));

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const compareObjects = (object1, object2, key) => {
        const obj1 = object1[key].toUpperCase()
        const obj2 = object2[key].toUpperCase()

        if (obj1 > obj2) {
            return -1
        }
        if (obj1 < obj2) {
            return 1
        }
        return 0
    }

    const emptyRows = rowsPerPage < anticipos.length ? rowsPerPage - Math.min(rowsPerPage, anticipos.length - page * rowsPerPage) : 0;

    async function fetchAnticipos(access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/legalizaciones/anticipos/inicio=${page * rowsPerPage}&fin=${page * rowsPerPage + rowsPerPage}&anticipo=${filtros[0] === '' ? 'all-elements' : filtros[0]}&estado=${filtros[1] === 'Todos' || filtros[1] === '' ? 'all-elements' : filtros[1]}&fecha=${filtros[2] === '' ? 'all-elements' : new Date(filtros[2]).toISOString().split('T')[0]}&estudio=${filtros[3] === '' ? 'all-elements' : filtros[3]}&cedula=${filtros[4] === '' ? 'all-elements' : filtros[4]}&nombre=${filtros[5] === '' ? 'all-elements' : filtros[5]}&valor=${filtros[6] === '' ? 'all-elements' : filtros[6]}&creador=all-elements`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
        })
        if (res.ok) {
            res.json().then(res => {
                let data = res[0];
                data = data.map(a => { let fecha = a.fecha.split('T')[0].split('-'); fecha = fecha.map(f => parseInt(f)); return ({ ...a, 'anticipo': `Anticipo ${a.id}`, 'fecha_envio': fecha }) })
                data = data.sort((a, b) => { return compareObjects(a, b, 'fecha') });
                setAnticipos(data);
                setCount(res[1]);
                //addPaths(res)
                setShowBackdrop(false);
            });
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        fetchAnticipos(newAccess);
                    }
                }
            });
        }

    }

    const clearDisabled = !filtros.some((v, i) => {
        if (i === 1 && (v !== 'Todos' && v !== '')) return true;
        else if (v !== '') return true;
        return false;
    });

    const clearFilters = () => {
        for (let i = 0; i < 7; i++) {
            filtros[i] = '';
        }
        filtros[1] = 'Todos';
        setFiltros([...filtros]);
    }

    useEffect(() => { clearFilters() }, []); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => { if (validRole) { fetchAnticipos() } }, [validRole, page, filtering, rowsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <Switch>
                <Route
                    exact
                    path={paths[1]}
                    render={() => (
                        <Card style={{ marginBottom: theme.spacing(2) }}>
                            <CardHeader
                                title={
                                    <Typography variant='h6'>Anticipos creados</Typography>
                                }
                            />
                            <Divider />
                            <CardContent>
                                <TableContainer component={Paper} elevation={0} variant="outlined">
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                            <TableCell style={{ fontSize: 16 }}>Detalle</TableCell>
                                                <TableCell style={{ fontSize: 16 }}>Anticipo</TableCell>
                                                <TableCell style={{ fontSize: 16 }}>Estado</TableCell>
                                                <TableCell style={{ fontSize: 16 }} align="right">Fecha de envío</TableCell>
                                                <TableCell style={{ fontSize: 16 }} align="right">Estudio</TableCell>
                                                <TableCell style={{ fontSize: 16 }} align="right">Doc. Encuestador</TableCell>
                                                <TableCell style={{ fontSize: 16 }} align="right">Supervisor/Encuestador</TableCell>
                                                <TableCell style={{ fontSize: 16 }} align="right">Valor</TableCell>
                                                
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} component='th' scope='row' align='center'>
                                                    <div style={{ display: 'flex' }}>
                                                        <ThemeProvider theme={themeDownloadButton}>
                                                            <IconButton
                                                                style={{ marginRight: 4 }}
                                                                size='small'
                                                                color='primary'
                                                                disabled={clearDisabled || filtering}
                                                                onClick={() => {
                                                                    setPage(0);
                                                                    setFiltering(true);
                                                                }}
                                                            >
                                                                <CheckIcon />
                                                            </IconButton>
                                                        </ThemeProvider>
                                                        <IconButton
                                                            size='small'
                                                            color='secondary'
                                                            onClick={() => {
                                                                clearFilters();
                                                                if (filtering) { setPage(0); setFiltering(false); }
                                                            }}
                                                            disabled={clearDisabled}
                                                        >
                                                            <ClearIcon />
                                                        </IconButton>
                                                    </div>
                                                </TableCell>
                                                <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>
                                                    <NumberFormat
                                                        customInput={TextField}
                                                        decimalSeparator={false}
                                                        allowNegative={false}
                                                        onFocus={(e) => e.target.select()}
                                                        inputProps={{
                                                            style: {
                                                                fontSize: 14,
                                                                padding: '7.69px 9.69px',
                                                                textAlign: 'right'
                                                            }
                                                        }}
                                                        value={filtros[0]}
                                                        onValueChange={(e) => { filtros[0] = e.value; setFiltros([...filtros]) }}
                                                        size='small'
                                                        variant='outlined'
                                                        fullWidth
                                                        disabled={filtering}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                                    <Autocomplete
                                                        value={filtros[1]}
                                                        onChange={(e, v) => { filtros[1] = v; setFiltros([...filtros]) }}
                                                        options={
                                                            ['Todos', 'Aprobado', 'Girado', 'Pendiente', 'Rechazado']
                                                        }
                                                        noOptionsText='No hay coincidencias'
                                                        disableClearable={true}
                                                        renderOption={(option) => {
                                                            if (option === 'Todas')
                                                                return (<span style={{ fontWeight: 500, fontSize: 14 }}>{option}</span>);
                                                            else
                                                                return (<span style={{ fontSize: 14 }}>{option}</span>);
                                                        }}
                                                        renderInput={params => (
                                                            <TextField
                                                                {...params}
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    style: {
                                                                        fontSize: 14,
                                                                        padding: '1.69px 3.69px'
                                                                    }
                                                                }}
                                                                size='small'
                                                                variant='outlined'
                                                                fullWidth
                                                                style={{ minWidth: 120 }}
                                                            />
                                                        )}
                                                        disabled={filtering}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={EsLocale}>
                                                        <DatePicker
                                                            value={filtros[2] === '' ? null : filtros[2]}
                                                            onChange={(date) => { filtros[2] = date; setFiltros([...filtros]) }}
                                                            size='small'
                                                            inputVariant='outlined'
                                                            format="dd/MM/yyyy"
                                                            fullWidth={true}
                                                            disableToolbar={true}
                                                            autoOk={true}
                                                            disabled={filtering}
                                                            disableFuture={true}
                                                            InputProps={{style:{height:'32px'}}}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </TableCell>
                                                <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                                    <TextField
                                                        inputProps={{
                                                            style: {
                                                                fontSize: 14,
                                                                padding: '7.69px 9.69px'
                                                            }
                                                        }}
                                                        value={filtros[3]}
                                                        onChange={(e) => { filtros[3] = e.target.value; setFiltros([...filtros]) }}
                                                        size='small'
                                                        variant='outlined'
                                                        fullWidth
                                                        disabled={filtering}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>
                                                    <NumberFormat
                                                        customInput={TextField}
                                                        decimalSeparator={false}
                                                        allowNegative={false}
                                                        onFocus={(e) => e.target.select()}
                                                        inputProps={{
                                                            style: {
                                                                fontSize: 14,
                                                                padding: '7.69px 9.69px',
                                                                textAlign: 'right'
                                                            }
                                                        }}
                                                        value={filtros[4]}
                                                        onValueChange={(e) => { filtros[4] = e.value; setFiltros([...filtros]) }}
                                                        size='small'
                                                        variant='outlined'
                                                        fullWidth
                                                        disabled={filtering}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                                    <TextField
                                                        inputProps={{
                                                            style: {
                                                                fontSize: 14,
                                                                padding: '7.69px 9.69px'
                                                            }
                                                        }}
                                                        value={filtros[5]}
                                                        onChange={(e) => { filtros[5] = e.target.value; setFiltros([...filtros]) }}
                                                        size='small'
                                                        variant='outlined'
                                                        fullWidth
                                                        disabled={filtering}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>
                                                    <NumberFormat
                                                        customInput={TextField}
                                                        decimalSeparator={false}
                                                        allowNegative={false}
                                                        onFocus={(e) => e.target.select()}
                                                        inputProps={{
                                                            style: {
                                                                fontSize: 14,
                                                                padding: '7.69px 9.69px',
                                                                textAlign: 'right'
                                                            }
                                                        }}
                                                        value={filtros[6]}
                                                        onValueChange={(e) => { filtros[6] = e.value; setFiltros([...filtros]) }}
                                                        size='small'
                                                        variant='outlined'
                                                        fullWidth
                                                        disabled={filtering}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            {anticipos.map((row) => (
                                                    <TableRow key={row.id}>
                                                        <TableCell style={{ fontSize: 16, paddingTop: 0, paddingBottom: 0 }} align="right">
                                                            <IconButton color='primary' style={{ marginRight: 12, marginLeft: 8 }} component={Link} size='small' onClick={() => { setAnticipo(row); setOpenDetalle(true) }}>
                                                                <Visibility style={{ height: 24, width: 'auto' }} />
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: 16, paddingTop: 0, paddingBottom: 0 }} component="th" scope="row">
                                                            {row.anticipo}
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: 16, paddingTop: 0, paddingBottom: 0 }}>{row.girado ? 'Girado' : (row.aprobado ? 'Aprobado' : (row.rechazado ? 'Rechazado' : 'Pendiente'))}</TableCell>
                                                        <TableCell style={{ fontSize: 16, paddingTop: 0, paddingBottom: 0 }} align='right'>{format(new Date(row.fecha_envio[0], row.fecha_envio[1] - 1, row.fecha_envio[2]), "dd/MM/yyyy")}</TableCell>
                                                        <TableCell style={{ fontSize: 16, paddingTop: 0, paddingBottom: 0 }} align="right">{row.estudio}</TableCell>
                                                        <TableCell style={{ fontSize: 16, paddingTop: 0, paddingBottom: 0 }} align="right">{row.encuestador.cedula}</TableCell>
                                                        <TableCell style={{ fontSize: 16, paddingTop: 0, paddingBottom: 0 }} align="right">{row.encuestador.nombre}</TableCell>
                                                        <TableCell style={{ fontSize: 16, paddingTop: 0, paddingBottom: 0 }} align="right">
                                                            <NumberFormat
                                                                value={row.valor}
                                                                displayType='text'
                                                                thousandSeparator={true}
                                                                decimalScale={0}
                                                                prefix='$'
                                                            />
                                                        </TableCell>
                                                        
                                                    </TableRow>
                                                ))}
                                            {emptyRows > 0 && (
                                                <TableRow style={{ height: 52.8 * emptyRows }}>
                                                    <TableCell />
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                    <TablePagination
                                        component='div'
                                        style={{ minWidth: 995 }}
                                        count={count}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        SelectProps={{
                                            style: {
                                                fontSize: 14
                                            }
                                        }}
                                        classes={{ menuItem: classes.menuItem }}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </TableContainer>
                                <Grid
                                    container
                                    direction='row'
                                    alignItems='center'
                                    justify='flex-end'
                                    spacing={1}
                                    style={{ marginTop: theme.spacing(1.5) }}
                                >
                                    <Grid item style={{ display: 'flex' }}>
                                        <Button component={Link} to={paths[0]} variant='contained' color='secondary'>Regresar</Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>

                    )}
                />




                <Route render={() => (<Redirect to={paths[1]} />)} />
            </Switch>
            {
                openDetalle ?
                    <DialogoDetalle
                        theme={theme}
                        anticipoId={anticipo.id}
                        open={[openDetalle, setOpenDetalle]}
                        setMessage={setMessage}
                        setSeverity={setSeverity}
                        setSnack={setSnack}
                        API_DEFAULT={API_DEFAULT}
                        accessToken={[accessToken, setAccessToken]}
                        setShowBackdrop={setShowBackdrop}
                        getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                        paths={paths}
                        validRole={validRole}
                        fetchAnticipos={fetchAnticipos}
                    />
                    :
                    null
            }

        </React.Fragment>
    );
}

export default ConsultarAnticipos;