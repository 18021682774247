import React, { useEffect, useState } from 'react';
import { makeStyles, ThemeProvider, createMuiTheme, responsiveFontSizes, useTheme } from '@material-ui/core/styles';
import {
    Typography,
    Card,
    CardContent,
    CardHeader,
    Divider,
    IconButton,
    TableContainer,
    TablePagination,
    Paper,
    Grid,
    Button,
    TextField
} from '@material-ui/core';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import EsLocale from 'date-fns/locale/es';

import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import {
    Route,
    Switch,
    Link,
    Redirect
} from "react-router-dom";
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';

import AgregarLegal from '../AgregarLegal/AgregarLegal';

const useStyles = makeStyles({
    table: {
        minWidth: 985,
    },
    menuItem: {
        fontSize: 14
    }
});

let themeDownloadButton = createMuiTheme({
    palette: {
        primary: {
            main: '#EF6C00'
        }
    },
    typography: {
        fontFamily: 'Roboto'
    }
});
themeDownloadButton = responsiveFontSizes(themeDownloadButton);

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                color='inherit'
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} color='inherit'>
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                color='inherit'
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                color='inherit'
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

function AnticiposRec(props) {
    const classes = useStyles();
    const { theme, validRole, getAccessTokenWithRefresh, getApiKeyRut } = props;
    const paths = props.paths;
    const [accessToken, setAccessToken] = props.accessToken;
    const API_DEFAULT = props.API_DEFAULT;
    const API_RUT = props.API_RUT;
    const API_KEY_RUT = props.API_KEY_RUT;
    const [supervPaths, setSupervPaths] = props.supervPaths;
    const [anticipos, setAnticipos] = useState([]);
    const setSnack = props.setSnack;
    const setSeverity = props.setSeverity;
    const setMessage = props.setMessage;
    const setShowBackdrop = props.setShowBackdrop;
    const setLabelShowBackdrop = props.setLabelShowBackdrop;
    const [continuar, setContinuar] = useState(false);
    const history = props.history;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [filtering, setFiltering] = useState(false);
    const [filtros, setFiltros] = useState(Array(5).fill(''));

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const compareObjects = (object1, object2, key) => {
        const obj1 = object1[key].toUpperCase()
        const obj2 = object2[key].toUpperCase()

        if (obj1 > obj2) {
            return -1
        }
        if (obj1 < obj2) {
            return 1
        }
        return 0
    }

    const emptyRows = rowsPerPage < anticipos.length ? rowsPerPage - Math.min(rowsPerPage, anticipos.length - page * rowsPerPage) : 0;

    async function fetchAnticipos(access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/legalizaciones/anticipos/inicio=${page * rowsPerPage}&fin=${page * rowsPerPage + rowsPerPage}&anticipo=${filtros[0] === '' ? 'all-elements' : filtros[0]}&estado=all-elements&fecha=${filtros[1] === '' ? 'all-elements' : new Date(filtros[1]).toISOString().split('T')[0]}&estudio=${filtros[2] === '' ? 'all-elements' : filtros[2]}&cedula=all-elements&nombre=all-elements&valor=${filtros[3] === '' ? 'all-elements' : filtros[3]}&creador=${filtros[4] === '' ? 'all-elements' : filtros[4]}`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
        })
        if (res.ok) {
            res.json().then(res => {
                let data = res[0];
                data = data.map(a => { let fecha = a.fecha.split('T')[0].split('-'); fecha = fecha.map(f => parseInt(f)); return ({ ...a, 'anticipo': `Anticipo ${a.id}`, 'fecha_envio': fecha }) })
                data = data.sort((a, b) => { return compareObjects(a, b, 'fecha') });
                setAnticipos(data);
                setCount(res[1]);
                addPaths(data);
                setShowBackdrop(false);
            });
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        fetchAnticipos(newAccess);
                    }
                }
            });
        }
    }

    const clearDisabled = !filtros.some((v, i) => {
        if (v !== '') return true;
        return false;
    });

    const clearFilters = () => {
        for (let i = 0; i < 5; i++) {
            filtros[i] = '';
        }
        setFiltros([...filtros]);
    }

    function addPaths(anticipos) {
        let paths = supervPaths;
        for (let i = 0; i < anticipos.length; i++) {
            paths = paths.concat(`/superv-enc/anticipos-recibidos/${anticipos[i].anticipo.toLowerCase().replace(' ', '-')}`)
            paths = paths.concat(`/superv-enc/anticipos-recibidos/${anticipos[i].anticipo.toLowerCase().replace(' ', '-')}/agregar-legalizacion`)
        }
        setSupervPaths(paths);
        setShowBackdrop(false);
    }

    useEffect(() => { clearFilters() }, []); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => { if (validRole) { fetchAnticipos() } }, [validRole, page, filtering, rowsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        return history.listen((location) => {
            if (!location.pathname.includes('agregar-legalizacion')) {
                setContinuar(false);
            }
            else {
                setContinuar(true);
            }
        })
    }, [history]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <Switch>
                <Route
                    exact
                    path={paths[1]}
                    render={() => (
                        <Card style={{ marginBottom: theme.spacing(2) }}>
                            <CardHeader
                                title={
                                    <Typography variant='h6'>Anticipos recibidos</Typography>
                                }
                            />
                            <Divider />
                            <CardContent>
                                <TableContainer component={Paper} elevation={0} variant="outlined">
                                    <Table>
                                        <Thead>
                                            <Tr>
                                                <Th style={{ fontSize: 16 }}>Anticipo</Th>
                                                <Th style={{ fontSize: 16 }} align="right">Fecha de envío</Th>
                                                <Th style={{ fontSize: 16 }} align="right">Estudio</Th>
                                                <Th style={{ fontSize: 16 }} align="right">Creador</Th>
                                                <Th style={{ fontSize: 16 }} align="right">Valor</Th>
                                                <Th style={{ fontSize: 16 }} align="right">Valor Aprobado</Th>
                                                <Th style={{ fontSize: 16 }} align="right">Añadir Legalización</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            <Tr>
                                                <Td style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>
                                                    <NumberFormat
                                                        customInput={TextField}
                                                        decimalSeparator={false}
                                                        allowNegative={false}
                                                        onFocus={(e) => e.target.select()}
                                                        inputProps={{
                                                            style: {
                                                                fontSize: 14,
                                                                padding: '7.69px 9.69px',
                                                                textAlign: 'right'
                                                            }
                                                        }}
                                                        value={filtros[0]}
                                                        onValueChange={(e) => { filtros[0] = e.value; setFiltros([...filtros]) }}
                                                        size='small'
                                                        variant='outlined'
                                                        fullWidth
                                                        disabled={filtering}
                                                    />
                                                </Td>
                                                <Td style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={EsLocale}>
                                                        <DatePicker
                                                            value={filtros[1] === '' ? null : filtros[1]}
                                                            onChange={(date) => { filtros[1] = date; setFiltros([...filtros]) }}
                                                            inputVariant='outlined'
                                                            format="dd/MM/yyyy"
                                                            fullWidth={true}
                                                            disableToolbar={true}
                                                            autoOk={true}
                                                            disabled={filtering}
                                                            disableFuture={true}
                                                            size={'small'}
                                                            InputProps={{style:{height:'32px'}}}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Td>
                                                <Td style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                                    <TextField
                                                        inputProps={{
                                                            style: {
                                                                fontSize: 14,
                                                                padding: '7.69px 9.69px'
                                                            }
                                                        }}
                                                        value={filtros[2]}
                                                        onChange={(e) => { filtros[2] = e.target.value; setFiltros([...filtros]) }}
                                                        size='small'
                                                        variant='outlined'
                                                        fullWidth
                                                        disabled={filtering}
                                                    />
                                                </Td>
                                                <Td style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                                    <TextField
                                                        inputProps={{
                                                            style: {
                                                                fontSize: 14,
                                                                padding: '7.69px 9.69px'
                                                            }
                                                        }}
                                                        value={filtros[4]}
                                                        onChange={(e) => { filtros[4] = e.target.value; setFiltros([...filtros]) }}
                                                        size='small'
                                                        variant='outlined'
                                                        fullWidth
                                                        disabled={filtering}
                                                    />
                                                </Td>
                                                <Td style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>
                                                    <NumberFormat
                                                        customInput={TextField}
                                                        decimalSeparator={false}
                                                        allowNegative={false}
                                                        onFocus={(e) => e.target.select()}
                                                        inputProps={{
                                                            style: {
                                                                fontSize: 14,
                                                                padding: '7.69px 9.69px',
                                                                textAlign: 'right'
                                                            }
                                                        }}
                                                        value={filtros[3]}
                                                        onValueChange={(e) => { filtros[3] = e.value; setFiltros([...filtros]) }}
                                                        size='small'
                                                        variant='outlined'
                                                        fullWidth
                                                        disabled={filtering}
                                                    />
                                                </Td>
                                                <Td style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'></Td>
                                                <Td style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} component='th' scope='row' align='right'>
                                                    <div style={{ display: 'inline' }}>
                                                        <ThemeProvider theme={themeDownloadButton}>
                                                            <IconButton
                                                                style={{ marginRight: 4 }}
                                                                size='small'
                                                                color='primary'
                                                                disabled={clearDisabled || filtering}
                                                                onClick={() => {
                                                                    setPage(0);
                                                                    setFiltering(true);
                                                                }}
                                                            >
                                                                <CheckIcon />
                                                            </IconButton>
                                                        </ThemeProvider>
                                                        <IconButton
                                                            size='small'
                                                            color='secondary'
                                                            onClick={() => {
                                                                clearFilters();
                                                                if (filtering) { setPage(0); setFiltering(false); }
                                                            }}
                                                            disabled={clearDisabled}
                                                        >
                                                            <ClearIcon />
                                                        </IconButton>
                                                    </div>
                                                </Td>
                                            </Tr>
                                            {anticipos.map((row) => (
                                                <Tr key={row.id}>
                                                    <Td style={{ fontSize: 16, paddingTop: 0, paddingBottom: 0 }} component="th" scope="row">
                                                        {row.anticipo}
                                                    </Td>
                                                    <Td style={{ fontSize: 16, paddingTop: 0, paddingBottom: 0 }} align='right'>
                                                        {format(new Date(row.fecha_envio[0], row.fecha_envio[1] - 1, row.fecha_envio[2]), "dd/MM/yyyy")}
                                                    </Td>
                                                    <Td style={{ fontSize: 16, paddingTop: 0, paddingBottom: 0 }} align="right">
                                                        {row.estudio}
                                                    </Td>
                                                    <Td style={{ fontSize: 16, paddingTop: 0, paddingBottom: 0 }} align="right">
                                                        {row.creador}
                                                    </Td>
                                                    <Td style={{ fontSize: 16, paddingTop: 0, paddingBottom: 0 }} align="right">
                                                        <NumberFormat
                                                            value={row.valor}
                                                            displayType='text'
                                                            thousandSeparator={true}
                                                            decimalScale={0}
                                                            prefix='$'
                                                        />
                                                    </Td>
                                                    <Td style={{ fontSize: 16, paddingTop: 0, paddingBottom: 0 }} align="right">
                                                        <NumberFormat
                                                            value={row.valor_legalizado !== undefined ? row.valor_legalizado : 0}
                                                            displayType='text'
                                                            thousandSeparator={true}
                                                            decimalScale={0}
                                                            prefix='$'
                                                        />
                                                    </Td>
                                                    <Td style={{ paddingTop: 0, paddingBottom: 0, height: 52.8 }} align="right">
                                                        <IconButton color='primary' style={{ marginRight: 12, marginLeft: 8 }} component={Link} size='small' to={`${paths[1]}/${row.anticipo.toLowerCase().replace(' ', '-')}`}>
                                                            <AddIcon style={{ height: 24, width: 'auto' }} />
                                                        </IconButton>
                                                    </Td>
                                                </Tr>
                                            ))}
                                            {emptyRows > 0 && (
                                                <Tr style={{ height: 52.8 * emptyRows }}>
                                                    <Td />
                                                </Tr>
                                            )}
                                        </Tbody>
                                    </Table>
                                    <TablePagination
                                        component='div'
                                        style={{ minWidth: 995 }}
                                        count={count}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        SelectProps={{
                                            style: {
                                                fontSize: 14
                                            }
                                        }}
                                        classes={{ menuItem: classes.menuItem }}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </TableContainer>
                                <Grid
                                    container
                                    direction='row'
                                    alignItems='center'
                                    justify='flex-end'
                                    spacing={1}
                                    style={{ marginTop: theme.spacing(1.5) }}
                                >
                                    <Grid item style={{ display: 'flex' }}>
                                        <Button component={Link} to={paths[0]} variant='contained' color='secondary'>Regresar</Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    )}
                />
                {anticipos.map(a =>
                    <Route
                        exact
                        path={`${paths[1]}/${a.anticipo.toLowerCase().replace(' ', '-')}${continuar ? '/agregar-legalizacion' : ''}`}
                        render={() => (
                            <AgregarLegal
                                anticipo={[a.id, a.anticipo]}
                                theme={theme}
                                path={paths[1]}
                                API_DEFAULT={API_DEFAULT}
                                API_RUT={API_RUT}
                                API_KEY_RUT={API_KEY_RUT}
                                accessToken={[accessToken, setAccessToken]}
                                getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                                getApiKeyRut={getApiKeyRut}
                                setSnack={setSnack}
                                setSeverity={setSeverity}
                                setMessage={setMessage}
                                setShowBackdrop={setShowBackdrop}
                                setLabelShowBackdrop={setLabelShowBackdrop}
                                estudios={[{ nombre_integra: a.estudio }]}
                                continuar={[continuar, setContinuar]}
                            />
                        )}
                    />
                )}
                <Route render={() => (<Redirect to={paths[1]} />)} />
            </Switch>
        </React.Fragment>
    );
}

export default AnticiposRec;
