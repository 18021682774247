import React, { useState } from 'react';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import {
    Typography,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Collapse,
    Grid,
    Button
} from '@material-ui/core';
import {
    Link
} from "react-router-dom";

import General from './General';
import Alojamiento from './Alojamiento/Alojamiento';
import Manutencion from './Manutencion/Manutencion';
import Transporte from './Transporte/Transporte';
import Otros from './Otros/Otros';
import Devolucion from './Devolucion/Devolucion';
import DialogoDiferencias from './DialogoDiferencias';

let themeDownloadButton = createMuiTheme({
    palette: {
        primary: {
            main: '#0277BD'
        }
    },
    typography: {
        fontFamily: 'Roboto'
    }
});
themeDownloadButton = responsiveFontSizes(themeDownloadButton);

function AgregarLegal(props) {
    const { theme, getAccessTokenWithRefresh, getApiKeyRut } = props;
    const path = props.path;
    const [accessToken, setAccessToken] = props.accessToken;
    const API_DEFAULT = props.API_DEFAULT;
    const API_RUT = props.API_RUT;
    const API_KEY_RUT = props.API_KEY_RUT;
    const setSnack = props.setSnack;
    const setSeverity = props.setSeverity;
    const setMessage = props.setMessage;
    const setShowBackdrop = props.setShowBackdrop;
    const setLabelShowBackdrop = props.setLabelShowBackdrop;

    const [continuar, setContinuar] = props.continuar;

    const [id, anticipo] = props.anticipo;

    const [estudio, setEstudio] = useState(null);
    const [fechaLegalizacion, setFechaLegalizacion] = useState(new Date());

    const [rubro, setRubro] = useState(null); // set null
    const [iva, setIva] = useState(null); // set null
    const [archivos, setArchivos] = useState(Array(2).fill(null));
    const [verDiferencias, setVerDiferencias] = useState(false);

    const estudios = props.estudios;

    function cargarArchivo(file, index) {
        if (file !== undefined) {
            console.log(file.type)
            if (file.type.split('/')[0] === 'image' || file.type.split('/')[1] === 'pdf') {
                archivos[index] = file;
                setArchivos([...archivos]);
            }
            else {
                setMessage('El tipo de archivo seleccionado no es reconocido.');
                setSeverity('warning');
                setTimeout(() => { setSnack(true) }, 0);
            }
        }
    }

    function limpiarGeneral() {
        setEstudio(null);
        setRubro(null);
        setIva(null);
        setFechaLegalizacion(new Date());
    }

    return (
        <React.Fragment>
            <Card style={{ marginBottom: theme.spacing(2) }}>
                <CardHeader
                    title={
                        <React.Fragment>
                            <Typography variant='subtitle2'>{anticipo}</Typography>
                            <Typography variant='h6'>&nbsp;Agregar legalización</Typography>
                        </React.Fragment>
                    }
                />
                <Divider />
                <CardContent>
                    {!continuar ?
                        <React.Fragment>
                            <Typography variant='body1'>Si tiene alguna <span style={{ fontWeight: 500 }}>duda con respecto al tipo de documento que va a legalizar</span>, puede comunicarse con los siguientes números:</Typography>
                            <Grid
                                container
                                direction='row'
                                alignItems='center'
                                spacing={2}
                                style={{ marginTop: theme.spacing(0) }}
                            >
                                <Grid item xs={6}>
                                    <Typography variant='body1'><span style={{ fontWeight: 500 }}>Bogotá</span></Typography>
                                    <Typography variant='body1' style={{ marginLeft: theme.spacing(1) }}>Patricia Jiménez: (+57) 312 5230980</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant='body1'><span style={{ fontWeight: 500 }}>Medellín</span></Typography>
                                    <Typography variant='body1' style={{ marginLeft: theme.spacing(1) }}>Mayerly Zapata: (+57) 315 7479539</Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                direction='row'
                                alignItems='center'
                                spacing={2}
                            >
                                <Grid item xs={6}>
                                    <Typography variant='body1'><span style={{ fontWeight: 500 }}>Cali</span></Typography>
                                    <Typography variant='body1' style={{ marginLeft: theme.spacing(1) }}>Gina Marcela Sánchez: (+57) 318 7562957</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant='body1'><span style={{ fontWeight: 500 }}>Barranquilla</span></Typography>
                                    <Typography variant='body1' style={{ marginLeft: theme.spacing(1) }}>Diego Nagles: (+57) 300 4521599</Typography>
                                </Grid>
                            </Grid>
                            <Divider style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}/>
                            <Typography variant='body1'>Recuerde que para hacer la devolución del dinero sobrante, debe realizar una consignación a la 
                            <span style={{ fontWeight: 500 }}> Cuenta Corriente del Banco de Bogotá No. 106-32877-6</span>
                            , a nombre del <span style={{ fontWeight: 500 }}>Centro Nacional de Consultoría</span>. 
                            NIT: <span style={{ fontWeight: 500 }}>800.011.951-9</span>. 
                            Teléfono: <span style={{ fontWeight: 500 }}>(601) 339 4888.</span></Typography>
                            <Grid
                                container
                                direction='row'
                                alignItems='center'
                                justify='flex-end'
                                spacing={1}
                                style={{ marginTop: theme.spacing(1.5) }}
                            >
                                <Grid item style={{ display: 'flex' }}>
                                    <Button component={Link} to={`${path}/anticipo-${id}/agregar-legalizacion`} replace={true} style={{ marginRight: theme.spacing(1) }} variant='contained' color='primary' onClick={() => setContinuar(true)}>Continuar</Button>
                                    <Button component={Link} to={path} variant='contained' color='secondary'>Regresar</Button>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <General
                                theme={theme}
                                rubro={[rubro, setRubro]}
                                API_DEFAULT={API_DEFAULT}
                                accessToken={accessToken}
                                estudio={[estudio, setEstudio]}
                                fechaLegalizacion={[fechaLegalizacion, setFechaLegalizacion]}
                                limpiar={limpiarGeneral}
                                setIva={setIva}
                                estudios={estudios}
                            />
                            <Collapse in={rubro === 0 && estudio !== null} mountOnEnter unmountOnExit>
                                <Alojamiento
                                    theme={theme}
                                    path={path}
                                    API_DEFAULT={API_DEFAULT}
                                    API_RUT={API_RUT}
                                    API_KEY_RUT={API_KEY_RUT}
                                    accessToken={[accessToken, setAccessToken]}
                                    getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                                    getApiKeyRut={getApiKeyRut}
                                    idAnticipo={id}
                                    estudio={estudio}
                                    fechaLegalizacion={fechaLegalizacion}
                                    rubro={rubro}
                                    iva={[iva, setIva]}
                                    archivos={[archivos, setArchivos]}
                                    cargarArchivo={cargarArchivo}
                                    setVerDiferencias={setVerDiferencias}
                                    limpiar={limpiarGeneral}
                                    setSnack={setSnack}
                                    setSeverity={setSeverity}
                                    setMessage={setMessage}
                                    setShowBackdrop={setShowBackdrop}
                                    setLabelShowBackdrop={setLabelShowBackdrop}
                                />
                            </Collapse>
                            <Collapse in={rubro === 1 && estudio !== null} mountOnEnter unmountOnExit>
                                <Manutencion
                                    theme={theme}
                                    path={path}
                                    API_DEFAULT={API_DEFAULT}
                                    API_RUT={API_RUT}
                                    API_KEY_RUT={API_KEY_RUT}
                                    accessToken={[accessToken, setAccessToken]}
                                    getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                                    getApiKeyRut={getApiKeyRut}
                                    idAnticipo={id}
                                    estudio={estudio}
                                    fechaLegalizacion={fechaLegalizacion}
                                    rubro={rubro}
                                    iva={[iva, setIva]}
                                    archivos={[archivos, setArchivos]}
                                    cargarArchivo={cargarArchivo}
                                    setVerDiferencias={setVerDiferencias}
                                    limpiar={limpiarGeneral}
                                    setSnack={setSnack}
                                    setSeverity={setSeverity}
                                    setMessage={setMessage}
                                    setShowBackdrop={setShowBackdrop}
                                    setLabelShowBackdrop={setLabelShowBackdrop}
                                />
                            </Collapse>
                            <Collapse in={rubro === 2 && estudio !== null} mountOnEnter unmountOnExit>
                                <Transporte
                                    theme={theme}
                                    path={path}
                                    API_DEFAULT={API_DEFAULT}
                                    API_RUT={API_RUT}
                                    API_KEY_RUT={API_KEY_RUT}
                                    accessToken={[accessToken, setAccessToken]}
                                    getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                                    getApiKeyRut={getApiKeyRut}
                                    idAnticipo={id}
                                    estudio={estudio}
                                    fechaLegalizacion={fechaLegalizacion}
                                    rubro={rubro}
                                    iva={[iva, setIva]}
                                    archivos={[archivos, setArchivos]}
                                    cargarArchivo={cargarArchivo}
                                    setVerDiferencias={setVerDiferencias}
                                    limpiarGeneral={limpiarGeneral}
                                    setSnack={setSnack}
                                    setSeverity={setSeverity}
                                    setMessage={setMessage}
                                    setShowBackdrop={setShowBackdrop}
                                    setLabelShowBackdrop={setLabelShowBackdrop}
                                />
                            </Collapse>
                            <Collapse in={rubro === 3 && estudio !== null} mountOnEnter unmountOnExit>
                                <Otros
                                    theme={theme}
                                    path={path}
                                    API_DEFAULT={API_DEFAULT}
                                    API_RUT={API_RUT}
                                    API_KEY_RUT={API_KEY_RUT}
                                    accessToken={[accessToken, setAccessToken]}
                                    getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                                    getApiKeyRut={getApiKeyRut}
                                    idAnticipo={id}
                                    estudio={estudio}
                                    fechaLegalizacion={fechaLegalizacion}
                                    rubro={rubro}
                                    iva={[iva, setIva]}
                                    archivos={[archivos, setArchivos]}
                                    cargarArchivo={cargarArchivo}
                                    setVerDiferencias={setVerDiferencias}
                                    limpiar={limpiarGeneral}
                                    setSnack={setSnack}
                                    setSeverity={setSeverity}
                                    setMessage={setMessage}
                                    setShowBackdrop={setShowBackdrop}
                                    setLabelShowBackdrop={setLabelShowBackdrop}
                                />
                            </Collapse>
                            <Collapse in={rubro === 4 && estudio !== null} mountOnEnter unmountOnExit>
                                <Devolucion
                                    theme={theme}
                                    path={path}
                                    API_DEFAULT={API_DEFAULT}
                                    accessToken={[accessToken, setAccessToken]}
                                    getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                                    idAnticipo={id}
                                    estudio={estudio}
                                    fechaLegalizacion={fechaLegalizacion}
                                    rubro={rubro}
                                    iva={[iva, setIva]}
                                    archivos={[archivos, setArchivos]}
                                    cargarArchivo={cargarArchivo}
                                    setVerDiferencias={setVerDiferencias}
                                    limpiar={limpiarGeneral}
                                    setSnack={setSnack}
                                    setSeverity={setSeverity}
                                    setMessage={setMessage}
                                    setShowBackdrop={setShowBackdrop}
                                    setLabelShowBackdrop={setLabelShowBackdrop}
                                />
                            </Collapse>
                            <Collapse in={rubro === null} mountOnEnter unmountOnExit>
                                <Grid
                                    container
                                    direction='row'
                                    alignItems='center'
                                    justify='flex-end'
                                    spacing={1}
                                    style={{ marginTop: theme.spacing(1.5) }}
                                >
                                    <Grid item style={{ display: 'flex' }}>
                                        {estudio !== null ?
                                            <ThemeProvider theme={themeDownloadButton}>
                                                <Button style={{ marginRight: theme.spacing(1) }} variant='contained' color='primary' onClick={() => limpiarGeneral()}>Limpiar</Button>
                                            </ThemeProvider>
                                            :
                                            null
                                        }
                                        <Button component={Link} to={path} variant='contained' color='secondary' onClick={() => setContinuar(false)}>Regresar</Button>
                                    </Grid>
                                </Grid>
                            </Collapse>
                        </React.Fragment>
                    }
                </CardContent>
            </Card>
            <DialogoDiferencias
                theme={theme}
                open={[verDiferencias, setVerDiferencias]}
            />
        </React.Fragment >
    );
}

export default AgregarLegal;