import React, { useEffect } from 'react';
import {
    Typography,
    Button,
    Grid,
    Card,
    CardContent,
    CardHeader,
    Divider
} from '@material-ui/core';
import {
    Link
} from "react-router-dom";

function MenuFin(props) {
    const theme = props.theme;
    const username = props.username;
    const paths = props.paths;
    const [financPaths, setfinancPaths] = props.financPaths;
    const financPathsIni = ['/financiera/consultar-anticipos',
        '/financiera/consultar-legalizaciones'];

    function clearPaths() {
        if (financPaths !== financPathsIni) {
            setfinancPaths(financPathsIni)
        }
    }

    useEffect(() => {
        clearPaths(); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Card style={{ marginBottom: theme.spacing(2) }}>
            <CardHeader
                title={
                    <Typography variant='h6'>Rol: Financiera</Typography>
                }
            />
            <Divider />
            <CardContent>
                <Grid
                    container
                    direction='column'
                    alignItems='center'
                    justify='center'
                    style={{ marginTop: theme.spacing(1) }}
                >
                    <Grid item>
                        <Typography style={{ fontSize: 17 }} variant='body1'>¡Hola {username}!</Typography>
                    </Grid>
                    <Grid item style={{ marginBottom: theme.spacing(3) }}>
                        <Typography style={{ fontSize: 18 }} align='center' variant='body1'>Bienvenido(a) al aplicativo de legalizaciones</Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    justify='center'
                    spacing={1}
                >
                    <Grid item style={{ display: 'flex', justifyContent: 'center' }} xs={12} md={3}>
                        <Button component={Link} style={{ maxWidth: 450 }} fullWidth={true} to={paths[1]} variant='contained' color='primary'>Ver anticipos</Button>
                    </Grid>
                    <Grid item style={{ display: 'flex', justifyContent: 'center' }} xs={12} md={3}>
                        <Button component={Link} style={{ maxWidth: 450 }} fullWidth={true} to={paths[2]} variant='contained' color='secondary'>Ver legalizaciones</Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default MenuFin;