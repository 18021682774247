import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';


import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

import {
    Typography,
    Card,
    CardContent,
    CardHeader,
    Divider,
    // Table,
    // Thead,
    // Tbody,
    // Tr,
    // Td,
    IconButton,
    TableContainer,
    TablePagination,
    Paper,
    Grid,
    Button,
    TextField,
    InputAdornment
} from '@material-ui/core';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import {
    Route,
    Switch,
    Link,
    Redirect
} from "react-router-dom";
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';
import General from '../EditarLegalizaciones/General'
import BodyFormato from '../EditarLegalizaciones/BodyFormato';
// import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles({
    table: {
        minWidth: 985,
    },
    menuItem: {
        fontSize: 14
    }
});

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

const colores = [{estado: 'Nueva' , color:'#0277BD'},
{estado: 'Aprobada' , color:'#01A307'},
{estado: 'Preaprobada' , color:'#00695C'},
{estado: 'Rechazada' , color:'#FF0000'},
{estado: 'Pendiente' , color:'#FF8700'}]



function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                color='inherit'
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} color='inherit'>
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                color='inherit'
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                color='inherit'
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}





function ConsultarLegalizaciones(props) {
    const classes = useStyles();
    const { validRole, theme, paths, API_DEFAULT, setSnack, setSeverity, setMessage, setShowBackdrop, getAccessTokenWithRefresh} = props;
    const [accessToken, setAccessToken] = props.accessToken;
    const [supervPaths, setSupervPaths] = props.supervPaths;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [legalizaciones, setLegalizaciones] = useState([]);
    const [busqueda, setBusqueda] = useState('');
    const [count, setCount] = useState(0);


    const RUBROS = [
        {index: 1, nombre: 'Alojamiento'},
        {index: 2, nombre: 'Manutención'},
        {index: 3, nombre: 'Transporte'},
        {index: 4, nombre: 'Otros'},
        {index: 5, nombre: 'Me sobró dinero'}
    ]

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const compareObjects = (object1, object2, key) => {
        const obj1 = object1[key].toUpperCase()
        const obj2 = object2[key].toUpperCase()

        if (obj1 > obj2) {
            return -1
        }
        if (obj1 < obj2) {
            return 1
        }
        return 0
    }

    function addPaths(legalizaciones) {
        let paths = supervPaths;
        legalizaciones.map(l =>
            paths = paths.concat(`/superv-enc/consultar-legalizaciones/anticipo-${l.anticipo}/legalizacion-${l.id}`)
        )
        setSupervPaths(paths);
        setShowBackdrop(false);
    }

    const emptyRows = rowsPerPage < legalizaciones.length ? rowsPerPage - Math.min(rowsPerPage, legalizaciones.length - page * rowsPerPage) : 0;

    async function fetchLegalizaciones(access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/legalizaciones/legalizaciones/?inicio=${page * rowsPerPage}&fin=${page * rowsPerPage + rowsPerPage}`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
        })
        if (res.ok) {
            res.json().then(res => {
                let data = res[0];
                data = data.map(a => { let fecha = a.fecha.split('T')[0].split('-'); fecha = fecha.map(f => parseInt(f)); return ({ ...a, 'legalizacion': `Legalización ${a.id}`, 'fecha_envio': fecha }) })
                data = data.map(l => {let estado = (l.nuevo?'Nueva':(l.aprobado?(l.aprobado_financiera?'Aprobada':(l.rechazado_financiera?'Rechazada':'Preaprobada')):(l.rechazada?'Rechazada':'Pendiente'))); return ({...l, 'estado': estado})}) //Nueva rechazada y aprobada
                data = data.sort((a, b) => { return compareObjects(a, b, 'fecha') });
                setLegalizaciones(data);
                console.log(data)
                addPaths(data);
                setCount(res[1]);
                setShowBackdrop(false);
            });
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        fetchLegalizaciones(newAccess);
                    }
                }
            });
        }
        
    }

    

    useEffect(() => { if (validRole) { fetchLegalizaciones() } }, [validRole, page, rowsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <React.Fragment>
            <Switch>
                <Route
                    exact
                    path={paths[3]}
                    render={() => (
                        <Card style={{ marginBottom: theme.spacing(2)}}>
                            <CardHeader
                                title={
                                    <Typography variant='h6'>Legalizaciones Creadas</Typography>
                                }
                                action={
                                    <TextField
                                        value={busqueda}
                                        onChange={(e) => {setBusqueda(e.target.value)}}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                                ),
                                        }}
                                    />
                                }
                            />
                            <Divider />
                            <CardContent>
                                <TableContainer component={Paper} elevation={0} variant="outlined">
                                    <Table>
                                        <Thead>
                                            <Tr>
                                                <Th style={{ fontSize: 16 }}>Legalización</Th>
                                                <Th style={{ fontSize: 16 }}>Anticipo</Th>
                                                <Th style={{ fontSize: 16 }} align="center">Estudio</Th>
                                                <Th style={{ fontSize: 16 }} align="right">Fecha de Envio</Th>
                                                <Th style={{ fontSize: 16 }} align="right">Valor</Th>
                                                <Th style={{ fontSize: 16 }} align="right">Rubro</Th>
                                                <Th style={{ fontSize: 16 }} align="right">Estado</Th>
                                                <Th style={{ fontSize: 16 }} align="right">Ver Detalles</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {legalizaciones.filter(
                                                (row) =>(
                                                    row.id.toString().includes(busqueda.toLowerCase())
                                                    || row.anticipo.toString().includes(busqueda.toLowerCase())
                                                    || row.estudio.toLowerCase().includes(busqueda.toLowerCase())
                                                    || row.valor.toLowerCase().includes(busqueda.toLowerCase())
                                                )
                                            ).map((row) => (
                                                    <Tr key={row.id}>
                                                        <Td style={{ fontSize: 16, paddingTop: 0, paddingBottom: 0 }} component="th" scope="row">
                                                            {row.legalizacion}
                                                        </Td>
                                                        <Td style={{ fontSize: 16, paddingTop: 0, paddingBottom: 0 }} component="th" scope="row">
                                                            {"Anticipo " + row.anticipo}
                                                        </Td>
                                                        <Td style={{ fontSize: 16, paddingTop: 0, paddingBottom: 0 }} component="th" scope="row">
                                                            {row.estudio}
                                                        </Td>
                                                        <Td style={{ fontSize: 16, paddingTop: 0, paddingBottom: 0 }} align='right'>{format(new Date(row.fecha_envio[0], row.fecha_envio[1] - 1, row.fecha_envio[2]), "dd/MM/yyyy")}</Td>
                                                        <Td style={{ fontSize: 16, paddingTop: 0, paddingBottom: 0 }} align="right">
                                                            <NumberFormat
                                                                value={row.valor}
                                                                displayType='text'
                                                                thousandSeparator={true}
                                                                decimalScale={0}
                                                                prefix='$'
                                                            />
                                                        </Td>
                                                        <Td style={{ fontSize: 16, paddingTop: 0, paddingBottom: 0 }} align="right">{RUBROS.find(r => r.index === row.rubro).nombre}</Td>
                                                        <Td style={{ fontSize: 16, paddingTop: 0, paddingBottom: 0 }} align="right">
                                                            <span style={{color: colores.filter(c => c.estado === row.estado)[0].color }}>
                                                                {row.estado}
                                                            </span>
                                                        </Td>
                                                        <Td style={{ paddingTop: 0, paddingBottom: 0, height: 52.8 }} align="right">
                                                            <IconButton size='small' component={Link} to={`${paths[3]}/anticipo-${row.anticipo}/legalizacion-${row.id}`}>
                                                                <FindInPageIcon color={'primary'} />
                                                            </IconButton>
                                                        </Td>
                                                    </Tr>
                                                ))}
                                            {emptyRows > 0 && (
                                                <Tr style={{ height: 52.8 * emptyRows }}>
                                                    <Td />
                                                </Tr>
                                            )}
                                            </Tbody>
                                    </Table>
                                    <TablePagination
                                        component="div"
                                        count={count}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        SelectProps={{
                                            style: {
                                                fontSize: 14
                                            }
                                        }}
                                        classes={{ menuItem: classes.menuItem }}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </TableContainer>
                                <Grid
                                    container
                                    direction='row'
                                    alignItems='center'
                                    justify='flex-end'
                                    spacing={1}
                                    style={{ marginTop: theme.spacing(1.5) }}
                                >
                                    <Grid item style={{ display: 'flex' }}>
                                        <Button component={Link} to={paths[0]} variant='contained' color='secondary'>Regresar</Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                      
                    )}
                />
                {legalizaciones.map(l =>
                    <Route
                        exact
                        path={`${paths[3]}/anticipo-${l.anticipo}/legalizacion-${l.id}`}
                        render={() => {
                            return (
                                <React.Fragment>
                                    <Card style={{ marginBottom: theme.spacing(2) }}>
                                        <CardHeader
                                            title={
                                                <React.Fragment>
                                                    <Typography variant='subtitle2'>Anticipo {l.anticipo}</Typography>
                                                    <Typography variant='h6'>&nbsp;Legalización {l.id}</Typography>
                                                </React.Fragment>
                                            }
                                            action={
                                                <Typography variant='h6' style={{ marginRight: theme.spacing(1), marginTop: theme.spacing(2.5) }}>Estado: <span style={{ fontSize: 'inherit', color: (l.rechazada ? '#0277BD' : l.aprobado?l.aprobado_financiera?'#00695C':l.rechazado_financiera?'#0277BD':'#FF8700':'#FF8700') }}>{(l.rechazada ? 'Rechazada' : l.aprobado?l.aprobado_financiera?'Aprobada Financiera':l.rechazado_financiera?'Rechazada Financiera':'Pendiente Financiera':'Pendiente')}</span></Typography>
                                            }
                                        />
                                        <Divider />
                                        <CardContent>
                                            <General
                                                validRole={validRole}
                                                getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                                                theme={theme}
                                                estudio={l.estudio}
                                                fecha={l.fecha}
                                                id={l.id}
                                                API_DEFAULT={API_DEFAULT}
                                                accessToken={[accessToken, setAccessToken]}
                                            />
                                            <BodyFormato
                                                validRole={validRole}
                                                getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                                                theme={theme}
                                                anticipoId={l.anticipo.id}
                                                id={l.id}
                                                API_DEFAULT={API_DEFAULT}
                                                accessToken={[accessToken, setAccessToken]}
                                                setShowBackdrop={setShowBackdrop}
                                                setSeverity={setSeverity}
                                                setMessage={setMessage}
                                                setSnack={setSnack}
                                                rubro={l.rubro}
                                                valor={l.valor}
                                                numFactura={l.num_factura}
                                                iva={l.responsable}
                                                path={`${paths[3]}/anticipo-${l.anticipo}`}
                                            />
                                        </CardContent>
                                    </Card>
                                </React.Fragment>
                            )
                        }}
                    />
                )}
                {<Route render={() => (<Redirect to={paths[3]} />)} />}
            </Switch>
        </React.Fragment>
    );
}

export default ConsultarLegalizaciones;