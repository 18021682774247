import React from 'react';
import {
    Route,
    Switch,
    Redirect
} from "react-router-dom";

import MenuFinanciera from './MenuFin';
import ConsultarLegalizaciones from './RevisarLegalizaciones/ConsultarLegalizaciones'
import ConsultarAnticipos from './RevisarAnticipos/ConsultarAnticipos';

function Financiera(props) {
    const { theme, validRole, getAccessTokenWithRefresh} = props;
    const username = props.username;
    const paths = props.paths;
    const [accessToken, setAccessToken] = props.accessToken;
    const API_DEFAULT = props.API_DEFAULT;
    const [financPaths, setFinancPaths] = props.financPaths;
    const setSnack = props.setSnack;
    const setSeverity = props.setSeverity;
    const setMessage = props.setMessage;
    const setShowBackdrop = props.setShowBackdrop;
    const history = props.history;

    return (
        <React.Fragment>
            <Switch>
                <Route
                    exact
                    path={paths[0]}
                    render={() => (
                        <MenuFinanciera
                            theme={theme}
                            username={username}
                            paths={paths}
                            financPaths={[financPaths, setFinancPaths]}
                        />
                    )}
                />
                <Route
                    path={paths[1]}
                    render={() => (
                        <React.Fragment>
                            <Redirect to={paths[1]} />
                            <ConsultarAnticipos
                                validRole={validRole}
                                theme={theme}
                                paths={paths}
                                financPaths={[financPaths, setFinancPaths]}
                                API_DEFAULT={API_DEFAULT}
                                accessToken={[accessToken, setAccessToken]}
                                setSnack={setSnack}
                                setSeverity={setSeverity}
                                setMessage={setMessage}
                                setShowBackdrop={setShowBackdrop}
                                history={history}
                                getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                            />
                        </React.Fragment>
                    )}
                />
                <Route
                    path={paths[2]}
                    render={() => (
                        <React.Fragment>
                            <Redirect to={paths[2]} />
                            <ConsultarLegalizaciones
                                validRole={validRole}
                                theme={theme}
                                paths={paths}
                                financPaths={[financPaths, setFinancPaths]}
                                API_DEFAULT={API_DEFAULT}
                                accessToken={[accessToken, setAccessToken]}
                                setSnack={setSnack}
                                setSeverity={setSeverity}
                                setMessage={setMessage}
                                setShowBackdrop={setShowBackdrop}
                                history={history}
                                getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                            />
                        </React.Fragment>
                    )}
                />
                <Route render={() => (<Redirect to={paths[0]} />)} />
            </Switch>
        </React.Fragment>
    );
}

export default Financiera;