import React, { useEffect, useState, useRef } from 'react';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    Typography,
    Grid,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Paper,
    Button,
    IconButton,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    TablePagination,
    Table,
    TableHead,
    Collapse,
    Box,
    Tooltip,
    TextField
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import EsLocale from 'date-fns/locale/es';

import {
    Route,
    Link,
    Switch,
    Redirect
} from 'react-router-dom';
import NumberFormat from 'react-number-format';

import { format } from 'date-fns';

import General from './General';
import BodyFormato from './BodyFormato';
import DialogoDescargaMovimientos from './DialogoDescargaMovimientos';
import myExcelXML from '../../../data/excelXML';

let themeDownloadButton = createMuiTheme({
    palette: {
        primary: {
            main: '#EF6C00'
        }
    },
    typography: {
        fontFamily: 'Roboto'
    }
});
themeDownloadButton = responsiveFontSizes(themeDownloadButton);

const useStyles = makeStyles({
    table: {
        minWidth: 995,
    },
    menuItem: {
        fontSize: 14
    },
    row: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                color='inherit'
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} color='inherit'>
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                color='inherit'
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                color='inherit'
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

function Row(props) {
    const classes = useStyles();
    const row = props.row;
    const paths = props.paths;
    const [openRow, setOpenRow] = props.openRow;
    const rowRefs = props.rowRefs;

    function rubroToString(rubro) {
        if (rubro === 1) {
            return 'Alojamiento'
        }
        else if (rubro === 2) {
            return 'Manutención'
        }
        else if (rubro === 3) {
            return 'Transporte'
        }
        else if (rubro === 4) {
            return 'Otros'
        }
        return 'Me sobró dinero'
    }

    return (
        <React.Fragment>
            <TableRow ref={rowRefs.current[row.id]} className={classes.row}>
                <TableCell style={{ fontSize: 16, paddingTop: 0, paddingBottom: 0 }} component='th' scope='row'></TableCell>
                <TableCell style={{ fontSize: 16, paddingTop: 0, paddingBottom: 0 }} component='th' scope='row'>
                    {row.anticipo}
                </TableCell>
                <TableCell style={{ fontSize: 16, paddingTop: 0, paddingBottom: 0 }} align='right'>{format(new Date(row.fecha_envio[0], row.fecha_envio[1] - 1, row.fecha_envio[2]), "dd/MM/yyyy")}</TableCell>
                <TableCell style={{ fontSize: 16, paddingTop: 0, paddingBottom: 0 }} width='100px'>{row.estudio}</TableCell>
                <TableCell style={{ fontSize: 16, paddingTop: 0, paddingBottom: 0 }} align='right'>{row.encuestador.cedula}</TableCell>
                <TableCell style={{ fontSize: 16, paddingTop: 0, paddingBottom: 0 }} >{row.encuestador.nombre}</TableCell>
                <TableCell style={{ fontSize: 16, paddingTop: 0, paddingBottom: 0 }} align='right'>
                    <NumberFormat
                        value={row.valor}
                        displayType='text'
                        thousandSeparator={true}
                        decimalScale={0}
                        prefix='$'
                    />
                </TableCell>
                <TableCell style={{ fontSize: 16, paddingTop: 0, paddingBottom: 0 }} align='right'>{row.legalizacion_set.length}</TableCell>
                <TableCell style={{ fontSize: 16, paddingTop: 0, paddingBottom: 0 }} align='right'>
                    <NumberFormat
                        value={row.valor_legalizado}
                        displayType='text'
                        thousandSeparator={true}
                        decimalScale={0}
                        prefix='$'
                    />
                </TableCell>
                <TableCell style={{ fontSize: 16, paddingTop: 0, paddingBottom: 0 }} align='right'>
                    <NumberFormat
                        value={row.valor - row.valor_legalizado}
                        displayType='text'
                        thousandSeparator={true}
                        decimalScale={0}
                        prefix='$'
                    />
                </TableCell>
                <TableCell style={{ paddingTop: 0, paddingBottom: 0, height: 52.8 }} align='right'>
                    <ThemeProvider theme={themeDownloadButton}>
                        <IconButton replace={true} component={Link} to={openRow[0] === row.id && openRow[1] ? paths[2] : `${paths[2]}/${row.anticipo.toLowerCase().replace(' ', '-')}`} style={{ marginRight: 12, marginLeft: 8 }} size='small' onClick={() => row.id === openRow[0] ? setOpenRow([-1, false]) : setOpenRow([row.id, true])}>
                            {openRow[0] === row.id && openRow[1] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </ThemeProvider>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={openRow[0] === row.id && openRow[1]} timeout='auto' unmountOnExit>
                        <Box margin={1}>
                            {row.legalizacion_set.length > 0 ?
                                <Table size='small'>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Legalización</TableCell>
                                            <TableCell align='right'>Fecha de envío</TableCell>
                                            <TableCell align='right'>Rubro</TableCell>
                                            <TableCell align='right'>Valor</TableCell>
                                            <TableCell align='right'>Responsable de IVA</TableCell>
                                            <TableCell align='right'>Estado</TableCell>
                                            <TableCell align='right'>Revisar</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.legalizacion_set.map(l => {
                                            let fecha = l.fecha.split('T')[0].split('-'); fecha = fecha.map(f => parseInt(f));
                                            return (
                                                <TableRow>
                                                    <TableCell >{`Legalización ${l.id}`}</TableCell>
                                                    <TableCell align='right'>{format(new Date(fecha[0], fecha[1] - 1, fecha[2]), "dd/MM/yyyy")}</TableCell>
                                                    <TableCell align='right'>{rubroToString(l.rubro)}</TableCell>
                                                    <TableCell align='right'>
                                                        <NumberFormat
                                                            style={{ fontSize: 14 }}
                                                            value={l.valor}
                                                            displayType='text'
                                                            thousandSeparator={true}
                                                            decimalScale={0}
                                                            prefix='$'
                                                        />
                                                    </TableCell>
                                                    <TableCell align='right'>{l.responsable ? 'Si' : 'No'}</TableCell>
                                                    <TableCell align='right'>{l.nuevo ? 'Nueva' : l.aprobado_financiera ? 'Aprobada' : l.rechazado_financiera ? 'Rechazada' : 'Pendiente'}</TableCell>
                                                    <TableCell align='right'>
                                                        <IconButton size='small' component={Link} to={`${paths[2]}/${row.anticipo.toLowerCase().replace(' ', '-')}/legalizacion-${l.id}`}>
                                                            <ArrowRightAltIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                                :
                                <Typography style={{ marginLeft: 16 }} variant='body2'>No existen legalizaciones sobre este anticipo.</Typography>
                            }
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

function ConsultarLegalizaciones(props) {
    const classes = useStyles();
    const { theme, validRole, getAccessTokenWithRefresh } = props;
    const paths = props.paths;
    const [accessToken, setAccessToken] = props.accessToken;
    const API_DEFAULT = props.API_DEFAULT;
    const [financPaths, setfinancPaths] = props.financPaths;
    const setSnack = props.setSnack;
    const setSeverity = props.setSeverity;
    const setMessage = props.setMessage;
    const setShowBackdrop = props.setShowBackdrop;
    let rowRefs = useRef([]);
    const tableRef = useRef(null);
    const [openDescargaMovimientos, setOpenDescargaMovimientos] = useState(false);
    const [resumen, setResumen] = useState([]);
    const [anticipos, setAnticipos] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [filtering, setFiltering] = useState(false);
    const [filtros, setFiltros] = useState(Array(5).fill(''));

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = rowsPerPage < anticipos.length ? rowsPerPage - Math.min(rowsPerPage, anticipos.length - page * rowsPerPage) : 0;

    const [openRow, setOpenRow] = useState([-1, false]);


    const compareObjects = (object1, object2, key) => {
        const obj1 = object1[key].toUpperCase()
        const obj2 = object2[key].toUpperCase()

        if (obj1 > obj2) {
            return -1
        }
        if (obj1 < obj2) {
            return 1
        }
        return 0
    }

    async function fetchResumen(access = accessToken) {
        const res = await fetch(API_DEFAULT + '/legalizaciones/resumen/', {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
        })
        if (res.ok) {
            res.json().then(res => {
                setResumen(res);
            });
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        fetchResumen(newAccess);
                    }
                }
            });
        }
    }

    const clearDisabled = !filtros.some((v, i) => {
        if (i < 5 && v !== '') return true;
        return false;
    });

    const clearFilters = () => {
        for (let i = 0; i < 5; i++) {
            filtros[i] = '';
        }
        setFiltros([...filtros]);
    }

    async function fetchAnticipos(access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/legalizaciones/anticipos_complete/inicio=${page * rowsPerPage}&fin=${page * rowsPerPage + rowsPerPage}&anticipo=${filtros[0] === '' ? 'all-elements' : filtros[0]}&fecha=${filtros[1] === '' ? 'all-elements' : new Date(filtros[1]).toISOString().split('T')[0]}&estudio=${filtros[2] === '' ? 'all-elements' : filtros[2]}&cedula=${filtros[3] === '' ? 'all-elements' : filtros[3]}&nombre=${filtros[4] === '' ? 'all-elements' : filtros[4]}`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
        })
        if (res.ok) {
            res.json().then(res => {
                var result = res[0];
                result = result.map(a => { let fecha = a.fecha.split('T')[0].split('-'); fecha = fecha.map(f => parseInt(f)); return ({ ...a, 'anticipo': `Anticipo ${a.id}`, 'fecha_envio': fecha }) })
                result = result.sort((a, b) => { return compareObjects(a, b, 'fecha') });
                for (let i = 0; i < result.length; i++) {
                    rowRefs.current[result[i].id] = React.createRef();
                    result[i].legalizacion_set = result[i].legalizacion_set.sort((a, b) => { return compareObjects(a, b, 'fecha') });
                }
                setAnticipos(result);
                addPaths(result);
                setCount(res[1]);
            });
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        fetchAnticipos(newAccess);
                    }
                }
            });
        }
    }

    async function fetchLegalizacionesImpresion(access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(API_DEFAULT + '/legalizaciones/legalizaciones_impresion/', {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
        })
        if (res.ok) {
            res.json().then(res => {
                let myTestXML = new myExcelXML(res);
                myTestXML.download();
                setShowBackdrop(false);
            });
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        fetchLegalizacionesImpresion(newAccess);
                    }
                }
            });
        }
        return true;
    }

    function addPaths(anticipos) {
        let paths = financPaths;
        anticipos.map(a =>
            paths = paths.concat(`/financiera/consultar-legalizaciones/${a.anticipo.toLowerCase().replace(' ', '-')}`)
        )
        anticipos.map(a => a.legalizacion_set.map(l =>
            paths = paths.concat(`/financiera/consultar-legalizaciones/${a.anticipo.toLowerCase().replace(' ', '-')}/legalizacion-${l.id}`)
        ))
        setfinancPaths(paths);
        setShowBackdrop(false);
    }

    function refresh(id) {
        fetchResumen();
        fetchAnticipos();
        setTimeout(() => {
            window.scrollTo({
                top: tableRef.current.offsetTop + rowRefs.current[id].current.offsetTop - 200,
                left: 0,
                behavior: 'smooth'
            });
            tableRef.current.scrollTo({
                top: 0,
                left: 995,
            });
        }, 0);
    }

    useEffect(() => { if (validRole) { fetchResumen();} }, [validRole]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => { if (validRole) { fetchAnticipos() } }, [validRole, page, filtering, rowsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps
    
    return (
        <React.Fragment>
            <Switch>
                <Route
                    exact
                    path={`${paths[2]}/${openRow[1] ? `anticipo-${openRow[0]}` : ''}`}
                    render={() => (
                        <Card style={{ marginBottom: theme.spacing(2) }}>
                            <CardHeader
                                title={
                                    <React.Fragment>
                                        <Typography variant='h6'>Revisar legalizaciones</Typography>
                                    </React.Fragment>
                                }
                                action={
                                    <Grid
                                        container
                                        direction='row'
                                        alignItems='stretch'
                                        spacing={2}
                                    >
                                        <Grid item xs={12} sm={2} md={2}>
                                            <Tooltip title="Descargar Plantillas Para Siigo">
                                                <IconButton onClick={() => { setOpenDescargaMovimientos(true); }}>
                                                    <LibraryBooksIcon color='primary' />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={12} sm={2} md={2}>
                                            <Tooltip title="Descargar Legalizaciones">
                                                <IconButton onClick={() => { fetchLegalizacionesImpresion() }}>
                                                    <CloudDownloadIcon color='primary' />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={12} sm={8} md={8}>
                                            <DialogoDescargaMovimientos
                                                open={[openDescargaMovimientos, setOpenDescargaMovimientos]}
                                                theme={theme}
                                                setSeverity={setSeverity}
                                                setMessage={setMessage}
                                                setSnack={setSnack}
                                                API_DEFAULT={API_DEFAULT}
                                                accessToken={[accessToken, setAccessToken]}
                                                setShowBackdrop={setShowBackdrop}
                                                getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                                            />
                                        </Grid>
                                    </Grid>
                                }
                            />
                            <Divider />
                            <CardContent>
                                <Grid
                                    container
                                    direction='row'
                                    alignItems='stretch'
                                    spacing={2}
                                >
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Paper elevation={0} variant='outlined' style={{ padding: theme.spacing(1), border: '1px solid #00695C', height: '100%', display: 'flex', alignItems: 'center' }}>
                                            <Typography style={{ fontSize: 18 }} variant='body1'>Se han aprobado <span style={{ fontWeight: 700, fontSize: 'inherit', color: '#00695C' }}>{resumen.anticipos_ap}</span> anticipos, que corresponden a <span style={{ fontWeight: 700, fontSize: 'inherit', color: '#00695C' }}><NumberFormat style={{ fontSize: 'inherit' }} value={resumen.valor_anticipos_ap} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={0} /></span></Typography>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Paper elevation={0} variant='outlined' style={{ padding: theme.spacing(1), border: '1px solid #0277BD', height: '100%', display: 'flex', alignItems: 'center' }}>
                                            <Typography style={{ fontSize: 18 }} variant='body1'>Ha aprobado <span style={{ fontWeight: 700, fontSize: 'inherit', color: '#0277BD' }}>{resumen.legalizaciones_ap_financiera}</span> legalizaciones, que corresponden a <span style={{ fontWeight: 700, fontSize: 'inherit', color: '#0277BD' }}><NumberFormat style={{ fontSize: 'inherit' }} value={resumen.valor_legalizaciones_ap_financiera} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={0} /></span></Typography>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Paper elevation={0} variant='outlined' style={{ padding: theme.spacing(1), border: '1px solid #880E4F', height: '100%', display: 'flex', alignItems: 'center' }}>
                                            <Typography style={{ fontSize: 18 }} variant='body1'>En total tiene <span style={{ fontWeight: 700, fontSize: 'inherit', color: '#880E4F' }}>{resumen.legalizaciones_ap}</span> legalizaciones por revisar, que corresponden a <span style={{ fontWeight: 700, fontSize: 'inherit', color: '#880E4F' }}><NumberFormat style={{ fontSize: 'inherit' }} value={resumen.valor_legalizaciones_ap} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={0} /></span></Typography>
                                        </Paper>
                                    </Grid>
                                </Grid>
                                <TableContainer ref={tableRef} component={Paper} elevation={0} variant='outlined' style={{ marginTop: theme.spacing(2) }} >
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ fontSize: 16 }}></TableCell>
                                                <TableCell style={{ fontSize: 16 }}>Anticipo</TableCell>
                                                <TableCell style={{ fontSize: 16 }} align='right'>Fecha de envío</TableCell>
                                                <TableCell style={{ fontSize: 16 }} width='100px' align='center'>Estudio</TableCell>
                                                <TableCell style={{ fontSize: 16 }} align='right'>Doc. Encuestador</TableCell>
                                                <TableCell style={{ fontSize: 16 }} align='center'>Encuestador</TableCell>
                                                <TableCell style={{ fontSize: 16 }} align='right'>Valor</TableCell>
                                                <TableCell style={{ fontSize: 16 }} align='right'>Legalizaciones</TableCell>
                                                <TableCell style={{ fontSize: 16 }} align='right'>Legalizado aprobado</TableCell>
                                                <TableCell style={{ fontSize: 16 }} align='right'>Pendiente por legalizar</TableCell>
                                                <TableCell />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} component='th' scope='row' align='center'>
                                                    <div style={{ display: 'flex' }}>
                                                        <ThemeProvider theme={themeDownloadButton}>
                                                            <IconButton
                                                                style={{ marginRight: 4 }}
                                                                size='small'
                                                                color='primary'
                                                                disabled={clearDisabled || filtering}
                                                                onClick={() => {
                                                                    setPage(0);
                                                                    setFiltering(true);
                                                                }}
                                                            >
                                                                <CheckIcon />
                                                            </IconButton>
                                                        </ThemeProvider>
                                                        <IconButton
                                                            size='small'
                                                            color='secondary'
                                                            onClick={() => {
                                                                clearFilters();
                                                                if (filtering) { setPage(0); setFiltering(false); }
                                                            }}
                                                            disabled={clearDisabled}
                                                        >
                                                            <ClearIcon />
                                                        </IconButton>
                                                    </div>
                                                </TableCell>
                                                <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>
                                                    <NumberFormat
                                                        customInput={TextField}
                                                        decimalSeparator={false}
                                                        allowNegative={false}
                                                        onFocus={(e) => e.target.select()}
                                                        inputProps={{
                                                            style: {
                                                                fontSize: 14,
                                                                padding: '7.69px 9.69px',
                                                                textAlign: 'right'
                                                            }
                                                        }}
                                                        value={filtros[0]}
                                                        onValueChange={(e) => { filtros[0] = e.value; setFiltros([...filtros]) }}
                                                        size='small'
                                                        variant='outlined'
                                                        fullWidth
                                                        disabled={filtering}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={EsLocale}>
                                                        <DatePicker
                                                            value={filtros[1] === ''?null:filtros[1] }
                                                            onChange={(date) => { filtros[1] = date; setFiltros([...filtros]) }}
                                                            size='small'
                                                            inputVariant='outlined'
                                                            format="dd/MM/yyyy"
                                                            fullWidth={true}
                                                            disableToolbar={true}
                                                            autoOk={true}
                                                            disabled={filtering}
                                                            disableFuture={true}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </TableCell>
                                                <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                                    <TextField
                                                        inputProps={{
                                                            style: {
                                                                fontSize: 14,
                                                                padding: '7.69px 9.69px'
                                                            }
                                                        }}
                                                        value={filtros[2]}
                                                        onChange={(e) => { filtros[2] = e.target.value; setFiltros([...filtros]) }}
                                                        size='small'
                                                        variant='outlined'
                                                        fullWidth
                                                        disabled={filtering}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>
                                                    <NumberFormat
                                                        customInput={TextField}
                                                        decimalSeparator={false}
                                                        allowNegative={false}
                                                        onFocus={(e) => e.target.select()}
                                                        inputProps={{
                                                            style: {
                                                                fontSize: 14,
                                                                padding: '7.69px 9.69px',
                                                                textAlign: 'right'
                                                            }
                                                        }}
                                                        value={filtros[3]}
                                                        onValueChange={(e) => { filtros[3] = e.value; setFiltros([...filtros]) }}
                                                        size='small'
                                                        variant='outlined'
                                                        fullWidth
                                                        disabled={filtering}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                                    <TextField
                                                        inputProps={{
                                                            style: {
                                                                fontSize: 14,
                                                                padding: '7.69px 9.69px'
                                                            }
                                                        }}
                                                        value={filtros[4]}
                                                        onChange={(e) => { filtros[4] = e.target.value; setFiltros([...filtros]) }}
                                                        size='small'
                                                        variant='outlined'
                                                        fullWidth
                                                        disabled={filtering}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            {anticipos.map((row, i) => (
                                                    <Row key={row.id} row={row} rowRefs={rowRefs} paths={paths} openRow={[openRow, setOpenRow]} />
                                                ))}
                                            {emptyRows > 0 && (
                                                <TableRow style={{ height: 52.8 * emptyRows }}>
                                                    <TableCell colSpan={12} />
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                    <TablePagination
                                        component='div'
                                        style={{ minWidth: 995 }}
                                        count={count}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        SelectProps={{
                                            style: {
                                                fontSize: 14
                                            }
                                        }}
                                        classes={{ menuItem: classes.menuItem }}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </TableContainer>
                                <Grid
                                    container
                                    direction='row'
                                    alignItems='center'
                                    justify='flex-end'
                                    spacing={1}
                                    style={{ marginTop: theme.spacing(1.5) }}
                                >
                                    <Grid item style={{ display: 'flex' }}>
                                        <Button component={Link} fullWidth={true} to={paths[0]} variant='contained' color='secondary'>Regresar</Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card >
                    )}
                />
                {anticipos.map((a, i) => a.legalizacion_set.map(l =>
                    <Route
                        exact
                        path={`${paths[2]}/${a.anticipo.toLowerCase().replace(' ', '-')}/legalizacion-${l.id}`}
                        render={() => {
                            return (
                                <React.Fragment>
                                    <Card style={{ marginBottom: theme.spacing(2) }}>
                                        <CardHeader
                                            title={
                                                <React.Fragment>
                                                    <Typography variant='subtitle2'>{a.anticipo}</Typography>
                                                    <Typography variant='h6'>&nbsp;Legalización {l.id}</Typography>
                                                </React.Fragment>
                                            }
                                            action={
                                                <Typography variant='h6' style={{ marginRight: theme.spacing(1), marginTop: theme.spacing(2.5) }}>Estado: <span style={{ fontSize: 'inherit', color: l.rechazado_financiera ? '#0277BD' : l.aprobado_financiera ? '#00695C' : 'inherit' }}>{l.rechazado_financiera ? 'Rechazada' : l.aprobado_financiera ? 'Aprobada' : 'Pendiente'}</span></Typography>
                                            }
                                        />
                                        <Divider />
                                        <CardContent>
                                            <General
                                                validRole={validRole}
                                                getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                                                theme={theme}
                                                estudio={l.estudio}
                                                fecha={l.fecha}
                                                id={l.id}
                                                API_DEFAULT={API_DEFAULT}
                                                accessToken={[accessToken, setAccessToken]}
                                            />
                                            <BodyFormato
                                                refresh={refresh}
                                                validRole={validRole}
                                                getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                                                theme={theme}
                                                anticipoId={a.id}
                                                id={l.id}
                                                API_DEFAULT={API_DEFAULT}
                                                accessToken={[accessToken, setAccessToken]}
                                                setShowBackdrop={setShowBackdrop}
                                                setSeverity={setSeverity}
                                                setMessage={setMessage}
                                                setSnack={setSnack}
                                                rubro={l.rubro}
                                                valor={l.valor}
                                                iva={l.responsable}
                                                numFactura={l.numFactura}
                                                path={`${paths[2]}/${a.anticipo.toLowerCase().replace(' ', '-')}`}
                                            />

                                        </CardContent>
                                    </Card>
                                </React.Fragment>

                            )
                        }}
                    />
                ))}
                <Route render={() => (<Redirect to={paths[2]} />)} />

            </Switch>
        </React.Fragment>
    );
}

export default ConsultarLegalizaciones;