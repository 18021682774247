import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Typography,
    Button,
    Accordion,
    AccordionDetails ,
    AccordionSummary,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    TableContainer,
    TablePagination,
    TextField,
    IconButton,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import NumberFormat from 'react-number-format';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import conceptos from '../../data/conceptos'

const useStyles = makeStyles({
    table: {
        minWidth: 995,
    },
    menuItem: {
        fontSize: 14
    },
    row: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    heading: {
        flexBasis: '33.33%',
        flexShrink: 0,
        fontWeight: 500
    },
    secondaryHeading: {
    fontSize: 14,
    color: '#333',
    fontWeight: 500
    },
});

function Row(props){
    const expanded = props.expanded;
    const row = props.row;
    const updateDinero = props.updateDinero;
    const [concepto, setConcepto] = useState('');
    const [unidades, setUnidades] = useState(1);
    const [personas, setPersonas] = useState(1);
    const [valorUnitario, setValorUnitario] = useState(0);
    const [total, setTotal] = useState(0);
    const [descripcion, setDescripcion] = useState('');
    
    useEffect(() => {
        if(concepto === ''){
            setConcepto(row.concepto);
            setUnidades(row.unidades !== undefined ? row.unidades:1);
            setPersonas(row.personas !== undefined ? row.personas:1);
            setValorUnitario(row.valorUnitario !== undefined ? row.valorUnitario: 0);
            setDescripcion(row.descripcion);
            setTotal(row.unidades * row.personas * row.valorUnitario);
            console.log('sin concepto')
        }else{
            row.rubro = expanded;
            row.concepto = concepto;
            row.unidades = unidades;
            row.personas = personas;
            row.valorUnitario = valorUnitario;
            row.total = valorUnitario !== undefined? personas * unidades * valorUnitario: 0 ;
            row.descripcion = descripcion;
            console.log('con concepto')
        }
        console.log(row);
        
        updateDinero();
    }, [row, expanded, concepto,unidades, personas, valorUnitario, total, descripcion, updateDinero]);
    
    return(
        <React.Fragment>
            <TableRow>
                <TableCell>
                    <Autocomplete
                        value={concepto}
                        onChange={(e, v) => { setConcepto(v);}} // errores[2] = false; setErrores([...errores]) }}
                        options={
                            conceptos.filter(o => o.rubro === expanded).map(o => o.concepto)
                        }
                        noOptionsText='No hay coincidencias'
                        renderInput={params => (
                            <TextField
                                {...params}
                                size='small'
                                variant='outlined'
                                fullWidth
                                // error={errores[2]}
                            />
                        )}
                        fullWidth
                    />
                </TableCell>
                <TableCell>
                    <NumberFormat
                        customInput={TextField}
                        decimalSeparator={false}
                        format='####'
                        value={unidades}
                        onChange={(e) => { setUnidades(e.target.value); setTotal(personas * valorUnitario * e.target.value);}}
                        size='small'
                        variant='outlined'
                        fullWidth
                    />
                </TableCell>
                <TableCell>
                    <NumberFormat
                        customInput={TextField}
                        decimalSeparator={false}
                        format='####'
                        value={personas}
                        onChange={(e) => { setPersonas(e.target.value); setTotal(e.target.value * valorUnitario * unidades); }}
                        size='small'
                        variant='outlined'
                        fullWidth
                    />
                </TableCell>
                <TableCell>
                    <CurrencyTextField
                        value={valorUnitario}
                        onChange={(e, v) => { setValorUnitario(v); setTotal(personas * v *unidades); }}
                        modifyValueOnWheel={true}
                        size='small'
                        variant='outlined'
                        currencySymbol='$'
                        outputFormat='string'
                        minimumValue='0'
                        textAlign='left'
                        decimalPlaces={0}
                        fullWidth
                    />
                </TableCell>
                <TableCell>
                    <CurrencyTextField
                        value={total}
                        size='small'
                        variant='outlined'
                        currencySymbol='$'
                        outputFormat='string'
                        minimumValue='0'
                        textAlign='left'
                        decimalPlaces={0}
                        fullWidth
                        selectOnFocus={false}
                        readOnly={true}
                    />
                </TableCell>
                <TableCell>
                    <TextField
                        value={descripcion}
                        size='small'
                        variant='outlined'
                        fullWidth
                        onChange={(e) => { setDescripcion(e.target.value) }}
                    />
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

function DineroEnviado(props) {
    const theme = props.theme;
    const [open, setOpen] = props.open;
    const revision = props.revision;
    const fetchCampos=props.fetchCampos;
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [dineroAlojamiento, setDineroAlojamiento] = props.dineroAlojamiento;
    const [dineroManutencion, setDineroManutencion] = props.dineroManutencion;
    const [dineroUrbano, setDineroUrbano] = props.dineroUrbano;
    const [dineroIntermunicipal, setDineroIntermunicipal] = props.dineroIntermunicipal;
    const [dineroRural, setDineroRural] = props.dineroRural;
    const [dineroOtros, setDineroOtros] = props.dineroOtros;
    const [dineroEnviado, setDineroEnviado] = props.dineroEnviado;
    const [rowsAlojamiento, setRowsAlojamiento] = props.rowsAlojamiento;
    const [rowsManutencion, setRowsManutencion] = props.rowsManutencion;
    const [rowsUrbano, setRowsUrbano] = props.rowsUrbano;
    const [rowsIntermunicipal, setRowsIntermunicipal] = props.rowsIntermunicipal;
    const [rowsRural, setRowsRural] = props.rowsRural;
    const [rowsOtros, setRowsOtros] = props.rowsOtros;    
    const tableRef = useRef(null);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function limpiar() {
        setDineroAlojamiento('');
        setDineroManutencion('');
        setDineroUrbano('');
        setDineroIntermunicipal('');
        setDineroRural('');
        setDineroOtros('');
        setDineroEnviado('');
        setRowsAlojamiento([]);
        setRowsManutencion([]);
        setRowsUrbano([]);
        setRowsIntermunicipal([]);
        setRowsRural([]);
        setRowsOtros([]);
        if(fetchCampos){
            fetchCampos();
        }
    }

    useEffect(() => {
        let dinero = (dineroAlojamiento !== '' ? parseFloat(dineroAlojamiento) : 0) + (dineroManutencion !== '' ? parseFloat(dineroManutencion) : 0) + (dineroUrbano !== '' ? parseFloat(dineroUrbano) : 0) + (dineroIntermunicipal !== '' ? parseFloat(dineroIntermunicipal) : 0) + (dineroRural !== '' ? parseFloat(dineroRural) : 0) + (dineroOtros !== '' ? parseFloat(dineroOtros) : 0);
        setDineroEnviado(dinero > 0 ? dinero + '' : '')
    },
        [dineroAlojamiento, dineroManutencion, dineroUrbano, dineroIntermunicipal, dineroRural, dineroOtros]) // eslint-disable-line react-hooks/exhaustive-deps

    
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const currency = function(number){
        return new Intl.NumberFormat('es-co', {style: 'currency',currency: 'COP', minimumFractionDigits: 0}).format(number);
    };

    const addRowAlojamiento = () => {
        setRowsAlojamiento(oldArray => [...oldArray, {'row': (rowsAlojamiento.length + 1)}]);
    }

    const addRowManutencion = () => {
        setRowsManutencion(oldArray => [...oldArray, {'row': (rowsManutencion.length + 1)}]);
    }

    const addRowUrbano = () => {
        setRowsUrbano(oldArray => [...oldArray, {'row': (rowsUrbano.length + 1)}]);
    }

    const addRowIntermunicipal = () => {
        setRowsIntermunicipal(oldArray => [...oldArray, {'row': (rowsIntermunicipal.length + 1)}]);
    }

    const addRowRural = () => {
        setRowsRural(oldArray => [...oldArray, {'row': (rowsRural.length + 1)}]);
    }

    const addRowOtros = () => {
        setRowsOtros(oldArray => [...oldArray, {'row': (rowsOtros.length + 1)}]);
    }

    const updateDineroAlojamiento = ()=>{
        setDineroAlojamiento(rowsAlojamiento.reduce((total, row) => {return total + row.total}, 0));
    };

    const updateDineroManutencion = ()=>{
        setDineroManutencion(rowsManutencion.reduce((total, row) => {return total + row.total}, 0));
    };

    const updateDineroUrbano = ()=>{
        setDineroUrbano(rowsUrbano.reduce((total, row) => {return total + row.total}, 0));
    };

    const updateDineroIntermunicipal = ()=>{
        setDineroIntermunicipal(rowsIntermunicipal.reduce((total, row) => {return total + row.total}, 0));
    };

    const updateDineroRural = ()=>{
        setDineroRural(rowsRural.reduce((total, row) => {return total + row.total}, 0));
    };

    const updateDineroOtros = ()=>{
        setDineroOtros(rowsOtros.reduce((total, row) => {return total + row.total}, 0));
    };

    return (
        <Dialog
            open={open}
            onClose={() => { setOpen(false) }}
            fullWidth={true}
            maxWidth='m'
        >
            <DialogTitle>Dinero enviado</DialogTitle>
            <DialogContent>
            <Accordion expanded={expanded === 'alojamiento'} onChange={handleChange('alojamiento')} style={{background: '#EF6C0026'}}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>Alojamiento</Typography>
                    <Typography className={classes.secondaryHeading}>{currency(dineroAlojamiento)}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TableContainer ref={tableRef} component={Paper} elevation={0} variant='outlined'>
                        <Table className={classes.table}>
                            <caption>
                                <IconButton color='primary' style={{ marginRight: 12, marginLeft: 8, align: 'center'}} size='small' onClick={addRowAlojamiento}>
                                    <AddCircleIcon  style={{ height: 24, width: 'auto' }}  />
                                    <Typography variant='subtitle2'>Añadir</Typography>
                                </IconButton>
                            </caption>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontSize: 15, width: 300}} size='small' >Concepto</TableCell>
                                    <TableCell style={{ fontSize: 15, width: 100 }} size='small' >Días</TableCell>
                                    <TableCell style={{ fontSize: 15, width: 50 }} size='small' >Personas</TableCell>
                                    <TableCell style={{ fontSize: 15, width: 200 }} size='small' >Valor unitario</TableCell>
                                    <TableCell style={{ fontSize: 15, width: 200 }} size='small' >Total</TableCell>
                                    <TableCell style={{ fontSize: 15, width: 400 }} size='small' >Descripción</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    rowsAlojamiento.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => 
                                        <Row expanded = {'alojamiento'} row={row} updateDinero={updateDineroAlojamiento}/>
                                    )  
                                }
                                
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            style={{ minWidth: 985 }}
                            count={rowsAlojamiento.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            SelectProps={{
                                style: {
                                    fontSize: 14
                                }
                            }}
                            classes={{ menuItem: classes.menuItem }}
                        />
                    </TableContainer>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'manutencion'} onChange={handleChange('manutencion')} style={{background: '#EF6C0026'}}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>Manutención</Typography>
                    <Typography className={classes.secondaryHeading}>{currency(dineroManutencion)}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <TableContainer ref={tableRef} component={Paper} elevation={0} variant='outlined'>
                        <Table className={classes.table}>
                            <caption>
                                <IconButton color='primary' style={{ marginRight: 12, marginLeft: 8, align: 'center'}} size='small' onClick={addRowManutencion}>
                                    <AddCircleIcon  style={{ height: 24, width: 'auto' }}  />
                                    <Typography variant='subtitle2'>Añadir</Typography>
                                </IconButton>
                            </caption>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontSize: 15, width: 300}} size='small' >Concepto</TableCell>
                                    <TableCell style={{ fontSize: 15, width: 100 }} size='small' >Días</TableCell>
                                    <TableCell style={{ fontSize: 15, width: 50 }} size='small' >Personas</TableCell>
                                    <TableCell style={{ fontSize: 15, width: 200 }} size='small' >Valor unitario</TableCell>
                                    <TableCell style={{ fontSize: 15, width: 200 }} size='small' >Total</TableCell>
                                    <TableCell style={{ fontSize: 15, width: 400 }} size='small' >Descripción</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    rowsManutencion.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => 
                                    <Row expanded = {'manutencion'} row={row} updateDinero={updateDineroManutencion} 
                                    />)  
                                }
                                
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            style={{ minWidth: 985 }}
                            count={rowsManutencion.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            SelectProps={{
                                style: {
                                    fontSize: 14
                                }
                            }}
                            classes={{ menuItem: classes.menuItem }}
                        />
                    </TableContainer>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'urbano'} onChange={handleChange('urbano')} style={{background: '#EF6C0026'}}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>Transporte interno/urbano</Typography>
                    <Typography className={classes.secondaryHeading}>{currency(dineroUrbano)}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TableContainer ref={tableRef} component={Paper} elevation={0} variant='outlined'>
                        <Table className={classes.table}>
                            <caption>
                                <IconButton color='primary' style={{ marginRight: 12, marginLeft: 8, align: 'center'}} size='small' onClick={addRowUrbano}>
                                    <AddCircleIcon  style={{ height: 24, width: 'auto' }}  />
                                    <Typography variant='subtitle2'>Añadir</Typography>
                                </IconButton>
                            </caption>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontSize: 15, width: 300}} size='small' >Concepto</TableCell>
                                    <TableCell style={{ fontSize: 15, width: 100 }} size='small' >Trayectos</TableCell>
                                    <TableCell style={{ fontSize: 15, width: 50 }} size='small' >Personas</TableCell>
                                    <TableCell style={{ fontSize: 15, width: 200 }} size='small' >Valor unitario</TableCell>
                                    <TableCell style={{ fontSize: 15, width: 200 }} size='small' >Total</TableCell>
                                    <TableCell style={{ fontSize: 15, width: 400 }} size='small' >Descripción</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    rowsUrbano.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => 
                                    <Row expanded = {'urbano'} row={row} updateDinero={updateDineroUrbano}/>)  
                                }
                                
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            style={{ minWidth: 985 }}
                            count={rowsUrbano.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            SelectProps={{
                                style: {
                                    fontSize: 14
                                }
                            }}
                            classes={{ menuItem: classes.menuItem }}
                        />
                    </TableContainer>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'intermunicipal'} onChange={handleChange('intermunicipal')} style={{background: '#EF6C0026'}}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>Transporte intermunicipal</Typography>
                    <Typography className={classes.secondaryHeading}>{currency(dineroIntermunicipal)}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TableContainer ref={tableRef} component={Paper} elevation={0} variant='outlined'>
                        <Table className={classes.table}>
                            <caption>
                                <IconButton color='primary' style={{ marginRight: 12, marginLeft: 8, align: 'center'}} size='small' onClick={addRowIntermunicipal}>
                                    <AddCircleIcon  style={{ height: 24, width: 'auto' }}  />
                                    <Typography variant='subtitle2'>Añadir</Typography>
                                </IconButton>
                            </caption>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontSize: 15, width: 300}} size='small' >Concepto</TableCell>
                                    <TableCell style={{ fontSize: 15, width: 100 }} size='small' >Trayectos</TableCell>
                                    <TableCell style={{ fontSize: 15, width: 50 }} size='small' >Personas</TableCell>
                                    <TableCell style={{ fontSize: 15, width: 200 }} size='small' >Valor unitario</TableCell>
                                    <TableCell style={{ fontSize: 15, width: 200 }} size='small' >Total</TableCell>
                                    <TableCell style={{ fontSize: 15, width: 400 }} size='small' >Descripción</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    rowsIntermunicipal.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => 
                                    <Row expanded = {'intermunicipal'} row={row} updateDinero={updateDineroIntermunicipal}/>)  
                                }
                                
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            style={{ minWidth: 985 }}
                            count={rowsIntermunicipal.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            SelectProps={{
                                style: {
                                    fontSize: 14
                                }
                            }}
                            classes={{ menuItem: classes.menuItem }}
                        />
                    </TableContainer>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'rural'} onChange={handleChange('rural')} style={{background: '#EF6C0026'}}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>Transporte rural</Typography>
                    <Typography className={classes.secondaryHeading}>{currency(dineroRural)}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TableContainer ref={tableRef} component={Paper} elevation={0} variant='outlined'>
                        <Table className={classes.table}>
                            <caption>
                                <IconButton color='primary' style={{ marginRight: 12, marginLeft: 8, align: 'center'}} size='small' onClick={addRowRural}>
                                    <AddCircleIcon  style={{ height: 24, width: 'auto' }}  />
                                    <Typography variant='subtitle2'>Añadir</Typography>
                                </IconButton>
                            </caption>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontSize: 15, width: 300}} size='small' >Concepto</TableCell>
                                    <TableCell style={{ fontSize: 15, width: 100 }} size='small' >Trayectos</TableCell>
                                    <TableCell style={{ fontSize: 15, width: 50 }} size='small' >Personas</TableCell>
                                    <TableCell style={{ fontSize: 15, width: 200 }} size='small' >Valor unitario</TableCell>
                                    <TableCell style={{ fontSize: 15, width: 200 }} size='small' >Total</TableCell>
                                    <TableCell style={{ fontSize: 15, width: 400 }} size='small' >Descripción</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    rowsRural.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => 
                                    <Row expanded = {'rural'} row={row} updateDinero={updateDineroRural}/>)  
                                }
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            style={{ minWidth: 985 }}
                            count={rowsRural.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            SelectProps={{
                                style: {
                                    fontSize: 14
                                }
                            }}
                            classes={{ menuItem: classes.menuItem }}
                        />
                    </TableContainer>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'otros'} onChange={handleChange('otros')} style={{background: '#EF6C0026'}}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>Otros</Typography>
                    <Typography className={classes.secondaryHeading}>{currency(dineroOtros)}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <TableContainer ref={tableRef} component={Paper} elevation={0} variant='outlined'>
                        <Table className={classes.table}>
                            <caption>
                                <IconButton color='primary' style={{ marginRight: 12, marginLeft: 8, align: 'center'}} size='small' onClick={addRowOtros}>
                                    <AddCircleIcon  style={{ height: 24, width: 'auto' }}  />
                                    <Typography variant='subtitle2'>Añadir</Typography>
                                </IconButton>
                            </caption>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontSize: 15, width: 300}} size='small' >Concepto</TableCell>
                                    <TableCell style={{ fontSize: 15, width: 100 }} size='small' >Cantidad</TableCell>
                                    <TableCell style={{ fontSize: 15, width: 50 }} size='small' >Personas</TableCell>
                                    <TableCell style={{ fontSize: 15, width: 200 }} size='small' >Valor unitario</TableCell>
                                    <TableCell style={{ fontSize: 15, width: 200 }} size='small' >Total</TableCell>
                                    <TableCell style={{ fontSize: 15, width: 400 }} size='small' >Descripción</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    rowsOtros.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => 
                                    <Row expanded = {'otros'} row={row} updateDinero={updateDineroOtros}/>)  
                                }
                                
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            style={{ minWidth: 985 }}
                            count={rowsOtros.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            SelectProps={{
                                style: {
                                    fontSize: 14
                                }
                            }}
                            classes={{ menuItem: classes.menuItem }}
                        />
                    </TableContainer>
                </AccordionDetails>
            </Accordion>
            <Typography style={{ marginTop: theme.spacing(2), fontWeight: 500 }} variant='body1'>Dinero enviado:</Typography>
                <CurrencyTextField
                    value={dineroEnviado}
                    modifyValueOnWheel={false}
                    size='small'
                    variant='outlined'
                    currencySymbol='$'
                    outputFormat='string'
                    minimumValue='0'
                    textAlign='left'
                    decimalPlaces={0}
                    fullWidth
                    // error={errores[1]}
                    selectOnFocus={false}
                    readOnly={true}
                />
            </DialogContent>
            {revision !== true?
                <DialogActions style={{ marginBottom: theme.spacing(2), marginRight: theme.spacing(1) }}>
                    <Button variant='contained' onClick={() => {setOpen(false)}} color='primary'>
                        Aceptar
                    </Button>
                    <Button variant='contained' onClick={() => { limpiar(); setOpen(false) }} color='secondary'>
                        Descartar
                    </Button>
                </DialogActions>
            :
                <DialogActions style={{ marginBottom: theme.spacing(2), marginRight: theme.spacing(1) }}>
                    <Button variant='contained' onClick={() => { limpiar(); setOpen(false) }} color='secondary'>
                        Aceptar
                    </Button>
                </DialogActions>
            }
        </Dialog>
    );
}

export default DineroEnviado;