import React, { useEffect } from 'react';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import {
    Typography,
    Button,
    Grid,
    Card,
    CardContent,
    CardHeader,
    Divider
} from '@material-ui/core';
import {
    Link
} from "react-router-dom";

let themeDownloadButton = createMuiTheme({
    palette: {
        primary: {
            main: '#0277BD'
        },
        secondary: {
            main: '#880E4F'
        }
    },
    typography: {
        fontFamily: 'Roboto'
    }
});
themeDownloadButton = responsiveFontSizes(themeDownloadButton);

function MenuAdm(props) {
    const theme = props.theme;
    const username = props.username;
    const paths = props.paths;
    const [coordAdmPaths, setCoordAdmPaths] = props.coordAdmPaths;
    const coordAdmPathsIni = ['/coord-administrativo/registrar-anticipo',
        '/coord-administrativo/revisar-legalizaciones',
        '/coord-administrativo/consultar-reportes',
        '/coord-administrativo/consultar-anticipos'];
    /* const accessToken = props.accessToken;*/
    

    function clearPaths() {
        if (coordAdmPaths !== coordAdmPathsIni) {
            setCoordAdmPaths(coordAdmPathsIni)
        }
    }

    useEffect(() => {
        clearPaths(); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Card style={{ marginBottom: theme.spacing(2) }}>
            <CardHeader
                title={
                    <Typography variant='h6'>Rol: Coordinador administrativo</Typography>
                }
            />
            <Divider />
            <CardContent>
                <Grid
                    container
                    direction='column'
                    alignItems='center'
                    justify='center'
                    style={{ marginTop: theme.spacing(1) }}
                >
                    <Grid item>
                        <Typography style={{ fontSize: 17 }} variant='body1'>¡Hola {username}!</Typography>
                    </Grid>
                    <Grid item style={{ marginBottom: theme.spacing(3) }}>
                        <Typography style={{ fontSize: 18 }} align='center' variant='body1'>Bienvenido(a) al aplicativo de legalizaciones</Typography>
                        <Typography style={{ fontSize: 14, marginTop: theme.spacing(2) }} align='center' variant='body1'>Las opciones desabilitadas se encuentran en desarrollo y estarán en funcionamiento dentro de poco</Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    justify='center'
                    spacing={1}
                >
                    <Grid item style={{ display: 'flex', justifyContent: 'center' }} xs={12} md={3}>
                        <Button component={Link} style={{ maxWidth: 450 }} fullWidth={true} to={paths[1]} variant='contained' color='primary'>Registrar anticipo</Button>
                    </Grid>
                    <Grid item style={{ display: 'flex', justifyContent: 'center' }} xs={12} md={3}>
                        <Button component={Link} style={{ maxWidth: 450 }} fullWidth={true} to={paths[4]} variant='contained' color='secondary'>Consultar anticipos</Button>
                    </Grid>
                    <Grid item style={{ display: 'flex', justifyContent: 'center' }} xs={12} md={3}>
                        <ThemeProvider theme={themeDownloadButton}>
                            <Button disabled={false} /* Cambiar en el futuro */ component={Link} style={{ maxWidth: 450 }} fullWidth={true} to={paths[2]} variant='contained' color='primary'>Revisar legalizaciones</Button>
                        </ThemeProvider>
                    </Grid>
                    <Grid item style={{ display: 'flex', justifyContent: 'center' }} xs={12} md={3}>
                        <ThemeProvider theme={themeDownloadButton}>
                            <Button disabled={true} /* Cambiar en el futuro */ component={Link} style={{ maxWidth: 450 }} fullWidth={true} to={paths[3]} variant='contained' color='secondary'>Consultar reportes</Button>
                        </ThemeProvider>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default MenuAdm;