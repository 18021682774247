import React from 'react';
import {
    Typography,
    Card,
    CardContent,
    CardHeader,
    Divider,
    List,
    ListItem,
    ListItemText,
    Grid
} from '@material-ui/core';

function RevisarLegal(props) {
    const theme = props.theme;
    return (
        <Card style={{ marginBottom: theme.spacing(2) }}>
            <CardHeader
                title={
                    <Typography variant='h6'>Revisar legalizaciones</Typography>
                }
            />
            <Divider />
            <CardContent>
                <List style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <ListItem>
                        <Grid
                            container
                            direction='row'
                            alignItems='center'
                            justify='center'
                        >
                            <Grid item xs>
                                <span style={{ fontWeight: 500 }}>Legalización</span>
                            </Grid>
                            <Grid item xs>
                                <span style={{ fontWeight: 500 }}>Valor</span>
                            </Grid>
                            <Grid item xs>
                                <span style={{ fontWeight: 500 }}>Estado</span>
                            </Grid>
                        </Grid>
                    </ListItem>
                    <Divider />
                    {[{ legalizacion: 'Legalización 1', valor: '$700,000', estado: 'No aprobada' }].map(l => (
                        <ListItem button /* style={{ borderTop: borderSolid, borderLeft: borderSolid, borderRight: borderSolid }} */>
                            <ListItemText primary={
                                <Grid
                                    container
                                    direction='row'
                                    alignItems='center'
                                    justify='center'
                                >
                                    <Grid item xs>
                                        {l.legalizacion}
                                    </Grid>
                                    <Grid item xs>
                                        {l.valor}
                                    </Grid>
                                    <Grid item xs>
                                        {l.estado}
                                    </Grid>
                                </Grid>
                            } />
                        </ListItem>
                    ))
                    }
                    <Divider />
                </List>
            </CardContent>
        </Card>
    );
}

export default RevisarLegal;