import React from 'react';
import {
    Route,
    Switch,
    Redirect
} from "react-router-dom";

import MenuSupervEnc from './MenuSupervEnc';
import AnticiposRec from './AnticiposRec';
import RevisarLegal from './RevisarLegal';
import ConsultarLegalizaciones from './ConsultarLegalizaciones/ConsultarLegalizaciones';

function SupervEnc(props) {
    const { theme, validRole, getAccessTokenWithRefresh, getApiKeyRut, isAdmin} = props;
    const username = props.username;
    const paths = props.paths;
    const [accessToken, setAccessToken] = props.accessToken;
    const API_DEFAULT = props.API_DEFAULT;
    const API_RUT = props.API_RUT;
    const API_KEY_RUT = props.API_KEY_RUT;
    const [supervPaths, setSupervPaths] = props.supervPaths;
    const setSnack = props.setSnack;
    const setSeverity = props.setSeverity;
    const setMessage = props.setMessage;
    const setShowBackdrop = props.setShowBackdrop;
    const setLabelShowBackdrop = props.setLabelShowBackdrop;
    const history = props.history;

    return (
        <React.Fragment>
            <Switch>
                <Route
                    exact
                    path={paths[0]}
                    render={() => (
                        <MenuSupervEnc
                            validRole={validRole}
                            theme={theme}
                            paths={paths}
                            username={username}
                            supervPaths={[supervPaths, setSupervPaths]}
                            API_DEFAULT={API_DEFAULT}
                            accessToken={[accessToken, setAccessToken]}
                            setShowBackdrop={setShowBackdrop}
                            getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                            isAdmin={isAdmin}
                        />
                    )}
                />
                <Route
                    path={paths[1]}
                    render={() => (
                        <React.Fragment>
                            <Redirect to={paths[1]} />
                            <AnticiposRec
                                validRole={validRole}
                                theme={theme}
                                paths={paths}
                                supervPaths={[supervPaths, setSupervPaths]}
                                API_DEFAULT={API_DEFAULT}
                                API_RUT={API_RUT}
                                API_KEY_RUT={API_KEY_RUT}
                                accessToken={[accessToken, setAccessToken]}
                                setSnack={setSnack}
                                setSeverity={setSeverity}
                                setMessage={setMessage}
                                setShowBackdrop={setShowBackdrop}
                                setLabelShowBackdrop={setLabelShowBackdrop}
                                history={history}
                                getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                                getApiKeyRut={getApiKeyRut}
                                isAdmin={isAdmin}
                                username={username}
                            />
                        </React.Fragment>
                    )}
                />
                <Route
                    exact
                    path={paths[2]}
                    render={() => (
                        <RevisarLegal
                            theme={theme}
                        />
                    )}
                />
                <Route
                    path={paths[3]}
                    render={() => (
                        <React.Fragment>
                            <Redirect to={paths[3]} />
                            <ConsultarLegalizaciones
                                validRole={validRole}
                                theme={theme}
                                paths={paths}
                                supervPaths={[supervPaths, setSupervPaths]}
                                API_DEFAULT={API_DEFAULT}
                                accessToken={[accessToken, setAccessToken]}
                                setSnack={setSnack}
                                setSeverity={setSeverity}
                                setMessage={setMessage}
                                setShowBackdrop={setShowBackdrop}
                                history={history}
                                getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                                isAdmin={isAdmin}
                                username={username}
                            />
                        </React.Fragment>
                    )}
                />
                <Route render={() => (<Redirect to={paths[0]} />)} />
            </Switch>
        </React.Fragment>
    );
}

export default SupervEnc;