import React from 'react';
import {
    Route,
    Redirect,
    Switch
} from "react-router-dom";

import MenuAdm from './MenuAdm';
import RegistrarAnt from './RegistrarAnt';
import RevisarLegal from './RevisarLegalizaciones/RevisarLegal';
import ConsultarRepo from './ConsultarRepo';
import ConsultarAnticipos from './ConsultarAnticipos/ConsultarAnticipos';


function CoordAdm(props) {
    const { theme, getAccessTokenWithRefresh, validRole } = props;
    const username = props.username;
    const paths = props.paths;
    const [coordAdmPaths, setCoordAdmPaths] = props.coordAdmPaths;
    const [accessToken, setAccessToken] = props.accessToken;
    const API_DEFAULT = props.API_DEFAULT;
    const setSnack = props.setSnack;
    const setSeverity = props.setSeverity;
    const setMessage = props.setMessage;
    const setShowBackdrop = props.setShowBackdrop;

    return (
        <React.Fragment>
            <Switch>
                <Route
                    exact
                    path={paths[0]}
                    render={() => (
                        <MenuAdm
                            theme={theme}
                            username={username}
                            paths={paths}
                            coordAdmPaths={[coordAdmPaths, setCoordAdmPaths]}
                        />
                    )}
                />
                <Route
                    exact
                    path={paths[1]}
                    render={() => (
                        <RegistrarAnt
                            theme={theme}
                            paths={paths}
                            API_DEFAULT={API_DEFAULT}
                            accessToken={[accessToken, setAccessToken]}
                            validRole={validRole}
                            setSnack={setSnack}
                            setSeverity={setSeverity}
                            setMessage={setMessage}
                            setShowBackdrop={setShowBackdrop}
                            getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                        />
                    )}
                />
                <Route
                    path={paths[2]}
                    render={() => (
                        <React.Fragment>
                            <Redirect to={paths[2]} />
                            <RevisarLegal
                                theme={theme}
                                paths={paths}
                                API_DEFAULT={API_DEFAULT}
                                accessToken={[accessToken, setAccessToken]}
                                coordAdmPaths={[coordAdmPaths, setCoordAdmPaths]}
                                setSnack={setSnack}
                                setSeverity={setSeverity}
                                setMessage={setMessage}
                                setShowBackdrop={setShowBackdrop}
                                getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                                validRole={validRole}
                            />
                        </React.Fragment>
                    )}
                />
                <Route
                    exact
                    path={paths[3]}
                    render={() => (
                        <ConsultarRepo
                            theme={theme}
                            paths={paths}
                            API_DEFAULT={API_DEFAULT}
                            accessToken={[accessToken, setAccessToken]}
                            setSnack={setSnack}
                            setSeverity={setSeverity}
                            setMessage={setMessage}
                            setShowBackdrop={setShowBackdrop}
                            getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                            validRole={validRole}
                        />
                    )}
                />
                <Route
                    exact
                    path={paths[4]}
                    render={() => (
                        <ConsultarAnticipos
                            theme={theme}
                            paths={paths}
                            API_DEFAULT={API_DEFAULT}
                            accessToken={[accessToken, setAccessToken]}
                            coordAdmPaths={[coordAdmPaths, setCoordAdmPaths]}
                            setSnack={setSnack}
                            setSeverity={setSeverity}
                            setMessage={setMessage}
                            setShowBackdrop={setShowBackdrop}
                            getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                            validRole={validRole}
                        />
                    )}
                />
                
                <Route render={() => (<Redirect to={paths[0]} />)} />
            </Switch>
        </React.Fragment>
    );
}

export default CoordAdm;