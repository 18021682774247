const conceptos = [
    { idRubro: 1 , rubro: 'alojamiento', concepto: "Alojamiento" },
    { idRubro: 2 , rubro: 'manutencion', concepto: "Desayuno" },
    { idRubro: 2 , rubro: 'manutencion', concepto: "Almuerzo" },
    { idRubro: 2 , rubro: 'manutencion', concepto: "Cena" },
    { idRubro: 2 , rubro: 'manutencion', concepto: "Refrigerio" },
    { idRubro: 2 , rubro: 'manutencion', concepto: "Desayuno/Almuerzo/Cena" },
    { idRubro: 3 , rubro: 'urbano', concepto: "Alquiler carro/camión/moto particular" },
    { idRubro: 3 , rubro: 'urbano', concepto: "Bus público" },
    { idRubro: 3 , rubro: 'urbano', concepto: "Taxi" },
    { idRubro: 3 , rubro: 'urbano', concepto: "Carro/camión/moto propia + gasolina" },
    { idRubro: 4 , rubro: 'intermunicipal', concepto: "Alquiler carro/camión/moto particular" },
    { idRubro: 4 , rubro: 'intermunicipal', concepto: "Bus/flota o taxi intermunicipal" },
    { idRubro: 4 , rubro: 'intermunicipal', concepto: "Lanchas o transporte fluvial" },
    { idRubro: 4 , rubro: 'intermunicipal', concepto: "Carro/camión/moto propia + gasolina" },
    { idRubro: 5 , rubro: 'rural', concepto: "Alquiler carro/camión/moto particular" },
    { idRubro: 5 , rubro: 'rural', concepto: "Lanchas o transporte fluvial" },
    { idRubro: 5 , rubro: 'rural', concepto: "Alquiler de caballos" },
    { idRubro: 5 , rubro: 'rural', concepto: "Carro/camión/moto propia + gasolina" },
    { idRubro: 6 , rubro: 'otros', concepto: "Fotocopias" },
    { idRubro: 6 , rubro: 'otros', concepto: "Tarjeta SIM" },
    { idRubro: 6 , rubro: 'otros', concepto: "Recarga modem" },
    { idRubro: 6 , rubro: 'otros', concepto: "Recarga datos celular" },
    { idRubro: 6 , rubro: 'otros', concepto: "Otros" }
]

export default conceptos;
