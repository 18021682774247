import React, { useEffect, useState } from 'react';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import {
    Typography,
    Button,
    Grid,
    Card,
    CardContent,
    CardHeader,
    Divider
} from '@material-ui/core';
import {
    Link
} from "react-router-dom";
import NumberFormat from 'react-number-format';

let themeDownloadButton = createMuiTheme({
    palette: {
        primary: {
            main: '#0277BD'
        }
    },
    typography: {
        fontFamily: 'Roboto'
    }
});
themeDownloadButton = responsiveFontSizes(themeDownloadButton);

const reducer = (accum, curent) => accum + curent

function MenuSupervEnc(props) {
    const { theme, validRole, getAccessTokenWithRefresh } = props;
    const username = props.username;
    const paths = props.paths;
    const [supervPaths, setSupervPaths] = props.supervPaths;
    const supervPathsIni = ['/superv-enc/anticipos-recibidos',
        '/superv-enc/legalizaciones', '/superv-enc/consultar-legalizaciones'];
    const [accessToken, setAccessToken] = props.accessToken;
    const API_DEFAULT = props.API_DEFAULT;
    const [faltantes, setFaltantes] = useState([]);
    const [display, setDisplay] = useState(false);

    function clearPaths() {
        if (supervPaths !== supervPathsIni) {
            setSupervPaths(supervPathsIni)
        }
    }

    async function fetchResumen(access = accessToken) {
        const res = await fetch(API_DEFAULT + `/legalizaciones/anticipos_resumen/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access }
        });
        if (res.ok) {
            res
                .json()
                .then(res => { setFaltantes(res); setDisplay(true) });
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        fetchResumen(newAccess);
                    }
                }
            });
        }
    }

    useEffect(() => {
        clearPaths(); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (validRole) { fetchResumen() }
    }, [validRole]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Card style={{ marginBottom: theme.spacing(2) }}>
            <CardHeader
                title={
                    <Typography variant='h6'>Rol: Supervisor - Encuestador municipios lejanos</Typography>
                }
            />
            <Divider />
            <CardContent>
                <Grid
                    container
                    direction='column'
                    alignItems='center'
                    justify='center'
                    style={{ marginTop: theme.spacing(1) }}
                >
                    <Grid item>
                        <Typography style={{ fontSize: 17 }} variant='body1'>¡Hola {username}!</Typography>
                    </Grid>
                    <Grid item style={{ marginBottom: theme.spacing(3) }}>
                        <Typography style={{ fontSize: 18 }} align='center' variant='body1'>Bienvenido(a) al aplicativo de legalizaciones</Typography>
                    </Grid>
                    <Grid item style={{ marginBottom: theme.spacing(3) }}>
                        {faltantes.length > 0 ?
                            <Typography style={{ fontSize: 18 }} align='center' variant='body1'>Tiene pendiente por legalizar un total de <span style={{ fontWeight: 700, fontSize: 'inherit', color: '#c62828' }}>{faltantes.length}</span> anticipos, que corresponde a <NumberFormat
                                style={{ fontWeight: 700, fontSize: 'inherit', color: '#c62828' }}
                                value={faltantes.map(f => parseFloat(f.valor_por_legalizar)).reduce(reducer, 0)}
                                displayType='text'
                                thousandSeparator={true}
                                decimalScale={0}
                                prefix='$'
                            />
                            </Typography>
                            :
                            <Typography style={{ fontSize: 18, display: display ? null : 'none' }} align='center' variant='body1'>Se encuentra al día en legalizaciones, ¡Muy bien!</Typography>
                        }
                        <Typography style={{ fontSize: 14, marginTop: theme.spacing(2) }} align='center' variant='body1'>Las opciones desabilitadas se encuentran en desarrollo y estarán en funcionamiento dentro de poco</Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    justify='center'
                    spacing={1}
                >
                    <Grid item style={{ display: 'flex', justifyContent: 'center' }} xs={12} md={6}>
                        <Button component={Link} style={{ maxWidth: 500 }} fullWidth={true} to={paths[1]} variant='contained' color='primary'>Anticipos recibidos</Button>
                    </Grid>
                    <Grid item style={{ display: 'flex', justifyContent: 'center' }} xs={12} md={6}>
                        <Button component={Link} style={{ maxWidth: 500 }} fullWidth={true} to={paths[3]} variant='contained' color='secondary'>Consultar legalizaciones</Button>
                    </Grid>
                    <Grid item style={{ display: 'flex', justifyContent: 'center' }} xs={12} md={6}>
                        <ThemeProvider theme={themeDownloadButton}>
                            <Button disabled={true} /* Cambiar en el futuro */ component={Link} style={{ maxWidth: 500 }} fullWidth={true} to={paths[2]} variant='contained' color='primary'>Cola de legalizaciones</Button>
                        </ThemeProvider>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default MenuSupervEnc;