import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Divider,
    Button
} from '@material-ui/core';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

import responsableIva from '../../images/responsable-iva.png';
import noResponsableIva from '../../images/no-responsable-iva.png';

function DialogoDiferencias(props) {
    const theme = props.theme;
    const [open, setOpen] = props.open;

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            fullWidth={true}
            maxWidth='md'
        >
            <DialogTitle>Responsable de IVA vs. NO responsable de IVA</DialogTitle>
            <DialogContent>
                <Typography variant='body1' style={{ marginBottom: theme.spacing(1) }}>Las facturas que contienen los siguientes datos pertenecen a establecimientos <span style={{ fontWeight: 500 }}>responsables de IVA</span>:</Typography>
                <Typography variant='body1'>&#8226; Dirección</Typography>
                <Typography variant='body1'>&#8226; NIT</Typography>
                <Typography variant='body1'>&#8226; Celular o teléfono</Typography>
                <Typography variant='body1'>&#8226; Consecutivo de la factura de venta</Typography>
                <Typography variant='body1'>&#8226; Resolución</Typography>
                <Typography variant='body1'>&#8226; IVA descrito</Typography>
                <Typography variant='body1'>&#8226; Mencionar que es de régimen común, gran contribuyente o autoretenedor</Typography>
                <Typography variant='body1' style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}>A continuación un par de ejemplos:</Typography>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ margin: theme.spacing(1) }} ><Zoom overlayBgColorEnd='rgba(33, 33, 33, 1)'><img style={{ width: '100%', height: 'auto', maxWidth: 300 }} src={responsableIva} alt='iva1' /></Zoom></div>
                    <div style={{ margin: theme.spacing(1) }} ><Zoom overlayBgColorEnd='rgba(33, 33, 33, 1)'><img style={{ width: '100%', height: 'auto', maxWidth: 300 }} src={responsableIva} alt='iva2' /></Zoom></div>
                </div>
                <Divider style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(2) }} />
                <Typography variant='body1' style={{ marginBottom: theme.spacing(1) }}>Hay otro tipo de establecimientos que pertenecen al grupo de <span style={{ fontWeight: 500 }}>NO responsables de IVA</span>. Estos establecimientos tienen recibos que generalmente carecen de consecutivo, resolución y del IVA descrito. Los siguientes son ejemplos de este tipo de recibos:</Typography>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ margin: theme.spacing(1) }} ><Zoom overlayBgColorEnd='rgba(33, 33, 33, 1)'><img style={{ width: '100%', height: 'auto', maxWidth: 300 }} src={noResponsableIva} alt='no-iva1' /></Zoom></div>
                    <div style={{ margin: theme.spacing(1) }} ><Zoom overlayBgColorEnd='rgba(33, 33, 33, 1)'><img style={{ width: '100%', height: 'auto', maxWidth: 300 }} src={noResponsableIva} alt='no-iva2' /></Zoom></div>
                </div>
            </DialogContent>
            <Divider />
            <DialogActions style={{ marginBottom: theme.spacing(2), marginRight: theme.spacing(1), marginTop: theme.spacing(1) }}>
                <Button variant='contained' onClick={() => alert('vídeo')} color='primary'>
                    Ver vídeo
                </Button>
                <Button variant='contained' onClick={() => setOpen(false)} color='secondary'>
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogoDiferencias;