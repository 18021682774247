import React, { useRef, useState } from 'react';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import {
    Divider,
    Typography,
    CardHeader,
    CardContent,
    Button,
    Grid
} from '@material-ui/core';
import {
    Link
} from "react-router-dom";
import CurrencyTextField from '@unicef/material-ui-currency-textfield';

let themeDownloadButton = createMuiTheme({
    palette: {
        primary: {
            main: '#0277BD'
        }
    },
    typography: {
        fontFamily: 'Roboto'
    }
});
themeDownloadButton = responsiveFontSizes(themeDownloadButton);

function Devolucion(props) {
    const { theme, getAccessTokenWithRefresh } = props;
    const hiddenInput = useRef(null);
    const [archivos, setArchivos] = props.archivos;
    const [errores, setErrores] = useState(Array(2).fill(false));
    const [valor, setValor] = useState('');
    const cargarArchivo = props.cargarArchivo;
    const path = props.path;
    const limpiar = props.limpiar;
    const setSnack = props.setSnack;
    const setSeverity = props.setSeverity;
    const setMessage = props.setMessage;
    const setShowBackdrop = props.setShowBackdrop;
    const setLabelShowBackdrop = props.setLabelShowBackdrop;
    const rubro = props.rubro;
    const estudio = props.estudio;
    const [accessToken, setAccessToken] = props.accessToken;
    const API_DEFAULT = props.API_DEFAULT;
    const idAnticipo = props.idAnticipo;
    const fechaLegalizacion = props.fechaLegalizacion;

    function limpiarArchivos(index) {
        if (index.length !== undefined) {
            for (let i = 0; i < index.length; i++) {
                archivos[index[i]] = null;
            }
        }
        else {
            archivos[index] = null;
        }
        setArchivos([...archivos]);
    }

    function validar() {
        let errorValidar = false;
        if (valor === '' || valor === '0') {
            errorValidar = true;
            errores[0] = true;
        }
        if (archivos[0] === null) {
            errorValidar = true;
            errores[1] = true;
        }
        if (!errorValidar) {
            postLegalizacion()
        }
        else {
            setErrores([...errores]);
            setMessage('Existen campos sin diligenciar o con algún error.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
        }
    }

    async function postLegalizacion(access = accessToken) {
        setShowBackdrop(true);
        setLabelShowBackdrop("Cargando legalización");
        const res = await fetch(API_DEFAULT + '/legalizaciones/legalizaciones/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
            body: JSON.stringify({
                'anticipo': idAnticipo,
                'estudio': estudio,
                'fecha': fechaLegalizacion.toISOString(),
                'valor': valor,
                'rubro': rubro + 1,
                'responsable': false
            })
        })
        if (res.ok) {
            res
                .json()
                .then(res => postArchivos(res['id'], archivos[0], 'Comprobante de consignación', access));
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        postLegalizacion(newAccess);
                    }
                }
                else {
                    setShowBackdrop(false);
                    setMessage('El valor ingresado excede el valor por legalizar.');
                    setSeverity('warning');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            })
        }
        setLabelShowBackdrop('');
    }

    async function postArchivos(id, archivo, tipo, access = accessToken) {
        setLabelShowBackdrop("Cargando "+tipo);
        const formData = new FormData();
        formData.append('legalizacion', id);
        formData.append('documento', archivo);
        formData.append('tipo', tipo);

        const res = await fetch(API_DEFAULT + '/legalizaciones/documentos/', {
            method: 'POST',
            headers: { 'Authorization': 'Bearer ' + access },
            body: formData
        })
        if (res.ok) {
            setShowBackdrop(false);
            setMessage('La legalización se registró exitosamente.');
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);
            limpiar();
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        postArchivos(id, archivo, tipo, newAccess);
                    }
                }
            })
        }
    }

    return (
        <React.Fragment>
            <Divider style={{ marginTop: theme.spacing(2) }} />
            <CardHeader
                style={{ paddingTop: 8, paddingBottom: 8 }}
                title={
                    <Typography variant='h6'>Me sobró dinero</Typography>
                }
            />
            <Divider />
            <CardContent>
                <Typography variant='body1'>Recuerde que para hacer la devolución del dinero sobrante, debe realizar una consignación a la <span style={{ fontWeight: 500 }}>Cuenta Corriente del Banco de Bogotá No. 106-32877-6</span>, a nombre del <span style={{ fontWeight: 500 }}>Centro Nacional de Consultoría</span>. 
                NIT: <span style={{ fontWeight: 500 }}>800.011.951-9</span>. 
                Teléfono: <span style={{ fontWeight: 500 }}>(601) 339 4888.</span></Typography>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                    style={{ marginTop: theme.spacing(1) }}
                >
                    <Grid item xs={12}>
                        <Typography variant='body1'>Valor que devuelve:</Typography>
                        <CurrencyTextField
                            modifyValueOnWheel={false}
                            size='small'
                            variant='outlined'
                            currencySymbol='$'
                            outputFormat='string'
                            minimumValue='0'
                            textAlign='left'
                            decimalPlaces={0}
                            fullWidth
                            onChange={(e, v) => { setValor(v); errores[0] = false; setErrores([...errores]) }}
                            value={valor}
                            selectOnFocus={false}
                            error={errores[0]}
                        />
                    </Grid>
                </Grid>
                <Typography style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(1), fontWeight: 500, fontSize: 17 }} variant='body1'>Adjunte</Typography>
                <Typography variant='body1' style={{ marginRight: theme.spacing(2) }}>
                    <span style={{ marginRight: theme.spacing(1) }}>Comprobante de consignación:</span>
                    <Button size='small' variant='outlined' color={archivos[0] ? 'secondary' : ''} style={{ marginRight: theme.spacing(1), height: 25 }} onClick={() => archivos[0] ? limpiarArchivos(0) : hiddenInput.current.click()}>
                        <Typography variant='button'>{archivos[0] ? 'Limpiar' : 'Cargar archivo'}</Typography>
                    </Button>
                    <span style={{ fontSize: 14, color: errores[1] ? '#e53935' : null }}>{archivos[0] ? archivos[0].name : 'Ningún archivo cargado'}</span>
                </Typography>
                <input
                    type='file'
                    ref={hiddenInput}
                    style={{ display: 'none' }}
                    onChange={(e) => { cargarArchivo(e.target.files[0], 0); e.target.value = null; errores[1] = false; setErrores([...errores]) }}
                />
                <Divider style={{ marginTop: theme.spacing(0.5) }} />
            </CardContent>
            <Grid
                container
                direction='row'
                alignItems='center'
                justify='flex-end'
                spacing={1}
            >
                <Grid item style={{ display: 'flex' }}>
                    <Button style={{ marginRight: theme.spacing(1) }} variant='contained' color='primary' onClick={() => validar()}>Enviar</Button>
                    <ThemeProvider theme={themeDownloadButton}>
                        <Button style={{ marginRight: theme.spacing(1) }} variant='contained' color='primary' onClick={() => limpiar()}>Limpiar</Button>
                    </ThemeProvider>
                    <Button component={Link} to={path} variant='contained' color='secondary'>Regresar</Button>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default Devolucion;