import React, { useState, useRef } from 'react';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import {
    CardHeader,
    CardContent,
    Divider,
    Typography,
    Grid,
    TextField,
    Radio,
    RadioGroup,
    FormControlLabel,
    Button,
    Collapse
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
    Link
} from "react-router-dom";
import NumberFormat from 'react-number-format';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';

import departamentos from '../../../../../data/departamentos';
import municipios from '../../../../../data/municipios';
import generos from '../../../../../data/generos';
import tipos_persona from '../../../../../data/tiposPersona';
import tipos_documento from '../../../../../data/tiposDocumento';


let themeDownloadButton = createMuiTheme({
    palette: {
        primary: {
            main: '#0277BD'
        }
    },
    typography: {
        fontFamily: 'Roboto'
    }
});
themeDownloadButton = responsiveFontSizes(themeDownloadButton);

function Alquiler2(props) {
    const { theme, getAccessTokenWithRefresh, getApiKeyRut} = props;
    const path = props.path;
    const [accessToken, setAccessToken] = props.accessToken;
    const API_DEFAULT = props.API_DEFAULT;
    const API_RUT = props.API_RUT;
    const API_KEY_RUT = props.API_KEY_RUT;
    const estudio = props.estudio;
    const fechaLegalizacion = props.fechaLegalizacion;
    const rubro = props.rubro;
    const [iva, setIva] = props.iva;
    const [archivos, setArchivos] = props.archivos;
    const hiddenInputs = [useRef(null), useRef(null), useRef(null)];
    const cargarArchivo = props.cargarArchivo;
    const setVerDiferencias = props.setVerDiferencias;
    const limpiar = props.limpiar;
    const setSnack = props.setSnack;
    const setSeverity = props.setSeverity;
    const setMessage = props.setMessage;
    const setShowBackdrop = props.setShowBackdrop;
    const setLabelShowBackdrop = props.setLabelShowBackdrop;

    const idAnticipo = props.idAnticipo;

    const [prNombre, setPrNombre] = useState('');
    const [sgNombre, setSgNombre] = useState('');
    const [prApellido, setPrApellido] = useState('');
    const [sgApellido, setSgApellido] = useState('');
    const [razonSocial, setRazonSocial] = useState('');
    const [tipoDoc, setTipoDoc] = useState('');
    const [nit, setNit] = useState('');
    const [dv, setDv] = useState(null);
    const [sexo, setSexo] = useState(2);
    const [tipoPersona, setTipoPersona] = useState(2);

    const [direccion, setDireccion] = useState('');
    const [departamento, setDepartamento] = useState(null);
    const [municipio, setMunicipio] = useState(null);
    const [telefono, setTelefono] = useState('');
    const [correo, setCorreo] = useState('');
    const [depOrigen, setDepOrigen] = useState(null);
    const [muniOrigen, setMuniOrigen] = useState(null);
    const [depDestino, setDepDestino] = useState(null);
    const [muniDestino, setMuniDestino] = useState(null);
    const [descripcion, setDescripcion] = useState('');
    const [valor, setValor] = useState('');
    const [numFactura, setNumFactura] = useState('');

    const [errores, setErrores] = useState(Array(20).fill(false));

    const [datosDian, setDatosDian] = useState(false);

    async function postLegalizacion(access = accessToken) {
        setShowBackdrop(true);
        setLabelShowBackdrop("Cargando legalización");
        const res = await fetch(API_DEFAULT + '/legalizaciones/legalizaciones/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
            body: JSON.stringify({
                'anticipo': idAnticipo,
                'estudio': estudio,
                'fecha': fechaLegalizacion.toISOString(),
                'valor': valor,
                'rubro': rubro + 1,
                'responsable': iva === 0,
                'num_factura': numFactura
            })
        })
        if (res.ok) {
            res.json().then(res => postDatos(res['id']));
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        postLegalizacion(newAccess);
                    }
                }
                else {
                    setShowBackdrop(false);
                    setMessage('El valor ingresado excede el valor por legalizar.');
                    setSeverity('warning');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            })
        }
    }

    async function postDatos(id, access = accessToken) {
        setLabelShowBackdrop("Cargando datos del proveedor");
        var name = tipoDoc === 'NIT' ? razonSocial : prNombre + ' ' + sgNombre;
        var lastname = tipoDoc === 'NIT' ? '' : prApellido + ' ' + sgApellido;
        var sex = tipoDoc === 'NIT' ? 2 : generos.filter(g => g.genero === sexo)[0].id;
        var tipoP = tipoDoc === 'NIT' ? 2 : tipos_persona.filter(t => t.tipo === tipoPersona)[0].id;

       
        let res = await fetch(API_DEFAULT + '/legalizaciones/datos/intermunicipal/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
            body: JSON.stringify({
                'legalizacion': id,
                'tipo': 'Transporte intermunicipal - Alquiler carro/camión/moto particular',
                'nombre': name.slice(0,120),
                'apellidos': lastname,
                'nit': nit,
                'd_verificacion': dv,
                'direccion': direccion,
                'departamento': departamentos.filter(d => d.departamento === departamento)[0].dane,
                'municipio': municipios.filter(m => m.municipio === municipio)[0].daneMuni,
                'telefono': telefono,
                'email': correo,
                'descripcion': descripcion,
                'origen_departamento': departamentos.filter(d => d.departamento === depOrigen)[0].dane,
                'origen_municipio': municipios.filter(m => m.municipio === muniOrigen)[0].daneMuni,
                'destino_departamento': departamentos.filter(d => d.departamento === depDestino)[0].dane,
                'destino_municipio': municipios.filter(m => m.municipio === muniDestino)[0].daneMuni,
                'sexo': sex,
                'tipo_persona': tipoP,
                'tipo_identificacion': tipos_documento.filter(t => t.tipo === tipoDoc)[0].id
            })
        })
        
        if (res.ok) {
            let responses = [];
            if (iva === 0) {
                let resArchivo1 = await postArchivos(id, archivos[0], 'Factura', access);
                responses.push(resArchivo1.status);
            }
            if (iva === 1) {
                let resArchivo1 = await postArchivos(id, archivos[0], 'RUT', access);
                responses.push(resArchivo1.status);
                let resArchivo2 = await postArchivos(id, archivos[1], 'Soporte compra (recibo)', access);
                responses.push(resArchivo2.status);
            }
            if (responses.every((val) => val === 200)) {
                validatePost(id);
            }else{
                deleteLegalizacion(id);
            }
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        postDatos(id, newAccess);
                    }
                }else{
                    deleteLegalizacion(id);
                }
            });
        }
        setLabelShowBackdrop('');
    }

    async function postArchivos(id, archivo, tipo, access = accessToken) {
        setLabelShowBackdrop("Cargando "+tipo);
        const formData = new FormData();
        formData.append('legalizacion', id);
        formData.append('documento', archivo);
        formData.append('tipo', tipo);

        const res = await fetch(API_DEFAULT + '/legalizaciones/documentos/', {
            method: 'POST',
            headers: { 'Authorization': 'Bearer ' + access },
            body: formData
        })

        await res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    postArchivos(id, archivo, tipo, newAccess);
                }
            }
            else {
                return res;
            }
        });

        return res;
    }

    async function validatePost(id, access = accessToken) {
        setLabelShowBackdrop("");
        var res = await fetch(`${API_DEFAULT}/legalizaciones/legalizacion/id=${id}/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access }
        });

        if (res.ok) {
            res.json().then(res => {
                if(res.datos_transporte_intermunicipal.length === 0 || res.documentos.length === 0){
                    deleteLegalizacion(id);
                    return false;
                }
                setShowBackdrop(false);
                setMessage('La legalización se registró exitosamente.');
                setSeverity('success');
                setTimeout(() => { setSnack(true) }, 0);
                limpiar();
                return true;
            });
        } else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        validatePost(id, newAccess);
                    }
                } else {
                    deleteLegalizacion(id);
                    return false;
                }
            });
        }

    }

    async function deleteLegalizacion(id, access = accessToken) {
        const res = await fetch(API_DEFAULT + '/legalizaciones/legalizaciones/', {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
            body: JSON.stringify({
                'id': id,
            })
        });

        if (res.ok) {
            setShowBackdrop(false);
            setMessage('Ocurrió un error. Intente nuevamente.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
        } else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        deleteLegalizacion(id, newAccess);
                    }
                }
            });
        }
    }
    
    async function getNombresDian(access = API_KEY_RUT, first = true){
        if(nit !== ''){
            setShowBackdrop(true);
            setLabelShowBackdrop("Cargando nombre o razón social");
            setDv(null);
            setPrNombre('');
            setSgNombre('');
            setDatosDian(false);
            setPrApellido('');
            setSgApellido('');
            setRazonSocial('');
            
            
            var res = await fetch(API_RUT + nit, {
                headers: { 'Content-Type': 'application/json', 
                'access_token': access},
                mode:'cors'
            })
            
            if (res.ok) {
                res.json().then(res => {
                    if(res.tipo === "Persona"){
                        setTipoDoc('Cédula de ciudadanía');
                        setDv(res.dv);
                        setPrNombre(res.pr_nombre);
                        setSgNombre(res.sg_nombre);
                        setPrApellido(res.pr_apellido);
                        setSgApellido(res.sg_apellido);
                        setDatosDian(true);
                        errores[3] = false;
                        errores[4] = false;
                        setErrores([...errores]);
                    }else if(res.tipo === "Empresa"){
                        setTipoDoc('NIT');
                        setDv(res.dv);
                        setRazonSocial(res.razon_social);
                        setDatosDian(true);
                        errores[3] = false;
                        errores[4] = false;
                        setErrores([...errores])
                    }
                    
                    setShowBackdrop(false);
                    setLabelShowBackdrop('');
                });
            }else {
                res.json().then(async res => {
                    if (res['detail'] === 'token_not_valid'  ) {
                        if(first){
                            let newAccess = await getApiKeyRut();
                            if (newAccess) {
                                getNombresDian(newAccess, false);
                            }
                        }else{
                            setShowBackdrop(false);
                        }       
                    }else{
                        setShowBackdrop(false);
                    }
                    
                });
            }
        } 
        
    }

    function limpiarArchivos(index) {
        if (index.length !== undefined) {
            for (let i = 0; i < index.length; i++) {
                archivos[index[i]] = null;
            }
        }
        else {
            archivos[index] = null;
        }
        setArchivos([...archivos]);
    }

    function validar() {
        let errorValidar = false;
       
        if (tipoDoc === '' || tipoDoc === null) {
            errorValidar = true;
            errores[1] = true;
        }
    
        if (nit === '') {
            errorValidar = true;
            errores[2] = true;
        }else{
            errores[2] = false;
            setErrores([...errores]);
        }
        
        if((prNombre === '' && prApellido === '') && (razonSocial === '')){
            errorValidar = true;
            errores[4] = true;
        }
        if(sexo === '' || sexo === null || (tipoDoc !== 'NIT' && sexo === 2)){
            errorValidar = true;
            errores[5] = true;
        }
        if(tipoPersona === '' || tipoPersona === null || (tipoDoc !== 'NIT' && tipoPersona === 2)){
            errorValidar = true;
            errores[6] = true;
        }
        if (direccion === '') {
            errorValidar = true;
            errores[7] = true;
        }
        if (departamento === null || departamento === '') {
            errorValidar = true;
            errores[8] = true;
        }
        if (municipio === null || municipio === '') {
            errorValidar = true;
            errores[9] = true;
        }
        if (telefono === '') {
            errorValidar = true;
            errores[10] = true;
        }
        var regex = /^([\da-z_.-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/;
        if (regex.test(correo) === false && correo!== '') {
            errorValidar = true;
            errores[11] = true;
        }
        if (depOrigen === null) {
            errorValidar = true;
            errores[12] = true;
        }
        if (muniOrigen === null) {
            errorValidar = true;
            errores[13] = true;
        }
        if (depDestino === null) {
            errorValidar = true;
            errores[14] = true;
        }
        if (muniDestino === null) {
            errorValidar = true;
            errores[15] = true;
        }
        if (descripcion === '') {
            errorValidar = true;
            errores[16] = true;
        }
        if (valor === '' || valor === '0') {
            errorValidar = true;
            errores[17] = true;
        }
        if (archivos[0] === null) {
            errorValidar = true;
            errores[18] = true;
        }
        if (iva === 1 && (archivos[1] === null)) {
            errorValidar = true;
            errores[19] = true;
        }
        if (!errorValidar) {
            postLegalizacion()
        }
        else {
            setErrores([...errores]);
            setMessage('Existen campos sin diligenciar o con algún error.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
        }
    }

    const handleChangeNit = () => {
    var access = getApiKeyRut().then((data)=> {getNombresDian(access)});
}
    return (
        <React.Fragment>
            <Divider style={{ marginTop: theme.spacing(2) }} />
            <CardHeader
                style={{ paddingTop: 8, paddingBottom: 8 }}
                title={
                    <React.Fragment>
                        <Typography variant='subtitle2'>Transporte intermunicipal</Typography>
                        <Typography variant='h6'>&nbsp;Alquiler carro/camión/moto particular</Typography>
                    </React.Fragment>
                }
            />
            <Divider />
            <CardContent>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Typography variant='body1'>Número de Factura o Soporte:</Typography>
                            <TextField
                                value={numFactura}
                                onChange={(e) => {setNumFactura(e.target.value)}}
                                size='small'
                                variant='outlined'
                                fullWidth
                            />
                        </Grid>
                </Grid>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                >
                    <Grid item xs={12} sm={4}>
                        <Typography variant='body1'>Tipo de Documento:</Typography>
                        <Autocomplete
                            value={tipoDoc}
                            onChange={(e, v) => { setTipoDoc(v); errores[1] = false; setErrores([...errores]) }}
                            options={
                                tipos_documento.map(option => option.tipo)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    error={errores[1]}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={9} sm={5}>
                        <Typography variant='body1'>Número de Documento:</Typography>
                        <NumberFormat
                            customInput={TextField}
                            decimalSeparator={false}
                            format={(tipoDoc === 'NIT'?'#########':'##########')}
                            size='small'
                            variant='outlined'
                            fullWidth
                            error={errores[2]}
                            onBlur={()=> handleChangeNit()}
                            value={nit}
                            InputProps={{
                                onKeyUp: (e) => {
                                    if (e.key === 'ArrowLeft'){
                                        e.stopPropagation();
                                    }else{
                                        setNit(e.target.value)
                                    }
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={3} sm={3}>
                        <Typography variant='body1'>DV:</Typography>
                        <NumberFormat
                            customInput={TextField}
                            decimalSeparator={false}
                            format='##########'
                            value={dv}
                            onChange={(e) => { if(datosDian===false){setDv(e.target.value)};errores[3] = false; setErrores([...errores]) }}
                            size='small'
                            variant='outlined'
                            fullWidth
                            error={errores[3]}
                        />
                    </Grid>
                </Grid>
                <Collapse in={tipoDoc === "Cédula de ciudadanía" || tipoDoc === "Cédula de extrajería" || tipoDoc === "Pasaporte" } mountOnEnter unmountOnExit>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={2}
                        style={{ marginTop: theme.spacing(1) }}
                    >
                        <Grid item xs={6} md={3}>
                            <Typography variant='body1'>Primer nombre:</Typography>
                            <TextField
                                value={prNombre}
                                onChange={(e) => {if(datosDian===false){setPrNombre(e.target.value)}; errores[4] = false; setErrores([...errores]) }}
                                size='small'
                                variant='outlined'
                                fullWidth
                                error={errores[4]}
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Typography variant='body1'>Segundo nombre:</Typography>
                            <TextField
                                value={sgNombre}
                                onChange={(e) => {if(datosDian===false){setSgNombre(e.target.value)}; setErrores([...errores]) }}
                                size='small'
                                variant='outlined'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Typography variant='body1'>Primer Apellido:</Typography>
                            <TextField
                                value={prApellido}
                                onChange={(e) => {if(datosDian===false){setPrApellido(e.target.value)}; errores[4] = false; setErrores([...errores]) }}
                                size='small'
                                variant='outlined'
                                fullWidth
                                error={errores[4]}
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Typography variant='body1'>Segundo Apellido:</Typography>
                            <TextField
                                value={sgApellido}
                                onChange={(e) => {if(datosDian===false){setSgApellido(e.target.value)} }}
                                size='small'
                                variant='outlined'
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={2}
                        style={{ marginTop: theme.spacing(1) }}
                    >
                        <Grid item xs={12} sm={4}>
                        <Typography variant='body1'>Sexo:</Typography>
                        <Autocomplete
                            value={sexo}
                            onChange={(e, v) => { setSexo(v); errores[3] = false; errores[5] = false; setErrores([...errores]) }}
                            options={
                                generos.map(option => option.genero)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    error={errores[5]}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant='body1'>Tipo de Persona:</Typography>
                        <Autocomplete
                            value={tipoPersona}
                            onChange={(e, v) => { setTipoPersona(v); errores[6] = false; setErrores([...errores]) }}
                            options={
                                tipos_persona.map(option => option.tipo)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    error={errores[6]}
                                />
                            )}
                        />
                    </Grid>
                    </Grid>
                </Collapse>
                <Collapse in={tipoDoc === "NIT" } mountOnEnter unmountOnExit>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={2}
                        style={{ marginTop: theme.spacing(1) }}
                    >
                        <Grid item xs={12} md={12}>
                            <Typography variant='body1'>Razón social:</Typography>
                            <TextField
                                value={razonSocial}
                                onChange={(e) => {if(datosDian===false){setRazonSocial(e.target.value)}; errores[4] = false; setErrores([...errores]) }}
                                size='small'
                                variant='outlined'
                                fullWidth
                                error={errores[4]}
                            />
                        </Grid>
                    </Grid>
                </Collapse>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                    style={{ marginTop: theme.spacing(1) }}
                >
                    <Grid item xs={12} md={5}>
                        <Typography variant='body1'>Dirección:</Typography>
                        <TextField
                            value={direccion}
                            onChange={(e) => { setDireccion(e.target.value); errores[7] = false; setErrores([...errores]) }}
                            size='small'
                            variant='outlined'
                            fullWidth
                            error={errores[7]}
                            inputProps={{ maxLength: 30 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm>
                        <Typography variant='body1'>Departamento:</Typography>
                        <Autocomplete
                            value={departamento}
                            onChange={(e, v) => { setDepartamento(v); setMunicipio(null); errores[8] = false; setErrores([...errores]) }}
                            options={
                                departamentos.map(option => option.departamento)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    error={errores[8]}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs>
                        <Typography variant='body1'>Municipio:</Typography>
                        <Autocomplete
                            style={{ backgroundColor: departamento === null ? '#E0E0E0' : null, borderRadius: 4 }}
                            disabled={departamento === null}
                            value={municipio}
                            onChange={(e, v) => { setMunicipio(v); errores[9] = false; setErrores([...errores]) }}
                            options={
                                municipios.filter(m => m.departamento === departamento).map(option => option.municipio)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    error={errores[9]}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                    style={{ marginTop: theme.spacing(1) }}
                >
                    <Grid item xs={12} sm={6}>
                        <Typography variant='body1'>Teléfono o celular:</Typography>
                        <NumberFormat
                            customInput={TextField}
                            decimalSeparator={false}
                            format='##########'
                            value={telefono}
                            onChange={(e) => { setTelefono(e.target.value); errores[10] = false; setErrores([...errores]) }}
                            size='small'
                            variant='outlined'
                            fullWidth
                            error={errores[10]}
                        />
                    </Grid>
                    <Grid item xs>
                        <Typography variant='body1'>Correo electrónico:</Typography>
                        <TextField
                            value={correo}
                            onChange={(e) => { setCorreo(e.target.value.toLowerCase()); errores[11] = false; setErrores([...errores]) }}
                            size='small'
                            variant='outlined'
                            fullWidth
                            error={errores[11]}
                        />
                    </Grid>
                </Grid>
                <Typography style={{ marginTop: theme.spacing(2), fontWeight: 500, fontSize: 17 }} variant='body1'>Origen</Typography>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                    style={{ paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1) }}
                >
                    <Grid item xs={12} sm={6}>
                        <Typography variant='body1'>Departamento:</Typography>
                        <Autocomplete
                            value={depOrigen}
                            onChange={(e, v) => { setDepOrigen(v); setMuniOrigen(null); errores[12] = false; setErrores([...errores]) }}
                            options={
                                departamentos.map(option => option.departamento)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    error={errores[12]}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs>
                        <Typography variant='body1'>Municipio:</Typography>
                        <Autocomplete
                            style={{ backgroundColor: depOrigen === null ? '#E0E0E0' : null, borderRadius: 4 }}
                            disabled={depOrigen === null}
                            value={muniOrigen}
                            onChange={(e, v) => { setMuniOrigen(v); errores[13] = false; setErrores([...errores]) }}
                            options={
                                municipios.filter(m => m.departamento === depOrigen).map(option => option.municipio)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    error={errores[13]}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Typography style={{ marginTop: theme.spacing(2), fontWeight: 500, fontSize: 17 }} variant='body1'>Destino</Typography>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                    style={{ paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1) }}
                >
                    <Grid item xs={12} sm={6}>
                        <Typography variant='body1'>Departamento:</Typography>
                        <Autocomplete
                            value={depDestino}
                            onChange={(e, v) => { setDepDestino(v); setMuniDestino(null); errores[14] = false; errores[15] = false; setErrores([...errores]) }}
                            options={
                                departamentos.map(option => option.departamento)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    error={errores[14]}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs>
                        <Typography variant='body1'>Municipio:</Typography>
                        <Autocomplete
                            style={{ backgroundColor: depDestino === null ? '#E0E0E0' : null, borderRadius: 4 }}
                            disabled={depDestino === null}
                            value={muniDestino}
                            onChange={(e, v) => { setMuniDestino(v); errores[15] = false; setErrores([...errores]) }}
                            options={
                                municipios.filter(m => m.departamento === depDestino).map(option => option.municipio)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    error={errores[15]}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Typography style={{ marginTop: theme.spacing(2) }} variant='body1'>Descripción del gasto:</Typography>
                <TextField
                    value={descripcion}
                    onChange={(e) => { setDescripcion(e.target.value); errores[16] = false; setErrores([...errores]) }}
                    size='small'
                    variant='outlined'
                    fullWidth
                    multiline
                    rows={1}
                    error={errores[16]}
                    inputProps={{ maxLength: 50 }}
                />
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                    style={{ marginTop: theme.spacing(1) }}
                >
                    <Grid item xs={12} md={4}>
                        <Typography variant='body1'>Valor:</Typography>
                        <CurrencyTextField
                            modifyValueOnWheel={false}
                            size='small'
                            variant='outlined'
                            currencySymbol='$'
                            outputFormat='string'
                            minimumValue='0'
                            textAlign='left'
                            decimalPlaces={0}
                            fullWidth
                            onChange={(e, v) => { setValor(v); errores[17] = false; setErrores([...errores]) }}
                            value={valor}
                            selectOnFocus={false}
                            error={errores[17]}
                        />
                    </Grid>
                    <Grid item xs>
                        <Typography variant='body1'>Seleccione el tipo de documento que va a legalizar:</Typography>
                        <Grid
                            container
                            direction='row'
                            alignItems='center'
                            spacing={1}
                            style={{ marginLeft: theme.spacing(0) }}
                        >
                            <Grid item>
                                <RadioGroup
                                    row
                                    value={iva}
                                    onChange={(e) => { setIva(parseInt(e.target.value)); limpiarArchivos([0, 1]); errores[18] = false; errores[19] = false; setErrores([...errores]) }}
                                >
                                    <FormControlLabel value={0} control={<Radio color='primary' />} label='Responsable de IVA' />
                                    <FormControlLabel value={1} control={<Radio color='primary' />} label='NO responsable de IVA' />
                                </RadioGroup>
                            </Grid>
                            <Grid item>
                                <Button size='small' variant='outlined' style={{ height: 25 }} onClick={() => setVerDiferencias(true)}>
                                    <Typography variant='button'>Ver diferencias</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
            <Collapse in={iva === 0} mountOnEnter unmountOnExit>
                <Divider />
                <CardHeader
                    style={{ paddingTop: 8, paddingBottom: 8 }}
                    title={
                        <Typography style={{ fontSize: 18 }} variant='h6'>Responsable de IVA</Typography>
                    }
                />
                <Divider />
                <CardContent style={{ paddingBottom: theme.spacing(2) }}>
                    <Typography variant='body1'>&#8226; Régimen común</Typography>
                    <Typography variant='body1'>&#8226; Grandes contribuyentes</Typography>
                    <Typography variant='body1'>&#8226; Autoretenedores</Typography>
                    <Typography style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(1), fontWeight: 500, fontSize: 17 }} variant='body1'>Adjunte</Typography>
                    <Typography variant='body1' style={{ marginRight: theme.spacing(2) }}>
                        <span style={{ marginRight: theme.spacing(1) }}>Factura:</span>
                        <Button size='small' variant='outlined' color={archivos[0] ? 'secondary' : ''} style={{ marginRight: theme.spacing(1), height: 25 }} onClick={() => archivos[0] ? limpiarArchivos(0) : hiddenInputs[0].current.click()}>
                            <Typography variant='button'>{archivos[0] ? 'Limpiar' : 'Cargar archivo'}</Typography>
                        </Button>
                        <span style={{ fontSize: 14, color: errores[18] ? '#e53935' : null }}>{archivos[0] ? archivos[0].name : 'Ningún archivo cargado'}</span>
                    </Typography>
                    <input
                        type='file'
                        ref={hiddenInputs[0]}
                        style={{ display: 'none' }}
                        onChange={(e) => { cargarArchivo(e.target.files[0], 0); e.target.value = null; errores[18] = false; setErrores([...errores]) }}
                    />
                    <Divider style={{ marginTop: theme.spacing(0.5) }} />
                </CardContent>
            </Collapse>
            <Collapse in={iva === 1} mountOnEnter unmountOnExit>
                <Divider />
                <CardHeader
                    style={{ paddingTop: 8, paddingBottom: 8 }}
                    title={
                        <Typography style={{ fontSize: 18 }} variant='h6'>NO responsable de IVA</Typography>
                    }
                />
                <Divider />
                <CardContent style={{ paddingBottom: theme.spacing(2) }}>
                    <React.Fragment>
                        <Typography variant='body1'>&#8226; Régimen simplificado</Typography>
                        <Typography variant='body1'>&#8226; Personas naturales</Typography>
                    </React.Fragment>
                    <Typography style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(1), fontWeight: 500, fontSize: 17 }} variant='body1'>Adjunte</Typography>
                    <Typography variant='body1' style={{ marginRight: theme.spacing(2) }}>
                        <span style={{ marginRight: theme.spacing(1) }}>RUT:</span>
                        <Button size='small' variant='outlined' color={archivos[0] ? 'secondary' : ''} style={{ marginRight: theme.spacing(1), height: 25 }} onClick={() => archivos[0] ? limpiarArchivos(0) : hiddenInputs[1].current.click()}>
                            <Typography variant='button'>{archivos[0] ? 'Limpiar' : 'Cargar archivo'}</Typography>
                        </Button>
                        <span style={{ fontSize: 14, color: errores[18] ? '#e53935' : null }}>{archivos[0] ? archivos[0].name : 'Ningún archivo cargado'}</span>
                    </Typography>
                    <input
                        type='file'
                        ref={hiddenInputs[1]}
                        style={{ display: 'none' }}
                        onChange={(e) => { cargarArchivo(e.target.files[0], 0); e.target.value = null; errores[18] = false; setErrores([...errores]) }}
                    />
                    <Divider style={{ marginTop: theme.spacing(0.5), marginBottom: theme.spacing(0.5) }} />

                    <Typography variant='body1' style={{ marginRight: theme.spacing(2) }}>
                        <span style={{ marginRight: theme.spacing(1) }}>Soporte de la compra (recibo):</span>
                        <Button  size='small' variant='outlined' color={archivos[1] ? 'secondary' : ''} style={{ marginRight: theme.spacing(1), height: 25 }} onClick={() => archivos[1] ? limpiarArchivos(1) : hiddenInputs[2].current.click()}>
                            <Typography variant='button'>{archivos[1] ? 'Limpiar' : 'Cargar archivo'}</Typography>
                        </Button>
                        <span style={{ fontSize: 14, color: errores[19] ? '#e53935' : null }}>{archivos[1] ? archivos[1].name : 'Ningún archivo cargado'}</span>
                    </Typography>

                    <input
                        type='file'
                        ref={hiddenInputs[2]}
                        style={{ display: 'none' }}
                        onChange={(e) => { cargarArchivo(e.target.files[0], 1); e.target.value = null; errores[19] = false; setErrores([...errores]) }}
                    />
                   {/* <Divider style={{ marginTop: theme.spacing(0.5), marginBottom: theme.spacing(0.5) }} />
                    <Typography variant='body1'>
                        <span style={{ marginRight: theme.spacing(1) }}>Si no cuenta con recibo, diligencie el siguiente formato:</span>
                        <Button disabled={archivos[1]} size='small' variant='outlined' color='primary' style={{ marginRight: theme.spacing(1), height: 25 }} onClick={() => setVerFormato(true)}>
                            <Typography variant='button'>{concepto !== '' ? 'Editar' : 'Formato'}</Typography>
                        </Button>
                        {concepto !== '' ? <span style={{ fontSize: 14 }}>Formato diligenciado</span> : archivos[1] === null ? <span style={{ fontSize: 14, color: errores[14] ? '#e53935' : null }}>Formato <span style={{ fontWeight: 500 }}>NO</span> diligenciado</span> : null}
                </Typography>*/}
                    <Divider style={{ marginTop: theme.spacing(0.5) }} />
                </CardContent>
            </Collapse>
            <Grid
                container
                direction='row'
                alignItems='center'
                justify='flex-end'
                spacing={1}
            >
                <Grid item style={{ display: 'flex' }}>
                    {iva !== null ?
                        <Button style={{ marginRight: theme.spacing(1) }} variant='contained' color='primary' onClick={() => validar()}>Enviar</Button>
                        :
                        null
                    }
                    <ThemeProvider theme={themeDownloadButton}>
                        <Button style={{ marginRight: theme.spacing(1) }} variant='contained' color='primary' onClick={() => limpiar()}>Limpiar</Button>
                    </ThemeProvider>
                    <Button component={Link} to={path} variant='contained' color='secondary'>Regresar</Button>
                </Grid>
            </Grid>
        </React.Fragment >
    );
}

export default Alquiler2;