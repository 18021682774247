import React, { useState, useEffect } from 'react';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Typography, TextField, Divider, Collapse, IconButton} from '@material-ui/core';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import GetAppIcon from '@material-ui/icons/GetApp';
import { saveAs } from 'file-saver';

import capitalize from '../../../styles/capitalize'
import DineroEnviado from '../../CoordAdm/DineroEnviado';
import DialogoRevision from './DialogoRevision';
import DialogoRevisionGiro from './DialogoRevisionGiro';
import conceptos from '../../../data/conceptos'

let themeDownloadButton = createMuiTheme({
    palette: {
        primary: {
            main: '#0277BD'
        }
    },
    typography: {
        fontFamily: 'Roboto'
    }
});
themeDownloadButton = responsiveFontSizes(themeDownloadButton);

function DialogoDetalle(props) {
    const { theme, validRole, setSeverity, setSnack, setShowBackdrop, setMessage, API_DEFAULT, fetchAnticipos, getAccessTokenWithRefresh} = props;
    const [accessToken,  setAccessToken] = props.accessToken;
    const [open, setOpen] = props.open;
    const [openDinero, setOpenDinero] = useState(false);
    const anticipoId = props.anticipoId;
    const [anticipo, setAnticipo] = useState(null);
    const [fecha, setFecha] = useState(new Date());
    const [aprobado, setAprobado] = useState(false);
    const [rechazado, setRechazado] = useState(false);
    const [girado, setGirado] = useState(false);
    const [validadoPor, setValidadoPor] = useState('');
    const [fechaValidacion, setFechaValidacion] = useState(new Date());
    const [encuestador, setEncuestador] = useState('');
    const [tipoContrato, setTipoContrato]   = useState('');
    const [cargo, setCargo]   = useState('');
    const [dineroAlojamiento, setDineroAlojamiento] = useState('');
    const [dineroManutencion, setDineroManutencion] = useState('');
    const [dineroUrbano, setDineroUrbano] = useState('');
    const [dineroIntermunicipal, setDineroIntermunicipal] = useState('');
    const [dineroRural, setDineroRural] = useState('');
    const [dineroOtros, setDineroOtros] = useState('');
    const [dineroEnviado, setDineroEnviado] = useState('');
    const [medioEnvio, setMedioEnvio] = useState([]);
    const [otroMedioEnvio, setOtroMedioEnvio] = useState('');
    const [cuenta, setCuenta] = useState('');
    const [estudio, setEstudio] = useState('');
    const [coordinador, setCoordinador] = useState('');
    const [ciudad, setCiudad] = useState(null);
    const [regional, setRegional] = useState(null);
    const [directorCampo, setDirectorCampo] = useState('');
    const [directorAprueba, setDirectorAprueba] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [observacion, setObservacion] = useState('');
    const [soporte, setSoporte] = useState(null);
    const [detallesAlojamiento, setDetallesAlojamiento] = useState([]);
    const [detallesManutencion, setDetallesManutencion] = useState([]);
    const [detallesUrbano, setDetallesUrbano] = useState([]);
    const [detallesIntermunicipal, setDetallesIntermunicipal] = useState([]);
    const [detallesRural, setDetallesRural] = useState([]);
    const [detallesOtros, setDetallesOtros] = useState([]);

    const [openRevision, setOpenRevision] = useState(false);
    const [openGirado, setOpenGirado] = useState(false);

    async function postRevision(idAnticipo, aprobado, observacion, access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(API_DEFAULT + `/legalizaciones/actualizar_anticipo/id=${idAnticipo}/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
            body: JSON.stringify({
                'aprobado': aprobado,
                'rechazado': !aprobado,
                'observacion': observacion
            })
        })
        if (res.ok) {
            setMessage(`El anticipo fue ${aprobado ? 'aprobado' : 'rechazado'} exitosamente.`);
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);
            setShowBackdrop(false);
            fetchAnticipos()
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        postRevision(idAnticipo, aprobado, observacion, newAccess);
                    }
                }
                else {
                    setMessage('Hubo un problema cargando los datos, intente nuevamente.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                    setShowBackdrop(false);
                }
            });
        }
    }

    async function postGirado(idAnticipo, access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(API_DEFAULT + `/legalizaciones/actualizar_anticipo/id=${idAnticipo}/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
            body: JSON.stringify({
                'girado': girado
            })
        })
        if (res.ok) {
            setMessage(`El anticipo fue actualizado exitosamente.`);
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);
            setShowBackdrop(false);
            fetchAnticipos()
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        postGirado(idAnticipo, newAccess);
                    }
                }
                else {
                    setMessage('Hubo un problema cargando los datos, intente nuevamente.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                    setShowBackdrop(false);
                }
            });
        }
    }

    async function fetchAnticipo(access = accessToken, id=anticipoId) {
        setShowBackdrop(true);
        const res = await fetch(API_DEFAULT + `/legalizaciones/anticipo/id=${id}/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
        })
        if (res.ok) {
            res.json().then(res => {

                setFecha(new Date(res.fecha).toLocaleString('es-ES'));
                setAnticipo(res);
                setAprobado(res.aprobado);
                setRechazado(res.rechazado);
                setGirado(res.girado);
                setValidadoPor(res.validado_por);
                setFechaValidacion(new Date(res.fecha_validacion).toLocaleString('es-ES'));
                setEncuestador(res.encuestador.nombre);
                setTipoContrato(res.encuestador.tipo_contrato);
                setCargo(res.encuestador.cargo);
                setMedioEnvio(res.medio_envio);
                setOtroMedioEnvio(res.otro_medio_envio);
                setCuenta(res.cuenta);
                setEstudio(res.estudio);
                setCoordinador(res.coordinador.nombre);
                setCiudad(res.municipio);
                setRegional(capitalize(res.regional));
                setDirectorCampo(res.director.nombre);
                setDirectorAprueba(res.director_aprueba.nombre);
                setDescripcion(res.descripcion);
                setObservacion(res.observacion);
                setCamposDinero(res);
                setShowBackdrop(false);
            });
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        fetchAnticipo(newAccess, id);
                    }
                }
            });
        }
    }

    function setCamposDinero(res){
        setDineroAlojamiento(res.valor_alojamiento);
        setDineroManutencion(res.valor_manutencion);
        setDineroUrbano(res.valor_transporte_urbano);
        setDineroIntermunicipal(res.valor_transporte_intermunicipal);
        setDineroRural(res.valor_transporte_rural);
        setDineroOtros(res.valor_otro);
        setDineroEnviado(res.valor);
        setSoporte(res.soporte.length > 0?res.soporte[0].soporte: null);
        setDetallesAlojamiento([]);
        setDetallesManutencion([]);
        setDetallesUrbano([]);
        setDetallesIntermunicipal([]);
        setDetallesRural([]);
        setDetallesOtros([]);
        
        res.detalle.map((row) => {
            let conceptosFiltrado = conceptos.filter(x => x.idRubro === row.rubro);
            row['rubro'] = conceptosFiltrado.length > 0 ? conceptosFiltrado[0].rubro: row['rubro'];
            row['total'] = row.personas * row.unidades * row.valorUnitario;
            if(row.rubro === 'alojamiento'){
                setDetallesAlojamiento(oldArray => [...oldArray, row]);
            }else if(row.rubro === 'manutencion'){
                setDetallesManutencion(oldArray => [...oldArray, row]);
            }else if(row.rubro === 'urbano'){
                setDetallesUrbano(oldArray => [...oldArray, row]);
            }else if(row.rubro === 'intermunicipal'){
                setDetallesIntermunicipal(oldArray => [...oldArray, row]);
            }else if(row.rubro === 'rural'){
                setDetallesRural(oldArray => [...oldArray, row]);
            }else if(row.rubro === 'otros'){
                setDetallesOtros(oldArray => [...oldArray, row]);
            }
            return row;
        });
    }

    const limpiar = () => {
        setFecha(new Date(anticipo.fecha).toLocaleString('es-ES'));
        setAprobado(anticipo.aprobado);
        setRechazado(anticipo.rechazado);
        setGirado(anticipo.girado);
        setValidadoPor(anticipo.validado_por);
        setFechaValidacion(new Date(anticipo.fecha_validacion).toLocaleString('es-ES'));
        setEncuestador(anticipo.encuestador.nombre);
        setTipoContrato(anticipo.encuestador.tipo_contrato);
        setCargo(anticipo.encuestador.cargo);
        setMedioEnvio(anticipo.medio_envio);
        setOtroMedioEnvio(anticipo.otro_medio_envio);
        setCuenta(anticipo.cuenta);
        setEstudio(anticipo.estudio);
        setCoordinador(anticipo.coordinador);
        setCiudad(anticipo.municipio);
        setRegional(anticipo.regional);
        setDirectorCampo(anticipo.director_campo);
        setDirectorAprueba(anticipo.director_aprueba);
        setDescripcion(anticipo.descripcion);
        setSoporte(anticipo.soporte.length > 0?anticipo.soporte[0].soporte: null);
        setDetallesAlojamiento([]);
        setDetallesManutencion([]);
        setDetallesUrbano([]);
        setDetallesIntermunicipal([]);
        setDetallesRural([]);
        setDetallesOtros([]);
        setOpen(false);
    }

    useEffect(() => {
        if(open && validRole){
            fetchAnticipo(accessToken, anticipoId);
        }
    },[open, validRole]);// eslint-disable-line react-hooks/exhaustive-deps
    
    return (
        <Dialog
            open={open}
            onClose={() => limpiar()}
            fullWidth={true}
            maxWidth='xl'
        >
        <DialogTitle style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }}>
            Detalle Anticipo {anticipoId}
            
        </DialogTitle>
            <Divider />
            <DialogContent style={{ paddingBottom: theme.spacing(2) }}>
                <Typography variant='h6' style={{ marginRight: theme.spacing(1), marginTop: theme.spacing(1) }}>Estado: <span style={{ fontSize: 'inherit', color: (girado ? '#0051FF': (rechazado ? '#0277BD' : (aprobado?'#00695C':'#FF8700')))}}>{girado?'Girado':(rechazado ? 'Rechazado' : (aprobado?'Aprobado':'Pendiente'))}</span></Typography>
                <Typography style={{fontSize: 14, paddingBottom: theme.spacing(1.5)}}>
                    {aprobado ?
                        'Aprobado por ' + validadoPor +' el '+ fechaValidacion
                        : 
                        (rechazado?
                            'Rechazado por '+validadoPor +' el '+ fechaValidacion
                            : 
                            null
                        )
                    }
                </Typography>
                <Divider />
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                    style={{ marginTop: theme.spacing(1) }}
                    >
                        <Grid item xs={12} md={5}>
                            <Typography variant='body1'>Supervisor o encuestador:</Typography>
                            <TextField
                                value={encuestador}
                                size='small'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                        <Grid item md={3} sm xs={12}>
                            <Typography variant='body1'>Tipo de contrato:</Typography>
                            <TextField
                                value={tipoContrato}
                                size='small'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                        <Grid item xs>
                            <Typography variant='body1'>Cargo:</Typography>
                            <TextField
                                value={cargo}
                                size='small'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={2}
                        style={{ marginTop: theme.spacing(1) }}
                    >
                    <Grid item md={5} sm={12} xs={12}>
                            <Grid
                                container
                                direction='row'
                                alignItems='center'
                                spacing={1}
                            >
                                <Grid item xs>
                                    <Typography variant='body1'>Dinero enviado:</Typography>
                                    <CurrencyTextField
                                        value={dineroEnviado}
                                        modifyValueOnWheel={false}
                                        size='small'
                                        variant='outlined'
                                        currencySymbol='$'
                                        outputFormat='string'
                                        minimumValue='0'
                                        textAlign='left'
                                        decimalPlaces={0}
                                        fullWidth
                                        selectOnFocus={false}
                                        readOnly={true}
                                        placeholder='Presione el botón INGRESAR'
                                    />
                                </Grid>
                                <Grid item>
                                    <Button onClick={() => setOpenDinero(true)} style={{ marginTop: theme.spacing(3) }} size='small' variant='outlined' color='primary'>Ingresar</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <Typography variant='body1'>Medio de envío:</Typography>
                            <TextField
                                value={medioEnvio}
                                size='small'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                        <Grid item md xs sm>
                            <Collapse in={medioEnvio === "Otro"}>
                                <Typography variant='body1'>¿Cuál?:</Typography>
                                <TextField
                                    value={otroMedioEnvio}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    inputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Collapse>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={2}
                        style={{ marginTop: theme.spacing(1) }}
                    >
                        <Grid item md sm={6} xs={12}>
                            <Typography variant='body1'>No. cuenta:</Typography>
                            <TextField
                                value={cuenta}
                                size='small'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                        <Grid item xs>
                            <Typography variant='body1'>Fecha de envío:</Typography>
                            <TextField
                                value={fecha}
                                size='small'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={2}
                        style={{ marginTop: theme.spacing(1) }}
                    >
                        <Grid item xs={12} md={3}>
                            <Typography variant='body1'>Estudio:</Typography>
                            <TextField
                                value={estudio}
                                size='small'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                        <Grid item md sm={7} xs={12}>
                            <Typography variant='body1'>Coordinador(a) que solicitó el anticipo:</Typography>
                            <TextField
                                value={coordinador}
                                size='small'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                        <Grid item xs>
                            <Typography variant='body1'>Ciudad:</Typography>
                            <TextField
                                value={ciudad}
                                size='small'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={2}
                        style={{ marginTop: theme.spacing(1) }}
                    >
                        <Grid item xs={12} sm={5} md={3}>
                            <Typography variant='body1'>Regional:</Typography>
                            <TextField
                                value={regional}
                                size='small'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md sm={7}>
                            <Typography variant='body1'>Director(a) de campo que aprobó:</Typography>
                            <TextField
                                value={directorCampo}
                                size='small'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                        <Grid item xs>
                            <Typography variant='body1'>Director(a) de proyecto que aprobó:</Typography>
                            <TextField
                                value={directorAprueba}
                                size='small'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={2}
                        style={{ marginTop: theme.spacing(1) }}
                    >
                        <Grid item xs={12} md={soporte !== null?11:12} sm={soporte !== null?11:12}>
                            <Typography variant='body1'>Descripción:</Typography>
                            <TextField
                                style={{ marginBottom: theme.spacing(1) }}
                                value={descripcion}
                                size='small'
                                variant='outlined'
                                fullWidth
                                multiline
                                rows={4}
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                        {
                            soporte !== null?
                                <Grid item xs={12} md={1} sm={1}>
                                    <Typography variant='body1'>Soporte:</Typography>
                                    <IconButton onClick={() => saveAs(soporte, soporte.split('/').slice(-1)[0])} size='small'>
                                        <GetAppIcon style={{ height: 25, width: 'auto'}} color='primary' />
                                        <Typography variant='body1'>Descargar</Typography>
                                    </IconButton>
                                </Grid>
                            :
                            null
                        }
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={2}
                        style={{ marginTop: theme.spacing(1) }}
                    >
                        { rechazado?
                            <Grid item xs>
                                <Typography variant='body1'>Observación:</Typography>
                                <TextField
                                    value={observacion}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    inputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>:''}
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        justify='flex-end'
                        spacing={1}
                        style={{ marginTop: theme.spacing(1.5) }}
                    >
                    </Grid>
                   <DineroEnviado
                        theme={theme}
                        open={[openDinero, setOpenDinero]}
                        dineroAlojamiento={[dineroAlojamiento, setDineroAlojamiento]}
                        dineroManutencion={[dineroManutencion, setDineroManutencion]}
                        dineroUrbano={[dineroUrbano, setDineroUrbano]}
                        dineroIntermunicipal={[dineroIntermunicipal, setDineroIntermunicipal]}
                        dineroRural={[dineroRural, setDineroRural]}
                        dineroOtros={[dineroOtros, setDineroOtros]}
                        dineroEnviado={[dineroEnviado, setDineroEnviado]}
                        rowsAlojamiento={[detallesAlojamiento, setDetallesAlojamiento]}
                        rowsManutencion={[detallesManutencion, setDetallesManutencion]}
                        rowsUrbano={[detallesUrbano, setDetallesUrbano]}
                        rowsIntermunicipal={[detallesIntermunicipal, setDetallesIntermunicipal]}
                        rowsRural={[detallesRural, setDetallesRural]}
                        rowsOtros={[detallesOtros, setDetallesOtros]}
                        revision={true}
                        fetchCampos={fetchAnticipo}
                    />
                   
            </DialogContent>
            <Divider />
            <DialogActions style={{ padding: theme.spacing(1.5) }}>
                {
                    aprobado? 
                        girado?
                            <React.Fragment>
                                <ThemeProvider theme={themeDownloadButton}>
                                    <Button style={{ marginRight: theme.spacing(1) }} variant='contained' color='primary' onClick={() => { setGirado(false);setOpenGirado(true)}}>No girado</Button>
                                </ThemeProvider>
                            </React.Fragment>
                        :
                            <React.Fragment>
                                <Button style={{ marginRight: theme.spacing(1) }} variant='contained' color='primary' onClick={() => { setGirado(true); setOpenGirado(true)}}>Girado</Button>
                                <ThemeProvider theme={themeDownloadButton}>
                                    <Button style={{ marginRight: theme.spacing(1) }} variant='contained' color='primary' onClick={() => { setAprobado(false); setOpenRevision(true)}}>Rechazar</Button>
                                </ThemeProvider>
                            </React.Fragment>
                        
                    :
                        rechazado?
                            <React.Fragment>
                                <Button style={{ marginRight: theme.spacing(1) }} variant='contained' color='primary' onClick={() => { setAprobado(true); setOpenRevision(true) }}>Aprobar</Button>
                            </React.Fragment>
                        :
                            <React.Fragment>
                                <Button style={{ marginRight: theme.spacing(1) }} variant='contained' color='primary' onClick={() => { setAprobado(true); setOpenRevision(true) }}>Aprobar</Button>
                                <ThemeProvider theme={themeDownloadButton}>
                                    <Button style={{ marginRight: theme.spacing(1) }} variant='contained' color='primary' onClick={() => { setAprobado(false); setOpenRevision(true)}}>Rechazar</Button>
                                </ThemeProvider>
                            </React.Fragment>
                }
                
                <Button size='small' variant='contained' color='secondary' onClick={() => {limpiar()}} >
                    Regresar
                </Button>
                <DialogoRevision
                    theme={theme}
                    open={[openRevision, setOpenRevision]}
                    id={anticipoId}
                    setSeverity={setSeverity}
                    setMessage={setMessage}
                    setSnack={setSnack}
                    aprobado={aprobado}
                    postRevision={postRevision}
                    setOpenDetalle= {setOpen}
                />
                <DialogoRevisionGiro
                    theme={theme}
                    open={[openGirado, setOpenGirado]}
                    id={anticipoId}
                    postGirado={postGirado}
                    girado={girado}
                    setOpenDetalle= {setOpen}
                />
            </DialogActions>
        </Dialog>
       
    );
}

export default DialogoDetalle;