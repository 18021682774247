import React from 'react';
import {
    Typography,
    Grid,
    TextField,
    Divider,
    Radio,
    RadioGroup,
    FormControlLabel,
    Collapse
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import EsLocale from 'date-fns/locale/es';

function General(props) {
    const theme = props.theme;
    const [rubro, setRubro] = props.rubro;
    const [estudio, setEstudio] = props.estudio;
    const [fechaLegalizacion, setFechaLegalizacion] = props.fechaLegalizacion;
    const limpiar = props.limpiar;
    const setIva = props.setIva;
    const estudios = props.estudios;

    return (
        <React.Fragment>
            <Grid
                container
                direction='row'
                alignItems='center'
                spacing={2}
            >
                <Grid item xs={12} md={7}>
                    <Typography variant='body1'>Seleccione el estudio:</Typography>
                    <Autocomplete
                        value={estudio}
                        onChange={(e, v) => { setEstudio(v); if (v === null) { limpiar() } }}
                        options={
                            estudios.map(option => option.nombre_integra)
                        }
                        noOptionsText='No hay coincidencias'
                        renderInput={params => (
                            <TextField
                                {...params}
                                size='small'
                                variant='outlined'
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                <Grid item xs>
                    <Typography variant='body1'>Seleccione la fecha de legalización:</Typography>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={EsLocale}>
                        <DatePicker
                            value={fechaLegalizacion}
                            onChange={(date) => setFechaLegalizacion(date)}
                            size='small'
                            inputVariant='outlined'
                            format="EEEE, dd 'de' MMMM 'del' yyyy"
                            fullWidth={true}
                            disableToolbar={true}
                            minDate={(new Date()).setDate((new Date()).getDate() - 30)}
                            disableFuture={true}
                            autoOk={true}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            </Grid>
            <Collapse in={estudio !== null} mountOnEnter unmountOnExit>
                <Divider style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }} />
                <Typography variant='body1'>Seleccione el rubro al que está relacionada la legalización:</Typography>
                <RadioGroup
                    value={rubro}
                    onChange={(e) => { setRubro(parseInt(e.target.value)); setIva(null) }}
                >
                    <FormControlLabel value={0} control={<Radio color='primary' />} label='Alojamiento' />
                    <FormControlLabel value={1} control={<Radio color='primary' />} label='Manutención' />
                    <FormControlLabel value={2} control={<Radio color='primary' />} label='Transporte' />
                    <FormControlLabel value={3} control={<Radio color='primary' />} label='Otros' />
                    <FormControlLabel value={4} control={<Radio color='primary' />} label='Me sobró dinero' />
                </RadioGroup>
            </Collapse>
        </React.Fragment>
    );
}

export default General;