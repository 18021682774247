import React, { useState } from 'react';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import {
    CardHeader,
    CardContent,
    Divider,
    Typography,
    TextField,
    Grid,
    Collapse,
    Button
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
    Link
} from "react-router-dom";

import Alquiler from './TransporteInterno/Alquiler/Alquiler';
import Bus from './TransporteInterno/Bus/Bus';
import Taxi from './TransporteInterno/Taxi/Taxi';
import Propio from './TransporteInterno/Propio/Propio';

import Alquiler2 from './TransporteIntermunicipal/Alquiler/Alquiler2';
import Bus2 from './TransporteIntermunicipal/Bus/Bus2';
import Lancha from './TransporteIntermunicipal/Lancha/Lancha';
import Propio2 from './TransporteIntermunicipal/Propio/Propio2';

import Alquiler3 from './TransporteRural/Alquiler/Alquiler3';
import Lancha2 from './TransporteRural/Lancha/Lancha2';
import Caballo from './TransporteRural/Caballo/Caballo';
import Propio3 from './TransporteRural/Propio/Propio3';

let themeDownloadButton = createMuiTheme({
    palette: {
        primary: {
            main: '#0277BD'
        }
    },
    typography: {
        fontFamily: 'Roboto'
    }
});
themeDownloadButton = responsiveFontSizes(themeDownloadButton);

function Transporte(props) {
    const tiposTransporte = [
        'Transporte interno/urbano',
        'Transporte intermunicipal',
        'Transporte rural'
    ];

    const subtiposTransporte = [
        { tipo: 'Transporte interno/urbano', subtipo: 'Alquiler carro/camión/moto particular' },
        { tipo: 'Transporte interno/urbano', subtipo: 'Bus público' },
        { tipo: 'Transporte interno/urbano', subtipo: 'Taxi' },
        { tipo: 'Transporte interno/urbano', subtipo: 'Carro/camión/moto propia + gasolina' },
        { tipo: 'Transporte intermunicipal', subtipo: 'Alquiler carro/camión/moto particular' },
        { tipo: 'Transporte intermunicipal', subtipo: 'Bus/flota o taxi intermunicipal' },
        { tipo: 'Transporte intermunicipal', subtipo: 'Lanchas o transporte fluvial' },
        { tipo: 'Transporte intermunicipal', subtipo: 'Carro/camión/moto propia + gasolina' },
        { tipo: 'Transporte rural', subtipo: 'Alquiler carro/camión/moto particular' },
        { tipo: 'Transporte rural', subtipo: 'Lanchas o transporte fluvial' },
        { tipo: 'Transporte rural', subtipo: 'Alquiler de caballos' },
        { tipo: 'Transporte rural', subtipo: 'Carro/camión/moto propia + gasolina' },
    ]

    const { theme, getAccessTokenWithRefresh, getApiKeyRut} = props;
    const path = props.path;
    const [accessToken, setAccessToken] = props.accessToken;
    const API_DEFAULT = props.API_DEFAULT;
    const API_RUT = props.API_RUT;
    const API_KEY_RUT = props.API_KEY_RUT;
    const estudio = props.estudio;
    const fechaLegalizacion = props.fechaLegalizacion;
    const rubro = props.rubro;
    const [iva, setIva] = props.iva;
    const [archivos, setArchivos] = props.archivos;
    const cargarArchivo = props.cargarArchivo;
    const setVerDiferencias = props.setVerDiferencias;
    const [tipo, setTipo] = useState(null);
    const [subtipo, setSubtipo] = useState(null);
    const limpiarGeneral = props.limpiarGeneral;
    const setSnack = props.setSnack;
    const setSeverity = props.setSeverity;
    const setMessage = props.setMessage;
    const setShowBackdrop = props.setShowBackdrop;
    const setLabelShowBackdrop = props.setLabelShowBackdrop;

    const idAnticipo = props.idAnticipo;

    return (
        <React.Fragment>
            <Divider style={{ marginTop: theme.spacing(2) }} />
            <CardHeader
                style={{ paddingTop: 8, paddingBottom: 8 }}
                title={<Typography variant='h6'>Transporte</Typography>}
                
            />
            
            <Divider />
            <CardContent style={{ paddingBottom: 4 }}>
            <Typography variant='body2'>Recuerde que los transportes semanales se pagan por el aplicactivo de productividad.</Typography>
                <Typography variant='body1'>Seleccione el tipo de transporte correspondiente:</Typography>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                >
                    <Grid item xs={12} sm={5}>
                        <Autocomplete
                            value={tipo}
                            onChange={(e, v) => { setTipo(v); setSubtipo(null); setIva(null) }}
                            options={
                                tiposTransporte.map(option => option)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs>
                        <Autocomplete
                            disabled={tipo === null}
                            style={{ backgroundColor: tipo === null ? '#E0E0E0' : null, borderRadius: 4 }}
                            value={subtipo}
                            onChange={(e, v) => { setSubtipo(v); setIva(null) }}
                            options={
                                subtiposTransporte.filter(s => s.tipo === tipo).map(option => option.subtipo)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </CardContent>
            <Collapse in={tipo === subtiposTransporte[0].tipo && subtipo === subtiposTransporte[0].subtipo} mountOnEnter unmountOnExit>
                <Alquiler
                    theme={theme}
                    path={path}
                    API_DEFAULT={API_DEFAULT}
                    API_RUT={API_RUT}
                    API_KEY_RUT={API_KEY_RUT}
                    accessToken={[accessToken, setAccessToken]}
                    getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                    getApiKeyRut={getApiKeyRut}
                    idAnticipo={idAnticipo}
                    estudio={estudio}
                    fechaLegalizacion={fechaLegalizacion}
                    rubro={rubro}
                    iva={[iva, setIva]}
                    archivos={[archivos, setArchivos]}
                    cargarArchivo={cargarArchivo}
                    setVerDiferencias={setVerDiferencias}
                    setSnack={setSnack}
                    setSeverity={setSeverity}
                    setMessage={setMessage}
                    setShowBackdrop={setShowBackdrop}
                    setLabelShowBackdrop={setLabelShowBackdrop}
                    limpiar={limpiarGeneral}
                />
            </Collapse>
            <Collapse in={tipo === subtiposTransporte[1].tipo && subtipo === subtiposTransporte[1].subtipo} mountOnEnter unmountOnExit>
                <Bus
                    theme={theme}
                    path={path}
                    API_DEFAULT={API_DEFAULT}
                    API_RUT={API_RUT}
                    API_KEY_RUT={API_KEY_RUT}
                    accessToken={[accessToken, setAccessToken]}
                    getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                    getApiKeyRut={getApiKeyRut}
                    idAnticipo={idAnticipo}
                    estudio={estudio}
                    fechaLegalizacion={fechaLegalizacion}
                    rubro={rubro}
                    iva={[iva, setIva]}
                    archivos={[archivos, setArchivos]}
                    cargarArchivo={cargarArchivo}
                    setSnack={setSnack}
                    setSeverity={setSeverity}
                    setMessage={setMessage}
                    setShowBackdrop={setShowBackdrop}
                    setLabelShowBackdrop={setLabelShowBackdrop}
                    limpiar={limpiarGeneral}
                />
            </Collapse>
            <Collapse in={tipo === subtiposTransporte[2].tipo && subtipo === subtiposTransporte[2].subtipo} mountOnEnter unmountOnExit>
                <Taxi
                    theme={theme}
                    path={path}
                    API_DEFAULT={API_DEFAULT}
                    API_RUT={API_RUT}
                    API_KEY_RUT={API_KEY_RUT}
                    accessToken={[accessToken, setAccessToken]}
                    getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                    getApiKeyRut={getApiKeyRut}
                    idAnticipo={idAnticipo}
                    estudio={estudio}
                    fechaLegalizacion={fechaLegalizacion}
                    rubro={rubro}
                    iva={[iva, setIva]}
                    archivos={[archivos, setArchivos]}
                    cargarArchivo={cargarArchivo}
                    setSnack={setSnack}
                    setSeverity={setSeverity}
                    setMessage={setMessage}
                    setShowBackdrop={setShowBackdrop}
                    setLabelShowBackdrop={setLabelShowBackdrop}
                    limpiar={limpiarGeneral}
                />
            </Collapse>
            <Collapse in={tipo === subtiposTransporte[3].tipo && subtipo === subtiposTransporte[3].subtipo} mountOnEnter unmountOnExit>
                <Propio
                    theme={theme}
                    path={path}
                    API_DEFAULT={API_DEFAULT}
                    API_RUT={API_RUT}
                    API_KEY_RUT={API_KEY_RUT}
                    accessToken={[accessToken, setAccessToken]}
                    getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                    getApiKeyRut={getApiKeyRut}
                    idAnticipo={idAnticipo}
                    estudio={estudio}
                    fechaLegalizacion={fechaLegalizacion}
                    rubro={rubro}
                    iva={[iva, setIva]}
                    archivos={[archivos, setArchivos]}
                    cargarArchivo={cargarArchivo}
                    setVerDiferencias={setVerDiferencias}
                    setSnack={setSnack}
                    setSeverity={setSeverity}
                    setMessage={setMessage}
                    setShowBackdrop={setShowBackdrop}
                    setLabelShowBackdrop={setLabelShowBackdrop}
                    limpiar={limpiarGeneral}
                />
            </Collapse>
            <Collapse in={tipo === subtiposTransporte[4].tipo && subtipo === subtiposTransporte[4].subtipo} mountOnEnter unmountOnExit>
                <Alquiler2
                    theme={theme}
                    path={path}
                    API_DEFAULT={API_DEFAULT}
                    API_RUT={API_RUT}
                    API_KEY_RUT={API_KEY_RUT}
                    accessToken={[accessToken, setAccessToken]}
                    getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                    getApiKeyRut={getApiKeyRut}
                    idAnticipo={idAnticipo}
                    estudio={estudio}
                    fechaLegalizacion={fechaLegalizacion}
                    rubro={rubro}
                    iva={[iva, setIva]}
                    archivos={[archivos, setArchivos]}
                    cargarArchivo={cargarArchivo}
                    setVerDiferencias={setVerDiferencias}
                    setSnack={setSnack}
                    setSeverity={setSeverity}
                    setMessage={setMessage}
                    setShowBackdrop={setShowBackdrop}
                    setLabelShowBackdrop={setLabelShowBackdrop}
                    limpiar={limpiarGeneral}
                />
            </Collapse>
            <Collapse in={tipo === subtiposTransporte[5].tipo && subtipo === subtiposTransporte[5].subtipo} mountOnEnter unmountOnExit>
                <Bus2
                    theme={theme}
                    path={path}
                    API_DEFAULT={API_DEFAULT}
                    API_RUT={API_RUT}
                    API_KEY_RUT={API_KEY_RUT}
                    accessToken={[accessToken, setAccessToken]}
                    getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                    getApiKeyRut={getApiKeyRut}
                    idAnticipo={idAnticipo}
                    estudio={estudio}
                    fechaLegalizacion={fechaLegalizacion}
                    rubro={rubro}
                    iva={[iva, setIva]}
                    archivos={[archivos, setArchivos]}
                    cargarArchivo={cargarArchivo}
                    setVerDiferencias={setVerDiferencias}
                    setSnack={setSnack}
                    setSeverity={setSeverity}
                    setMessage={setMessage}
                    setShowBackdrop={setShowBackdrop}
                    setLabelShowBackdrop={setLabelShowBackdrop}
                    limpiar={limpiarGeneral}
                />
            </Collapse>
            <Collapse in={tipo === subtiposTransporte[6].tipo && subtipo === subtiposTransporte[6].subtipo} mountOnEnter unmountOnExit>
                <Lancha
                    theme={theme}
                    path={path}
                    API_DEFAULT={API_DEFAULT}
                    API_RUT={API_RUT}
                    API_KEY_RUT={API_KEY_RUT}
                    accessToken={[accessToken, setAccessToken]}
                    getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                    getApiKeyRut={getApiKeyRut}
                    idAnticipo={idAnticipo}
                    estudio={estudio}
                    fechaLegalizacion={fechaLegalizacion}
                    rubro={rubro}
                    iva={[iva, setIva]}
                    archivos={[archivos, setArchivos]}
                    cargarArchivo={cargarArchivo}
                    setVerDiferencias={setVerDiferencias}
                    setSnack={setSnack}
                    setSeverity={setSeverity}
                    setMessage={setMessage}
                    setShowBackdrop={setShowBackdrop}
                    setLabelShowBackdrop={setLabelShowBackdrop}
                    limpiar={limpiarGeneral}
                />
            </Collapse>
            <Collapse in={tipo === subtiposTransporte[7].tipo && subtipo === subtiposTransporte[7].subtipo} mountOnEnter unmountOnExit>
                <Propio2
                    theme={theme}
                    path={path}
                    API_DEFAULT={API_DEFAULT}
                    API_RUT={API_RUT}
                    API_KEY_RUT={API_KEY_RUT}
                    accessToken={[accessToken, setAccessToken]}
                    getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                    getApiKeyRut={getApiKeyRut}
                    idAnticipo={idAnticipo}
                    estudio={estudio}
                    fechaLegalizacion={fechaLegalizacion}
                    rubro={rubro}
                    iva={[iva, setIva]}
                    archivos={[archivos, setArchivos]}
                    cargarArchivo={cargarArchivo}
                    setVerDiferencias={setVerDiferencias}
                    setSnack={setSnack}
                    setSeverity={setSeverity}
                    setMessage={setMessage}
                    setShowBackdrop={setShowBackdrop}
                    setLabelShowBackdrop={setLabelShowBackdrop}
                    limpiar={limpiarGeneral}
                />
            </Collapse>
            <Collapse in={tipo === subtiposTransporte[8].tipo && subtipo === subtiposTransporte[8].subtipo} mountOnEnter unmountOnExit>
                <Alquiler3
                    theme={theme}
                    path={path}
                    API_DEFAULT={API_DEFAULT}
                    API_RUT={API_RUT}
                    API_KEY_RUT={API_KEY_RUT}
                    accessToken={[accessToken, setAccessToken]}
                    getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                    getApiKeyRut={getApiKeyRut}
                    idAnticipo={idAnticipo}
                    estudio={estudio}
                    fechaLegalizacion={fechaLegalizacion}
                    rubro={rubro}
                    iva={[iva, setIva]}
                    archivos={[archivos, setArchivos]}
                    cargarArchivo={cargarArchivo}
                    setVerDiferencias={setVerDiferencias}
                    setSnack={setSnack}
                    setSeverity={setSeverity}
                    setMessage={setMessage}
                    setShowBackdrop={setShowBackdrop}
                    setLabelShowBackdrop={setLabelShowBackdrop}
                    limpiar={limpiarGeneral}
                />
            </Collapse>
            <Collapse in={tipo === subtiposTransporte[9].tipo && subtipo === subtiposTransporte[9].subtipo} mountOnEnter unmountOnExit>
                <Lancha2
                    theme={theme}
                    path={path}
                    API_DEFAULT={API_DEFAULT}
                    API_RUT={API_RUT}
                    API_KEY_RUT={API_KEY_RUT}
                    accessToken={[accessToken, setAccessToken]}
                    getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                    getApiKeyRut={getApiKeyRut}
                    idAnticipo={idAnticipo}
                    estudio={estudio}
                    fechaLegalizacion={fechaLegalizacion}
                    rubro={rubro}
                    iva={[iva, setIva]}
                    archivos={[archivos, setArchivos]}
                    cargarArchivo={cargarArchivo}
                    setVerDiferencias={setVerDiferencias}
                    setSnack={setSnack}
                    setSeverity={setSeverity}
                    setMessage={setMessage}
                    setShowBackdrop={setShowBackdrop}
                    setLabelShowBackdrop={setLabelShowBackdrop}
                    limpiar={limpiarGeneral}
                />
            </Collapse>
            <Collapse in={tipo === subtiposTransporte[10].tipo && subtipo === subtiposTransporte[10].subtipo} mountOnEnter unmountOnExit>
                <Caballo
                    theme={theme}
                    path={path}
                    API_DEFAULT={API_DEFAULT}
                    API_RUT={API_RUT}
                    API_KEY_RUT={API_KEY_RUT}
                    accessToken={[accessToken, setAccessToken]}
                    getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                    getApiKeyRut={getApiKeyRut}
                    idAnticipo={idAnticipo}
                    estudio={estudio}
                    fechaLegalizacion={fechaLegalizacion}
                    rubro={rubro}
                    iva={[iva, setIva]}
                    archivos={[archivos, setArchivos]}
                    cargarArchivo={cargarArchivo}
                    setVerDiferencias={setVerDiferencias}
                    setSnack={setSnack}
                    setSeverity={setSeverity}
                    setMessage={setMessage}
                    setShowBackdrop={setShowBackdrop}
                    setLabelShowBackdrop={setLabelShowBackdrop}
                    limpiar={limpiarGeneral}
                />
            </Collapse>
            <Collapse in={tipo === subtiposTransporte[11].tipo && subtipo === subtiposTransporte[11].subtipo} mountOnEnter unmountOnExit>
                <Propio3
                    theme={theme}
                    path={path}
                    API_DEFAULT={API_DEFAULT}
                    API_RUT={API_RUT}
                    API_KEY_RUT={API_KEY_RUT}
                    accessToken={[accessToken, setAccessToken]}
                    getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                    getApiKeyRut={getApiKeyRut}
                    idAnticipo={idAnticipo}
                    estudio={estudio}
                    fechaLegalizacion={fechaLegalizacion}
                    rubro={rubro}
                    iva={[iva, setIva]}
                    archivos={[archivos, setArchivos]}
                    cargarArchivo={cargarArchivo}
                    setVerDiferencias={setVerDiferencias}
                    setSnack={setSnack}
                    setSeverity={setSeverity}
                    setMessage={setMessage}
                    setShowBackdrop={setShowBackdrop}
                    setLabelShowBackdrop={setLabelShowBackdrop}
                    limpiar={limpiarGeneral}
                />
            </Collapse>
            <Collapse in={subtipo === null}>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    justify='flex-end'
                    spacing={1}
                    style={{ marginTop: theme.spacing(1.5) }}
                >
                    <Grid item style={{ display: 'flex' }}>
                        {estudio !== null ?
                            <ThemeProvider theme={themeDownloadButton}>
                                <Button style={{ marginRight: theme.spacing(1) }} variant='contained' color='primary' onClick={() => limpiarGeneral()}>Limpiar</Button>
                            </ThemeProvider>
                            :
                            null
                        }
                        <Button component={Link} to={path} variant='contained' color='secondary'>Regresar</Button>
                    </Grid>
                </Grid>
            </Collapse>
        </React.Fragment>
    );
}

export default Transporte;