import React from 'react';
import {
    Dialog,
    DialogTitle,
    Divider,
    DialogContent,
    DialogActions,
    Button,
    Typography
} from '@material-ui/core';



function DialogoSuperaPresupuesto(props) {
    const theme = props.theme;
    const [open, setOpen] = props.open;
    const postAnticipo = props.postAnticipo;
    const mensaje = props.mensaje;


    return (
        <Dialog
            open={open}
            onClose={() => {setOpen(false) }}
            fullWidth={true}
            maxWidth='sm'
        >
            <DialogTitle>Sobrecosto Presupuesto</DialogTitle>
            <Divider />
            <DialogContent>
                <Typography style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }} variant='body1'>
                    El valor de <span style={{ fontWeight: 500 }}>{mensaje}</span> supera el valor presupuestado. ¿Está seguro de crear el anticipo?
                </Typography>
            </DialogContent>
            <Divider />
            <DialogActions style={{ paddingRight: 16, paddingLeft: 16, paddingBottom: 24, paddingTop: 16 }}>
                <Button onClick={() => {postAnticipo(); setOpen(false)}} size='small' color='secondary' variant='contained'>Crear</Button>
                <Button onClick={() => { setOpen(false) }} size='small' color='primary' variant='contained'>Cancelar</Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogoSuperaPresupuesto