import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    Divider,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Grid,
    Switch,
    FormControlLabel
} from '@material-ui/core';

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import EsLocale from 'date-fns/locale/es';
import { saveAs } from 'file-saver';


function DialogoDescargaMovimientos(props) {
    const { API_DEFAULT, setShowBackdrop, getAccessTokenWithRefresh} = props;
    const [accessToken, setAccessToken]= props.accessToken;
    const [open, setOpen] = props.open;
    // eslint-disable-next-line
    const [error, setError] = useState(false);
    const setSnack = props.setSnack;
    const setSeverity = props.setSeverity;
    const setMessage = props.setMessage;
    const [fechaInicial, setFechaInicial] = useState(new Date());
    const [fechaFinal, setFechaFinal] = useState(new Date());
    const [nuevos, setNuevos] = useState(true);

    
    async function fetchExcelMovimientos( access = accessToken) {
        setShowBackdrop(true);
        let f_inicial = new Date(fechaInicial.toISOString().split('T')[0]);
        const res = await fetch(API_DEFAULT + '/legalizaciones/excel_mov_contables/?f_inicio='+f_inicial.toISOString()+'&f_fin='+toISOLocal(fechaFinal)+'&nuevos='+nuevos, { 
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
        });
        if (res.ok) {
            
            const date = new Date().toISOString().split('T')[0]
            const filename = `Movimientos contables ${date}.xlsx`
            const blob = await res.blob();

            saveAs(blob, filename);

            fetchExcelTerceros();
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        fetchExcelMovimientos(newAccess);
                    }
                }
            }); 
        }
        
    }

    async function fetchExcelTerceros( access = accessToken) {
        setShowBackdrop(true);
        let f_inicial = new Date(fechaInicial.toISOString().split('T')[0])
        const res = await fetch(API_DEFAULT + '/legalizaciones/excel_terceros/?f_inicio='+f_inicial.toISOString()+'&f_fin='+toISOLocal(fechaFinal)+'&nuevos='+nuevos, { 
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
        });
        if (res.ok) {
            
            const date = new Date().toISOString().split('T')[0]
            const filename = `plantilla_terceros ${date}.xlsx`
            const blob = await res.blob();

            saveAs(blob, filename);
            setShowBackdrop(false);
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        fetchExcelTerceros(newAccess);
                    }
                }
            }); 
        }
        
    }

    function validar() {
        let errorValidar = false;
        

        if (errorValidar) {
            setError(true);
            setMessage('Existen campos sin diligenciar o con algún error.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
        }
        else {
            fetchExcelMovimientos();
            setOpen(false);
        }
    }

    function toISOLocal(d) {
        var z  = n =>  ('0' + n).slice(-2);
        var zz = n => ('00' + n).slice(-3);
        var off = d.getTimezoneOffset();
        var sign = off < 0? '+' : '-';
        off = Math.abs(off);
      
        return d.getFullYear() + '-'
               + z(d.getMonth()+1) + '-' +
               z(d.getDate()) + 'T' +
               z(d.getHours()) + ':'  + 
               z(d.getMinutes()) + ':' +
               z(d.getSeconds()) + '.' +
               zz(d.getMilliseconds()) +
               sign + z(off/60|0) + ':' + z(off%60); 
    }

    return (
        <Dialog
            open={open}
            onClose={() => {setError(false); setOpen(false) }}
            fullWidth={true}
            maxWidth='sm'
        >
            <DialogTitle>Descarga Plantillas Contables SIIGO</DialogTitle>
            <Divider />
            <DialogContent>
                <React.Fragment>
                    <Grid
                        container
                        direction='row'
                        alignItems='stretch'
                        spacing={2}
                    >
                        <Grid item xs>
                            <Typography variant='body1'>Seleccione la fecha inicial:</Typography>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={EsLocale}>
                                <DatePicker
                                    value={fechaInicial}
                                    onChange={(date) => setFechaInicial(date)}
                                    size='small'
                                    inputVariant='outlined'
                                    format="EEEE, dd 'de' MMMM 'del' yyyy"
                                    fullWidth={true}
                                    disableToolbar={true}
                                    disableFuture={true}
                                    autoOk={true}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs>
                            <Typography variant='body1'>Seleccione la fecha final:</Typography>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={EsLocale}>
                                <DatePicker
                                    value={fechaFinal}
                                    onChange={(date) => setFechaFinal(date)}
                                    size='small'
                                    inputVariant='outlined'
                                    format="EEEE, dd 'de' MMMM 'del' yyyy"
                                    fullWidth={true}
                                    disableToolbar={true}
                                    disableFuture={true}
                                    autoOk={true}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        alignItems='stretch'
                        spacing={2}
                    >
                        <Grid item xs>
                            <FormControlLabel
                                control={<Switch checked={nuevos} onChange={(e)=> setNuevos(e.target.checked)} name="checkedA" />}
                                label="Descargar únicamente nuevos movimientos"
                            />
                        </Grid>
                    </Grid>
                </React.Fragment>
            </DialogContent>
            <Divider />
            <DialogActions style={{ paddingRight: 16, paddingLeft: 16, paddingBottom: 24, paddingTop: 16 }}>
                <Button onClick={() => validar()} size='small' color='primary' variant='contained'>Aceptar</Button>
                <Button onClick={() => {setError(false); setOpen(false) }} size='small' color='secondary' variant='contained'>Cancelar</Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogoDescargaMovimientos;