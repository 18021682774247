import React, { useEffect, useState } from 'react';
import {
    CardHeader,
    CardContent,
    Divider,
    Typography,
    Grid,
    TextField,
    Button,
    IconButton,
    Collapse
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import NumberFormat from 'react-number-format';
import GetAppIcon from '@material-ui/icons/GetApp';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import {
    Link
} from "react-router-dom";
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { saveAs } from 'file-saver';

import departamentos from '../../../data/departamentos';
import municipios from '../../../data/municipios';
import generos from '../../../data/generos';
import tipos_persona from '../../../data/tiposPersona';
import tipos_documento from '../../../data/tiposDocumento';

import DialogoDocumentos from './DialogoDocumento';

function Formato2(props) {
    const { theme, path, API_DEFAULT, setShowBackdrop, getAccessTokenWithRefresh} = props;
    const [accessToken, setAccessToken] = props.accessToken;
    const iva = props.iva;
    const id = props.id;
    const datos = props.datos;
    const documentos = props.documentos;
    const [valor, setValor] = props.valor;
    const [numFactura, setNumFactura] = props.numFactura;
    const titulo = props.titulo;
    const rubro = props.rubro;
    const [openDialog, setOpenDialog] = useState(false);
    const [pathFile, setPathFile] = useState('');
    const [title, setTitle] = useState('');
    const setSnack = props.setSnack;
    const setSeverity = props.setSeverity;
    const setMessage = props.setMessage;
    const totalDatos = props.totalDatos;
    const [errores, setErrores] = useState([]);
    // eslint-disable-next-line
    let controller = new AbortController();
    // eslint-disable-next-line
    let inputValue = React.useState('');
    let timeout;
    // eslint-disable-next-line
    const [datosDian, setDatosDian] = useState(false);

    const [nombres, setNombres] = useState('');
    const [apellidos, setApellidos] = useState('');
    const [razonSocial, setRazonSocial] = useState('');
    const [tipoDoc, setTipoDoc] = useState('');
    const [nit, setNit] = useState('');
    const [dv, setDv] = useState('');
    const [sexo, setSexo] = useState('');
    const [tipoPersona, setTipoPersona] = useState('');
    const [direccion, setDireccion] = useState('');
    const [departamento, setDepartamento] = useState('');
    const [municipio, setMunicipio] = useState('');
    const [telefono, setTelefono] = useState('');
    const [email, setEmail] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [depOrigen, setDepOrigen] = useState('');
    const [muniOrigen, setMuniOrigen] = useState('');
    const [depDestino, setDepDestino] = useState('');
    const [muniDestino, setMuniDestino] = useState('');

    useEffect(() => { window.scrollTo(0, 0) }, [])

    function dataToString(data){
        setNombres(data.nombre);
        setApellidos(data.apellidos);
        setRazonSocial(data.nombre);
        setNit(data.nit);
        setDv(data.d_verificacion);
        setDireccion(data.direccion);
        setTelefono(data.telefono);
        setEmail(data.email);
        setDescripcion(data.descripcion);

        let tipo_documento = tipos_documento.filter(t => t.id === data.tipo_identificacion)[0];
        setTipoDoc(tipo_documento !== undefined? tipo_documento.tipo:'');
        let genero = generos.filter(g => g.id === data.sexo)[0];
        setSexo(genero !== undefined? genero.genero :'');
        let tipo_persona = tipos_persona.filter(t => t.id === data.tipo_persona)[0];
        setTipoPersona(tipo_persona !== undefined ?tipo_persona.tipo:'');
        let dep = departamentos.filter(d => d.dane === data.departamento)[0];
        setDepartamento(dep !== undefined? dep.departamento: '');
        let mun = municipios.filter(m => m.daneMuni === data.municipio)[0];
        setMunicipio(mun !== undefined ? mun.municipio: '');  
        let depOri = departamentos.filter(d => d.dane === data.origen_departamento)[0];
        setDepOrigen(depOri !== undefined? depOri.departamento: '');
        let munOri = municipios.filter(m => m.daneMuni === data.origen_municipio)[0];
        setMuniOrigen(munOri !== undefined ? munOri.municipio: '');
        let depDes = departamentos.filter(d => d.dane === data.destino_departamento)[0];
        setDepDestino(depDes !== undefined? depDes.departamento: '');
        let munDes = municipios.filter(m => m.daneMuni === data.destino_municipio)[0];
        setMuniDestino(munDes !== undefined ? munDes.municipio: '');
    }

    async function putDatos( access = accessToken) {
        var name = ''
        var lastname = ''
        var sex = ''
        var tipoP = ''
        
        if(tipoDoc === 'NIT'){
            name = razonSocial;
            sex = 2;
            tipoP = 2;
        }else{
            name = nombres;
            lastname = apellidos;
            sex = generos.filter(g => g.genero === sexo)[0].id;
            tipoP = tipos_persona.filter(t => t.tipo === tipoPersona)[0].id;
        }

       
        const res = await fetch(API_DEFAULT + '/legalizaciones/datos/intermunicipal/', {
            method: 'P<u>T',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
            body: JSON.stringify({
                'id': datos.id,
                'legalizacion': id,
                'tipo': 'Transporte intermunicipal - Alquiler carro/camión/moto particular',
                'nombre': name,
                'apellidos': lastname,
                'nit': nit,
                'd_verificacion': dv,
                'direccion': direccion,
                'departamento': departamentos.filter(d => d.departamento === departamento)[0].dane,
                'municipio': municipios.filter(m => m.municipio === municipio)[0].daneMuni,
                'telefono': telefono,
                'email': email,
                'descripcion': descripcion,
                'origen_departamento': departamentos.filter(d => d.departamento === depOrigen)[0].dane,
                'origen_municipio': municipios.filter(m => m.municipio === muniOrigen)[0].daneMuni,
                'destino_departamento': departamentos.filter(d => d.departamento === depDestino)[0].dane,
                'destino_municipio': municipios.filter(m => m.municipio === muniDestino)[0].daneMuni,
                'sexo': sex,
                'tipo_persona': tipoP,
                'tipo_identificacion': tipos_documento.filter(t => t.tipo === tipoDoc)[0].id
            })
        })
        
        if (res.ok) {
            let responses = [];
            if (responses.every((val) => val === 200)) {
                setShowBackdrop(false);
                setMessage('Se realizaron los cambios con exito.');
                setSeverity('success');
                setTimeout(() => { setSnack(true) }, 0);
            }
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        putDatos(id, newAccess);
                    }
                }
            });
        }
    }

    useEffect(()=>{
        dataToString(props.datos);
    },[props.datos]);

    function validar() {
        let errorValidar = false;
       
        if (tipoDoc === '' || tipoDoc === null) {
            errorValidar = true;
            errores[1] = true;
        }
    
        if (nit === '') {
            errorValidar = true;
            errores[2] = true;
        }
        
        if((nombres === '' && apellidos === '') && (razonSocial === '')){
            errorValidar = true;
            errores[4] = true;
        }
        if(sexo === '' && tipoDoc !== 'NIT'){
            errorValidar = true;
            errores[5] = true;
        }
        if(tipoPersona === ''){
            errorValidar = true;
            errores[6] = true;
        }
        if (direccion === '') {
            errorValidar = true;
            errores[7] = true;
        }
        if (departamento === null || departamento === '') {
            errorValidar = true;
            errores[8] = true;
        }
        if (municipio === null || municipio === '') {
            errorValidar = true;
            errores[9] = true;
        }
        if (telefono === '') {
            errorValidar = true;
            errores[10] = true;
        }
        var regex = /^([\da-z_.-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/;
        if (regex.test(email) === false && email!== '') {
            errorValidar = true;
            errores[11] = true;
        }
        if (depOrigen === null) {
            errorValidar = true;
            errores[12] = true;
        }
        if (muniOrigen === null) {
            errorValidar = true;
            errores[13] = true;
        }
        if (depDestino === null) {
            errorValidar = true;
            errores[14] = true;
        }
        if (muniDestino === null) {
            errorValidar = true;
            errores[15] = true;
        }
        if (descripcion === '') {
            errorValidar = true;
            errores[16] = true;
        }
        if (valor === '' || valor === '0') {
            errorValidar = true;
            errores[17] = true;
        }
        if (!errorValidar) {
            putDatos();
        }
        else {
            setErrores([...errores]);
            setMessage('Existen campos sin diligenciar o con algún error.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
        }
    }

    return (
        <React.Fragment>
            <Divider style={{ marginTop: theme.spacing(2) }} />
            <CardHeader
                style={{ paddingTop: 8, paddingBottom: 8 }}
                title={rubro !== 3 && rubro !== 4 ?
                    <Typography variant='h6'>{titulo}</Typography>
                    :
                    <React.Fragment>
                        <Typography variant='subtitle2'>{titulo !== undefined ? titulo.split(' - ')[0] : ''}</Typography>
                        <Typography variant='h6'>&nbsp;{titulo !== undefined ? titulo.split(' - ')[1] : ''}</Typography>
                    </React.Fragment>
                }
            />
            <Divider />
            <CardContent>
            <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Typography variant='body1'>Número de Factura o Soporte:</Typography>
                            <TextField
                                value={numFactura}
                                onChange={(e) => {setNumFactura(e.target.value)}}
                                size='small'
                                variant='outlined'
                                fullWidth
                            />
                        </Grid>
                </Grid>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                >
                    <Grid item xs={12} sm={4}>
                        <Typography variant='body1'>Tipo de Documento:</Typography>
                        <Autocomplete
                            value={tipoDoc}
                            onChange={(e, v) => { setTipoDoc(v); setMunicipio(null);  errores[1] = false; setErrores([...errores]) }}
                            options={
                                tipos_documento.map(option => option.tipo)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    error={errores[1]}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={9} sm={5}>
                        <Typography variant='body1'>Número de Documento:</Typography>
                        <NumberFormat
                            value={nit}
                            customInput={TextField}
                            decimalSeparator={false}
                            format={(tipoDoc === 'NIT'?'#########':'##########')}
                            size='small'
                            variant='outlined'
                            fullWidth
                            error={errores[2]}
                            InputProps={{
                                onKeyDown: (e) => {
                                        if (e.key === 'ArrowLeft'){
                                            e.stopPropagation()
                                        }else{
                                            e.persist();
                                            clearTimeout(timeout);
                                            errores[2] = false;
                                            timeout = setTimeout(() => {
                                                inputValue = e.target.value;
                                                //handleChangeNit();
                                                clearTimeout(timeout);
                                            },1500);
                                        }
                                    }
                            }}
                        />
                    </Grid>
                    <Grid item xs={3} sm={3}>
                        <Typography variant='body1'>DV:</Typography>
                        <NumberFormat
                            customInput={TextField}
                            decimalSeparator={false}
                            format='##########'
                            value={dv}
                            onChange={(e) => { if(datosDian===false){setDv(e.target.value)};errores[3] = false; setErrores([...errores]) }}
                            size='small'
                            variant='outlined'
                            fullWidth
                            error={errores[3]}
                        />
                    </Grid>
                </Grid>
                <Collapse in={tipoDoc === "Cédula de ciudadanía" || tipoDoc === "Cédula de extrajería" || tipoDoc === "Pasaporte" } mountOnEnter unmountOnExit>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={2}
                        style={{ marginTop: theme.spacing(1) }}
                    >
                        <Grid item xs={6} md={6}>
                            <Typography variant='body1'>Nombres:</Typography>
                            <TextField
                                value={nombres}
                                onChange={(e) => {if(datosDian===false){setNombres(e.target.value)}; errores[4] = false; setErrores([...errores]) }}
                                size='small'
                                variant='outlined'
                                fullWidth
                                error={errores[4]}
                            />
                        </Grid>
                        <Grid item xs={6} md={5}>
                            <Typography variant='body1'>Apellidos:</Typography>
                            <TextField
                                value={apellidos}
                                onChange={(e) => {if(datosDian===false){setApellidos(e.target.value)}; errores[4] = false; setErrores([...errores]) }}
                                size='small'
                                variant='outlined'
                                fullWidth
                                error={errores[4]}
                            />
                        </Grid>
                        
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={2}
                        style={{ marginTop: theme.spacing(1) }}
                    >
                        <Grid item xs={12} sm={4}>
                        <Typography variant='body1'>Sexo:</Typography>
                        <Autocomplete
                            value={sexo}
                            onChange={(e, v) => { setSexo(v); errores[3] = false; errores[5] = false; setErrores([...errores]) }}
                            options={
                                generos.map(option => option.genero)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    error={errores[5]}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant='body1'>Tipo de Persona:</Typography>
                        <Autocomplete
                            value={tipoPersona}
                            onChange={(e, v) => { setTipoPersona(v); errores[6] = false; setErrores([...errores]) }}
                            options={
                                tipos_persona.map(option => option.tipo)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    error={errores[6]}
                                />
                            )}
                        />
                    </Grid>
                    </Grid>
                </Collapse>
                <Collapse in={tipoDoc === "NIT" } mountOnEnter unmountOnExit>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={2}
                        style={{ marginTop: theme.spacing(1) }}
                    >
                        <Grid item xs={12} md={12}>
                            <Typography variant='body1'>Razón social:</Typography>
                            <TextField
                                value={razonSocial}
                                onChange={(e) => {if(datosDian===false){setRazonSocial(e.target.value)}; errores[4] = false; setErrores([...errores]) }}
                                size='small'
                                variant='outlined'
                                fullWidth
                                error={errores[4]}
                            />
                        </Grid>
                    </Grid>
                </Collapse>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                    style={{ marginTop: theme.spacing(1) }}
                >
                    <Grid item xs={12} md={5}>
                        <Typography variant='body1'>Dirección:</Typography>
                        <TextField
                            value={direccion}
                            onChange={(e) => { setDireccion(e.target.value); errores[7] = false; setErrores([...errores]) }}
                            size='small'
                            variant='outlined'
                            fullWidth
                            error={errores[7]}
                            inputProps={{ maxLength: 30 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm>
                        <Typography variant='body1'>Departamento:</Typography>
                        <Autocomplete
                            value={departamento}
                            onChange={(e, v) => { setDepartamento(v); setMunicipio(null); errores[8] = false; setErrores([...errores]) }}
                            options={
                                departamentos.map(option => option.departamento)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    error={errores[8]}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs>
                        <Typography variant='body1'>Municipio:</Typography>
                        <Autocomplete
                            style={{ backgroundColor: departamento === null ? '#E0E0E0' : null, borderRadius: 4 }}
                            disabled={departamento === null}
                            value={municipio}
                            onChange={(e, v) => { setMunicipio(v); errores[9] = false; setErrores([...errores]) }}
                            options={
                                municipios.filter(m => m.departamento === departamento).map(option => option.municipio)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    error={errores[9]}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                    style={{ marginTop: theme.spacing(1) }}
                >
                    <Grid item xs={12} sm={6}>
                        <Typography variant='body1'>Teléfono o celular:</Typography>
                        <NumberFormat
                            customInput={TextField}
                            decimalSeparator={false}
                            format='##########'
                            value={telefono}
                            onChange={(e) => { setTelefono(e.target.value); errores[10] = false; setErrores([...errores]) }}
                            size='small'
                            variant='outlined'
                            fullWidth
                            error={errores[10]}
                        />
                    </Grid>
                    <Grid item xs>
                        <Typography variant='body1'>Correo electrónico:</Typography>
                        <TextField
                            value={email}
                            onChange={(e) => { setEmail(e.target.value.toLowerCase()); errores[11] = false; setErrores([...errores]) }}
                            size='small'
                            variant='outlined'
                            fullWidth
                            error={errores[11]}
                        />
                    </Grid>
                </Grid>
                <Typography style={{ marginTop: theme.spacing(2), fontWeight: 500, fontSize: 17 }} variant='body1'>Origen</Typography>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                    style={{ paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1) }}
                >
                    <Grid item xs={12} sm={6}>
                        <Typography variant='body1'>Departamento:</Typography>
                        <Autocomplete
                            value={depOrigen}
                            onChange={(e, v) => { setDepOrigen(v); setMuniOrigen(null); errores[12] = false; setErrores([...errores]) }}
                            options={
                                departamentos.map(option => option.departamento)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    error={errores[12]}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs>
                        <Typography variant='body1'>Municipio:</Typography>
                        <Autocomplete
                            style={{ backgroundColor: depOrigen === null ? '#E0E0E0' : null, borderRadius: 4 }}
                            disabled={depOrigen === null}
                            value={muniOrigen}
                            onChange={(e, v) => { setMuniOrigen(v); errores[13] = false; setErrores([...errores]) }}
                            options={
                                municipios.filter(m => m.departamento === depOrigen).map(option => option.municipio)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    error={errores[13]}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Typography style={{ marginTop: theme.spacing(2), fontWeight: 500, fontSize: 17 }} variant='body1'>Destino</Typography>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                    style={{ paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1) }}
                >
                    <Grid item xs={12} sm={6}>
                        <Typography variant='body1'>Departamento:</Typography>
                        <Autocomplete
                            value={depDestino}
                            onChange={(e, v) => { setDepDestino(v); setMuniDestino(null); errores[14] = false; errores[15] = false; setErrores([...errores]) }}
                            options={
                                departamentos.map(option => option.departamento)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    error={errores[14]}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs>
                        <Typography variant='body1'>Municipio:</Typography>
                        <Autocomplete
                            style={{ backgroundColor: depDestino === null ? '#E0E0E0' : null, borderRadius: 4 }}
                            disabled={depDestino === null}
                            value={muniDestino}
                            onChange={(e, v) => { setMuniDestino(v); errores[15] = false; setErrores([...errores]) }}
                            options={
                                municipios.filter(m => m.departamento === depDestino).map(option => option.municipio)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    error={errores[15]}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Typography style={{ marginTop: theme.spacing(2) }} variant='body1'>Descripción del gasto:</Typography>
                <TextField
                    value={descripcion}
                    onChange={(e) => { setDescripcion(e.target.value); errores[16] = false; setErrores([...errores]) }}
                    size='small'
                    variant='outlined'
                    fullWidth
                    multiline
                    rows={1}
                    error={errores[16]}
                    inputProps={{ maxLength: 50 }}
                />
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                    style={{ marginTop: theme.spacing(1) }}
                >
                    <Grid item xs={12} md={4}>
                        <Typography variant='body1'>Valor:</Typography>
                        <CurrencyTextField
                            modifyValueOnWheel={false}
                            size='small'
                            variant='outlined'
                            currencySymbol='$'
                            outputFormat='string'
                            minimumValue='0'
                            textAlign='left'
                            decimalPlaces={0}
                            fullWidth
                            onChange={(e, v) => { setValor(v); errores[17] = false; setErrores([...errores]) }}
                            value={valor}
                            selectOnFocus={false}
                            error={errores[17]}
                        />
                    </Grid>
                    {/*<Grid item xs>
                        <Typography variant='body1'>Seleccione el tipo de documento que va a legalizar:</Typography>
                        <Grid
                            container
                            direction='row'
                            alignItems='center'
                            spacing={1}
                            style={{ marginLeft: theme.spacing(0) }}
                        >
                            <Grid item>
                                <RadioGroup
                                    row
                                    value={iva}
                                    onChange={(e) => { setIva(parseInt(e.target.value)); limpiarArchivos([0, 1]); errores[18] = false; errores[19] = false; setErrores([...errores]) }}
                                >
                                    <FormControlLabel value={0} control={<Radio color='primary' />} label='Responsable de IVA' />
                                    <FormControlLabel value={1} control={<Radio color='primary' />} label='NO responsable de IVA' />
                                </RadioGroup>
                            </Grid>
                            <Grid item>
                                <Button size='small' variant='outlined' style={{ height: 25 }} onClick={() => setVerDiferencias(true)}>
                                    <Typography variant='button'>Ver diferencias</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>*/}
                </Grid>
            </CardContent>
            {iva ?
                <React.Fragment>
                    <Divider />
                    <CardHeader
                        style={{ paddingTop: 8, paddingBottom: 8 }}
                        title={
                            <Typography style={{ fontSize: 18 }} variant='h6'>Responsable de IVA</Typography>
                        }
                    />
                    <Divider />
                    <CardContent style={{ paddingBottom: theme.spacing(2) }}>
                        <Typography variant='body1' style={{ marginRight: theme.spacing(2) }}>
                            <span style={{ marginRight: theme.spacing(1) }}>Factura:</span>
                            <IconButton size='small' onClick={() => { setPathFile(documentos[0].documento); setTitle('Factura'); setOpenDialog(true) }}>
                                <PictureAsPdfIcon style={{ height: 25, width: 'auto' }} />
                            </IconButton>
                            <IconButton onClick={() => saveAs(documentos[0].documento, documentos[0].documento.split('/').slice(-1)[0])} size='small'>
                                <GetAppIcon style={{ height: 25, width: 'auto' }} />
                            </IconButton>
                        </Typography>
                        {documentos[1] !== undefined ?
                            <React.Fragment>
                                <Divider style={{ marginTop: theme.spacing(0.5), marginBottom: theme.spacing(0.5) }} />
                                <Typography variant='body1' style={{ marginRight: theme.spacing(2) }}>
                                    <span style={{ marginRight: theme.spacing(1) }}>Tiquete con el que viajó:</span>
                                    <IconButton size='small' onClick={() => { setPathFile(documentos[0].documento); setTitle('Factura'); setOpenDialog(true) }}>
                                        <PictureAsPdfIcon style={{ height: 25, width: 'auto' }} />
                                    </IconButton>
                                    <IconButton onClick={() => saveAs(documentos[0].documento, documentos[0].documento.split('/').slice(-1)[0])} size='small'>
                                        <GetAppIcon style={{ height: 25, width: 'auto' }} />
                                    </IconButton>
                                </Typography>
                            </React.Fragment>
                            :
                            null
                        }
                        <Divider style={{ marginTop: theme.spacing(0.5) }} />
                    </CardContent>
                </React.Fragment>
                :
                <React.Fragment>
                    <Divider />
                    <CardHeader
                        style={{ paddingTop: 8, paddingBottom: 8 }}
                        title={
                            <Typography style={{ fontSize: 18 }} variant='h6'>NO responsable de IVA</Typography>
                        }
                    />
                    <Divider />
                    <CardContent style={{ paddingBottom: theme.spacing(2) }}>
                        <Typography variant='body1' style={{ marginRight: theme.spacing(2) }}>
                            <span style={{ marginRight: theme.spacing(1) }}>RUT:</span>
                            <IconButton size='small' onClick={() => { setPathFile(documentos[0].documento); setTitle('RUT'); setOpenDialog(true) }}>
                                <PictureAsPdfIcon style={{ height: 25, width: 'auto' }} />
                            </IconButton>
                            <IconButton onClick={() => saveAs(documentos[0].documento, documentos[0].documento.split('/').slice(-1)[0])} size='small'>
                                <GetAppIcon style={{ height: 25, width: 'auto' }} />
                            </IconButton>
                        </Typography>
                        <Divider style={{ marginTop: theme.spacing(0.5), marginBottom: theme.spacing(0.5) }} />
                        <Typography variant='body1' style={{ marginRight: theme.spacing(2) }}>
                            <span style={{ marginRight: theme.spacing(1) }}>Soporte de la compra (recibo):</span>
                            <IconButton size='small' onClick={() => { setPathFile(documentos[1].documento); setTitle('Soporte de la compra (recibo)'); setOpenDialog(true) }}>
                                <PictureAsPdfIcon style={{ height: 25, width: 'auto' }} />
                            </IconButton>
                            <IconButton onClick={() => saveAs(documentos[1].documento, documentos[1].documento.split('/').slice(-1)[0])} size='small'>
                                <GetAppIcon style={{ height: 25, width: 'auto' }} />
                            </IconButton>
                        </Typography>
                        <Divider style={{ marginTop: theme.spacing(0.5) }} />
                    </CardContent>
                </React.Fragment>
            }
            {(totalDatos.observacion !== null)?  
                <React.Fragment>
                    <Divider />
                    <CardHeader
                        style={{ paddingTop: 8, paddingBottom: 8 }}
                        title={
                            <Typography style={{ fontSize: 18 }} variant='h6'>Observación</Typography>
                        }
                    />
                    <CardContent style={{ paddingBottom: theme.spacing(2) }}>
                        <TextField
                            value={totalDatos.observacion}
                            size='small'
                            variant='outlined'
                            fullWidth
                            multiline
                            rows={2}
                            inputProps={{
                                readOnly: true
                            }}
                        />
                    </CardContent>
                    <Divider style={{ marginTop: theme.spacing(0.5) }} />
                </React.Fragment>
                :
                    null
            }
            <Grid
                container
                direction='row'
                alignItems='center'
                justify='flex-end'
                spacing={1}
            >
                <Grid item style={{ display: 'flex' }}>
                    {totalDatos.rechazada === true || totalDatos.aprobado === true ?
                        null
                        :
                        <React.Fragment>
                            <Button style={{ marginRight: theme.spacing(1) }} variant='contained' color='primary' onClick={() => { validar()}}>Guardar</Button>
                        </React.Fragment>
                    }
                    <Button component={Link} to={path} variant='contained' color='secondary'>Regresar</Button>
                </Grid>
            </Grid>
            <DialogoDocumentos
                theme={theme}
                title={title}
                open={[openDialog, setOpenDialog]}
                path={[pathFile, setPathFile]}
            />
        </React.Fragment >
    );
}

export default Formato2;