import React, { useState, useEffect } from 'react';
import Logo from '../logo.png';
import { makeStyles } from '@material-ui/core/styles';
import {
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Container,
    Backdrop,
    CircularProgress,
    Snackbar, Menu, MenuItem, Divider, Badge, Avatar, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Grid
} from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';

import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
// import MailIcon from '@material-ui/icons/Mail';

import { Switch, Route, Redirect, Link } from "react-router-dom";
import { useHistory } from 'react-router-dom';

import './styles/font.css';
import Sesion from './components/Sesion';
import CoordCP from './components/CoordCP/CoordCP';
import CoordAdm from './components/CoordAdm/CoordAdm';
import SupervEnc from './components/SupervEnc/SupervEnc';
import Copyright from './components/Copyright';
import Financiera from './components/Financiera/Financiera';

const API_DEFAULT = 'https://back-sistema-cnc-service-q2nhgfzuoq-uc.a.run.app';
// const API_DEFAULT = 'http://localhost:8000';
const API_RUT = 'https://api-servicios-transversales-service-q2nhgfzuoq-uc.a.run.app/rut/'
// const API_RUT = 'http://127.0.0.1:9000/rut/'


const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
        fontWeight: 500
    },
    logo: {
        width: 110,
        [theme.breakpoints.up('sm')]: {
            width: 135
        }
    },
    logoSpacing: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(4)
        },
        display: 'flex'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    }
}));




function Legalizaciones(props) {
    const classes = useStyles();
    const theme = props.theme;

    const [rol, setRol] = useState(parseInt(localStorage.getItem('rol')));

    const pPaths = ['/inicio-sesion',
        '/coord-costos-presupuestos',
        '/coord-administrativo',
        '/superv-enc',
        '/financiera'];
    const coordCPPaths = ['/coord-costos-presupuestos/registrar-presupuesto',
        '/coord-costos-presupuestos/modificar-presupuesto'];
    const [coordAdmPaths, setCoordAdmPaths] = useState(['/coord-administrativo/registrar-anticipo',
        '/coord-administrativo/revisar-legalizaciones',
        '/coord-administrativo/consultar-reportes',
        '/coord-administrativo/consultar-anticipos']);
    const [supervPaths, setSupervPaths] = useState(['/superv-enc/anticipos-recibidos',
        '/superv-enc/legalizaciones', '/superv-enc/consultar-legalizaciones']);
    const [financPaths, setFinancPaths] = useState(['/financiera/consultar-anticipos',
        '/financiera/consultar-legalizaciones'
    ]);
    const [pathname, setPathname] = useState(window.location.pathname);

    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const [notificationEl, setNotificationAnchorEl] = useState(null);
    const isNotificationOpen = Boolean(notificationEl);

    const [auth, setAuth] = useState(localStorage.getItem('auth'));
    const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refresh'));
    const [accessToken, setAccessToken] = useState('');

    const [username, setUsername] = useState('');
    const [userImage, setUserImage] = useState('');
    const [sessionErrors, setSessionErrors] = useState(Array(2).fill(false));

    const history = useHistory();

    const [showBackdrop, setShowBackdrop] = useState(false);
    const [labelShowBackdrop, setLabelShowBackdrop] = useState('');
    const [snack, setSnack] = useState(false);
    const [severity, setSeverity] = useState('');
    const [message, setMessage] = useState('');

    const [isAdmin, setIsAdmin] = useState(false);
    const [openDialogAdmin, setOpenDialogAdmin] = useState(false);
    const [tempRol, setTempRol] = useState(null);
    const [errorRol, setErrorRol] = useState(false);

    const [validRole, setValidRole] = useState(false);
    const [notificaciones, setNotificaciones] = useState([]);



    const dialogRoleAdmin = (
        <Dialog
            open={isAdmin && openDialogAdmin && !rol}
            // onClose={() => { set }}
            fullWidth={true}
            maxWidth='xs'
        >
            <DialogTitle style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }}>Seleccione el rol que desea revisar</DialogTitle>
            <Divider />
            <DialogContent style={{ paddingBottom: theme.spacing(2) }}>
                <Autocomplete
                    value={tempRol}
                    onChange={(e, v) => { setTempRol(v); setErrorRol(false) }}
                    options={
                        ['Coordinador de costos y presupuestos', 'Coordinador administrativo', 'Supervisor/encuestador municipios lejanos', 'Financiera']
                    }
                    noOptionsText='No hay coincidencias'
                    renderInput={params => (
                        <TextField
                            {...params}
                            size='small'
                            variant='outlined'
                            fullWidth
                            error={errorRol}
                            helperText={errorRol ? 'Debe seleccionar un rol para poder continuar' : null}
                        />
                    )}
                />
            </DialogContent>
            <Divider />
            <DialogActions style={{ padding: theme.spacing(1.5) }}>
                <Button size='small' variant='contained' color='primary'
                    onClick={() => {
                        if (tempRol === null) { setErrorRol(true) }
                        else {
                            let newRol = tempRol === 'Coordinador de costos y presupuestos' ? 1 : (tempRol === 'Coordinador administrativo' ? 2 : (tempRol === 'Supervisor/encuestador municipios lejanos' ? 3 : 4));
                            localStorage.setItem('rol', newRol);
                            setRol(newRol);
                            setOpenDialogAdmin(false);
                            setTempRol(null);
                        }
                    }}
                >
                    Aceptar
                </Button>
            </DialogActions>
        </Dialog>
    );

    const renderMenu = (
        <Menu
            style={{ marginTop: isMobileMenuOpen ? 40 : 32 }}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={() => { setAnchorEl(null); setMobileMoreAnchorEl(null); }}
        >
            <MenuItem style={{ cursor: 'default', pointerEvents: 'none', fontSize: 14, paddingTop: 4, minHeight: 'auto' }}><span style={{ fontSize: 'inherit' }}>Iniciado como <span style={{ fontWeight: 500 }}>{username}</span></span></MenuItem>
            <Divider style={{ marginTop: 2, marginBottom: 8 }} />
            <MenuItem component='a' style={{ fontSize: 14, minHeight: 'auto' }} href='https://perfil.desarrolloscnc.com'>Perfil</MenuItem>
            {isAdmin ?
                <MenuItem style={{ fontSize: 14, minHeight: 'auto' }} onClick={() => { setAnchorEl(null); setMobileMoreAnchorEl(null); setRol(null); setOpenDialogAdmin(true) }}>Cambiar de rol</MenuItem>
                :
                null
            }
            <MenuItem style={{ fontSize: 14, minHeight: 'auto' }} onClick={() => { setAnchorEl(null); setMobileMoreAnchorEl(null); logOut(); }}>Cerrar sesión</MenuItem>
        </Menu>
    );

    const renderMobileMenu = (
        <Menu
            style={{ marginTop: 32 }}
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={() => { setMobileMoreAnchorEl(null) }}
        >
            {/* <MenuItem style={{ minHeight: 'auto' }}>
                <IconButton color='inherit' style={{ padding: 2, marginRight: 12, pointerEvents: 'none' }}>
                    <Badge badgeContent={4} color='secondary' variant='dot'>
                        <MailIcon />
                    </Badge>
                </IconButton>
                <span style={{ fontSize: 14 }}>Mensajes</span>
            </MenuItem> */}
            <MenuItem style={{ minHeight: 'auto' }}>
                <IconButton color='inherit' style={{ padding: 2, marginRight: 12, pointerEvents: 'none' }} >
                    <Badge badgeContent={notificaciones.filter(n => !n.visto).length} color='secondary' variant='dot'>
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <span style={{ fontSize: 14 }}>Notificaciones</span>
            </MenuItem>
            <MenuItem style={{ minHeight: 'auto' }} onClick={(event) => { setAnchorEl(event.currentTarget) }}>
                <IconButton color='inherit' style={{ padding: 2, marginRight: 12, pointerEvents: 'none' }}>
                    <Avatar style={{ height: 24, width: 24 }} src={userImage} />
                </IconButton>
                <span style={{ fontSize: 14 }}>Perfil</span>
            </MenuItem>
        </Menu>
    );

    const ITEM_HEIGHT = 48;

    const renderNotificaciones = (
        <Menu
            style={{ marginTop: 32 }}
            anchorEl={notificationEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isNotificationOpen}
            onClose={() => { setNotificationAnchorEl(null); putNotificaciones(accessToken); }}
            PaperProps={{
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                },
            }}
        >
            {notificaciones.map((row) => (
                <tooltip
                    title={
                        new Date(row.fecha).getDate() + '/'
                        + ((new Date(row.fecha).getMonth() + 1) < 10 ? ('0' + (new Date(row.fecha).getMonth() + 1)) : (new Date(row.fecha).getMonth() + 1)) + '/'
                        + new Date(row.fecha).getFullYear()}
                >
                    <MenuItem style={{ minHeight: 'auto', backgroundColor: (row.visto ? '#fff' : '#FAE5D3') }} onClick={() => { setNotificationAnchorEl(null); putNotificaciones(accessToken); }}>
                        <span style={{ color: (row.visto ? '#777' : '#000') }}>{row.mensaje}</span>
                    </MenuItem>
                </tooltip>


            ))}
        </Menu>
    );

    const validateSession = (username, password) => {
        let errorSesion = false;
        if (username === '') {
            errorSesion = true;
            sessionErrors[0] = true;
        }
        if (password === '') {
            errorSesion = true;
            sessionErrors[1] = true;
        }
        if (errorSesion) {
            setSessionErrors([...sessionErrors]);
            setMessage('Existen campos sin diligenciar o con algún error.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
        }
        else {
            logIn(username, password);
        }
    }

    const logIn = async (username, password) => {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/usuarios/auth/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                'username': username,
                'password': password
            })
        })
        res
            .json()
            .then(d => {
                if (d['access']) {
                    getRole(d['access'], d['refresh']);
                }
                else {
                    setShowBackdrop(false);
                    setMessage('Los datos de usuario y contraseña son incorrectos.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            })

    }

    const getRole = async (access = accessToken, refresh = refreshToken) => {
        setShowBackdrop(true);
        if (access === '') {
            let newAccess = await getAccessTokenWithRefresh();
            if (newAccess) {
                getRole(newAccess, refresh);
            }
        }
        else {
            const res = await fetch(`${API_DEFAULT}/usuarios/dar_rol/`, {
                headers: { 'Authorization': `Bearer ${access}` }
            })

            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        getRole(newAccess, refresh);
                    }
                }
                else if (res['estado'] === 0) {
                    signOff();
                    setShowBackdrop(false);
                    setMessage('El usuario no está activo.');
                    setSeverity('warning');
                    setTimeout(() => { setSnack(true) }, 0);
                }
                else if (res['roles'].includes('Legalizaciones - Coordinador de costos y presupuestos') || res['roles'].includes('Legalizaciones - Coordinador administrativo') || res['roles'].includes('Legalizaciones - Supervisor/encuestador municipios lejanos') || res['roles'].includes('Administrador') || res['roles'].includes('Legalizaciones - Administrador') || res['roles'].includes('Legalizaciones - Financiera')) {
                    const rol1 = res['roles'].includes('Legalizaciones - Coordinador de costos y presupuestos') && !res['roles'].includes('Legalizaciones - Coordinador administrativo') && !res['roles'].includes('Legalizaciones - Supervisor/encuestador municipios lejanos') && !res['roles'].includes('Legalizaciones - Financiera')
                    const rol2 = !res['roles'].includes('Legalizaciones - Coordinador de costos y presupuestos') && res['roles'].includes('Legalizaciones - Coordinador administrativo') && !res['roles'].includes('Legalizaciones - Supervisor/encuestador municipios lejanos') && !res['roles'].includes('Legalizaciones - Financiera')
                    const rol3 = !res['roles'].includes('Legalizaciones - Coordinador de costos y presupuestos') && !res['roles'].includes('Legalizaciones - Coordinador administrativo') && !res['roles'].includes('Legalizaciones - Supervisor/encuestador municipios lejanos') && res['roles'].includes('Legalizaciones - Financiera')

                    localStorage.setItem('refresh', refresh);
                    localStorage.setItem('auth', true);
                    if (res['roles'].includes('Administrador') || res['roles'].includes('Legalizaciones - Administrador')) {
                        chooseRoleAdmin();
                    }
                    else {
                        localStorage.setItem('rol', rol1 ? 1 : (rol2 ? 2 : (rol3 ? 4 : 3)));
                        setRol(rol1 ? 1 : (rol2 ? 2 : (rol3 ? 4 : 3)))
                    }
                    setUsername(res['username']);
                    setUserImage(res['imagen'] === null ? '' : res['imagen']);
                    setAccessToken(access);
                    setRefreshToken(refresh);
                    setAuth(true);
                    setValidRole(true);
                    setShowBackdrop(false);
                    getNotificaciones(access);
                }
                else {
                    signOff();
                    setShowBackdrop(false);
                    setMessage('El usuario no está habilitado para usar esta aplicación.');
                    setSeverity('warning');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            })
        }
    }

    const getAccessTokenWithRefresh = async () => {
        let access = undefined;
        const res = await fetch(`${API_DEFAULT}/usuarios/refresh_token/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                'refresh': refreshToken
            })
        });

        if (!res.ok) {
            signOff();
            setShowBackdrop(false);
            setMessage('La sesión expiró, por favor ingrese nuevamente.');
            setSeverity('info');
            setTimeout(() => { setSnack(true) }, 0);
        }

        await res.json().then(res => {
            if (res['code'] === 'token_not_valid') {
                signOff();
                setShowBackdrop(false);
                setMessage('La sesión expiró, por favor ingrese nuevamente.');
                setSeverity('info');
                setTimeout(() => { setSnack(true) }, 0);
            }
            else {
                access = res['access'];
            }
        });

        return access;
    }

    const logOut = async (access = accessToken) => {
        setShowBackdrop(true);

        const res = await fetch(`${API_DEFAULT}/usuarios/logout/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
            body: JSON.stringify({
                'all': false,
                'refresh': refreshToken
            })
        });

        if (res.ok) {
            setShowBackdrop(false);
            signOff();
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        logOut(newAccess);
                    }
                }
                else {
                    setShowBackdrop(false);
                    setMessage('Ocurrio un error, intente de nuevo.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            });
        }
    }

    const getNotificaciones = async (access = accessToken, refresh = refreshToken) => {
        if (access === '') {
            let newAccess = await getAccessTokenWithRefresh();
            if (newAccess) {
                getNotificaciones(newAccess, refresh);
            }
        }
        else {
            const res = await fetch(`${API_DEFAULT}/legalizaciones/notificaciones/`, {
                headers: { 'Authorization': `Bearer ${access}` }
            })

            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        getNotificaciones(newAccess, refresh);
                    }
                } else {
                    setNotificaciones(res);
                }
            })
        }
    }

    const putNotificaciones = async (access = accessToken, refresh = refreshToken) => {
        if (access === '') {
            let newAccess = await getAccessTokenWithRefresh();
            if (newAccess) {
                getNotificaciones(newAccess, refresh);
            }
        }
        else {
            await notificaciones.filter((e) => { return !e.visto }).forEach(notificacion => {
                // eslint-disable-next-line
                const res = fetch(`${API_DEFAULT}/legalizaciones/notificaciones/`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
                    body: JSON.stringify({
                        'id': notificacion.id,
                        'visto': true
                    })
                })
            });

            getNotificaciones(access);

        }
    }

    const signOff = () => {
        localStorage.removeItem('auth');
        localStorage.removeItem('refresh');
        localStorage.removeItem('rol');
        setRol(null);
        setAuth(false);
        setUsername('');
        setUserImage('')
        setAccessToken('');
        setRefreshToken('');
        setValidRole(false);
        setIsAdmin(false);
        setOpenDialogAdmin(false);
        setTempRol(null);
        setErrorRol(false);
        setNotificaciones([]);
    }
    const chooseRoleAdmin = () => {
        setIsAdmin(true);
        setOpenDialogAdmin(true);
    }


    const getApiKeyRut = async () => {
        var hoy = new Date();
        var dia = hoy.getUTCDate();
        var mes = hoy.getUTCMonth() + 1;
        var año = hoy.getUTCFullYear();
        
        let formatted_date = año + "-" + (mes < 10 ? "0" + mes : mes) + "-" + (dia < 10 ? "0" + dia : dia);
        const msgBuffer = new TextEncoder('utf-8').encode("desarrolloscnc" + formatted_date);
        const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map(b => ('00' + b.toString(16)).slice(-2)).join('');
        return hashHex;
    };

    useEffect(() => {
        if (auth) {
            getRole(accessToken, refreshToken);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        function handleResize() {
            setAnchorEl(null);
            setMobileMoreAnchorEl(null);
            setNotificationAnchorEl(null);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        return history.listen((location) => {
            setPathname(location.pathname)
        })
    }, [history]);

    return (
        <div className='Legalizaciones'>
            <header className='Legalizaciones-header'>
                <AppBar position='static'>
                    <Toolbar>
                        <div className={classes.logoSpacing}>
                            <a style={{ display: 'contents' }} href={'https://desarrolloscnc.com/'}>
                                <img src={Logo} alt='logo' className={classes.logo} />
                            </a>
                        </div>
                        <Typography component={Link} to={pPaths[rol]} style={{ color: 'inherit', textDecoration: 'none' }} variant='h5' className={classes.title}>
                            Legalizaciones
                        </Typography>
                        {auth ?
                            <div>
                                <div className={classes.sectionDesktop}>
                                    {/* <IconButton color='inherit'>
                                        <Badge badgeContent={4} color='secondary' variant='dot'>
                                            <MailIcon />
                                        </Badge>
                                    </IconButton> */}
                                    <IconButton
                                        onClick={(event) => {
                                            setNotificationAnchorEl(event.currentTarget);
                                        }}
                                        color='inherit'>
                                        <Badge badgeContent={notificaciones.filter(n => !n.visto).length} color='secondary'>
                                            <NotificationsIcon />
                                        </Badge>
                                    </IconButton>
                                    <IconButton
                                        onClick={(event) => {
                                            setAnchorEl(event.currentTarget);
                                        }}
                                        color='inherit'
                                    >
                                        <Avatar style={{ height: 24, width: 24 }} src={userImage} />
                                    </IconButton>
                                </div>
                                <div className={classes.sectionMobile}>
                                    <IconButton
                                        onClick={(event) => {
                                            setMobileMoreAnchorEl(event.currentTarget);
                                        }}
                                        color='inherit'
                                    >
                                        <MoreIcon />
                                    </IconButton>
                                </div>
                            </div>
                            :
                            null
                        }
                    </Toolbar>
                </AppBar>
                {renderMobileMenu}
                {renderMenu}
                {renderNotificaciones}
                {dialogRoleAdmin}
            </header>
            <div className='Productividad-body' style={{ marginTop: theme.spacing(2) }}>
                <Redirect
                    to={{
                        pathname: auth ?
                            rol === 1 ?
                                [pPaths[1]].concat(coordCPPaths).includes(pathname) ?
                                    pathname
                                    :
                                    pPaths[1]
                                :
                                rol === 2 ?
                                    [pPaths[2]].concat(coordAdmPaths).includes(pathname) ?
                                        pathname
                                        :
                                        pPaths[2]
                                    :
                                    rol === 3 ?
                                        [pPaths[3]].concat(supervPaths).includes(pathname) ?
                                            pathname
                                            :
                                            pPaths[3]
                                        :
                                        rol === 4 ?
                                            [pPaths[4]].concat(financPaths).includes(pathname) ?
                                                pathname
                                                :
                                                pPaths[4]
                                            :
                                            '/'
                            :
                            pPaths[0],
                        state: { from: props.location }
                    }}
                />
                <Switch>
                    <Route
                        exact
                        path={pPaths[0]}
                        render={() => {
                            if (!auth) {
                                return (
                                    <Container component='main' maxWidth='xs'>
                                        <Container component='main' maxWidth='xs'>
                                            <Sesion theme={theme} errors={[sessionErrors, setSessionErrors]} validateSession={validateSession} />
                                        </Container>
                                    </Container>
                                );
                            }
                        }}
                    />
                    <Route
                        path={pPaths[1]}
                        render={() => {
                            if (auth) {
                                if (rol === 1) {
                                    return (
                                        <Container component='main' maxWidth={pathname === coordCPPaths[0] ? 'sm' : 'md'}>
                                            <CoordCP
                                                theme={theme}
                                                API_DEFAULT={API_DEFAULT}
                                                username={username}
                                                accessToken={[accessToken, setAccessToken]}
                                                getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                                                paths={[pPaths[1]].concat(coordCPPaths)}
                                                setSnack={setSnack}
                                                setSeverity={setSeverity}
                                                setMessage={setMessage}
                                                setShowBackdrop={setShowBackdrop}
                                                validRole={validRole}
                                            />
                                        </Container>
                                    );
                                }
                            }
                            else {
                                return (
                                    <Redirect to={pPaths[0]} />
                                );
                            }
                        }}
                    />
                    <Route
                        path={pPaths[2]}
                        render={() => {
                            if (auth) {
                                if (rol === 2) {
                                    return (
                                        <Container component='main' maxWidth={pathname.includes('legalizacion-') ? 'md' : pathname.includes('revisar-legalizaciones') ? 'xl' : 'lg'}>
                                            <CoordAdm
                                                getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                                                theme={theme}
                                                API_DEFAULT={API_DEFAULT}
                                                username={username}
                                                accessToken={[accessToken, setAccessToken]}
                                                validRole={validRole}
                                                auth={[auth, pPaths[0]]}
                                                paths={[pPaths[2]].concat(coordAdmPaths)}
                                                coordAdmPaths={[coordAdmPaths, setCoordAdmPaths]}
                                                setSnack={setSnack}
                                                setSeverity={setSeverity}
                                                setMessage={setMessage}
                                                setShowBackdrop={setShowBackdrop}
                                            />
                                        </Container>
                                    );
                                }
                            }
                            else {
                                return (
                                    <Redirect to={pPaths[0]} />
                                );
                            }
                        }}
                    />
                    <Route
                        path={pPaths[3]}
                        render={() => {
                            if (auth) {
                                if (rol === 3) {
                                    return (
                                        <Container component='main' maxWidth={pathname === (supervPaths[0]) || pathname === supervPaths[1] || pathname === supervPaths[2] || pathname.includes('legalizacion-') ? 'lg' : 'md'}>
                                            <SupervEnc
                                                validRole={validRole}
                                                getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                                                getApiKeyRut={getApiKeyRut}
                                                theme={theme}
                                                API_DEFAULT={API_DEFAULT}
                                                API_RUT={API_RUT}
                                                API_KEY_RUT={getApiKeyRut()}
                                                username={username}
                                                accessToken={[accessToken, setAccessToken]}
                                                paths={[pPaths[3]].concat(supervPaths)}
                                                supervPaths={[supervPaths, setSupervPaths]}
                                                setSnack={setSnack}
                                                setSeverity={setSeverity}
                                                setMessage={setMessage}
                                                setShowBackdrop={setShowBackdrop}
                                                setLabelShowBackdrop={setLabelShowBackdrop}
                                                history={history}
                                                isAdmin={isAdmin}
                                            />
                                        </Container>
                                    );
                                }
                            }
                            else {
                                return (
                                    <Redirect to={pPaths[0]} />
                                );
                            }
                        }}
                    />
                    <Route
                        path={pPaths[4]}
                        render={() => {
                            if (auth) {
                                if (rol === 4) {
                                    return (
                                        <Container component='main' maxWidth={pathname.includes('consultar-legalizaciones') ? 'xl' : 'lg'}>
                                            <Financiera
                                                validRole={validRole}
                                                getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                                                theme={theme}
                                                API_DEFAULT={API_DEFAULT}
                                                username={username}
                                                accessToken={[accessToken, setAccessToken]}
                                                paths={[pPaths[4]].concat(financPaths)}
                                                financPaths={[financPaths, setFinancPaths]}
                                                setSnack={setSnack}
                                                setSeverity={setSeverity}
                                                setMessage={setMessage}
                                                setShowBackdrop={setShowBackdrop}
                                                history={history}
                                            />
                                        </Container>
                                    );
                                }
                            }
                            else {
                                return (
                                    <Redirect to={pPaths[0]} />
                                );
                            }
                        }}
                    />
                    <Route render={() => (<Redirect to={auth ? pPaths[rol] : pPaths[0]} />)} />
                </Switch>
                <Copyright />
                <Backdrop className={classes.backdrop} open={showBackdrop}>
                    <Grid alignItems='center' spacing={2}>
                        <Grid style={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress color='inherit' />
                        </Grid>
                        <Grid>
                            <Typography variant='h6'>{labelShowBackdrop}</Typography>
                        </Grid>
                    </Grid>
                </Backdrop>
                <Snackbar
                    open={snack}
                    autoHideDuration={6000}
                    onClose={() => setSnack(false)}
                >
                    <Alert onClose={() => setSnack(false)} severity={severity} variant='filled'>
                        {message}
                    </Alert>
                </Snackbar>
            </div>
        </div >
    );
}

export default Legalizaciones;