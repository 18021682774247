import React, { useEffect, useState } from 'react';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import {
    CardHeader,
    CardContent,
    Divider,
    Typography,
    Grid,
    Button,
    IconButton,
    TextField
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import {
    Link
} from "react-router-dom";
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { saveAs } from 'file-saver';

import DialogoDocumentos from './DialogoDocumento';
import DialogoRevision from './DialogoRevision';

let themeDownloadButton = createMuiTheme({
    palette: {
        primary: {
            main: '#0277BD'
        }
    },
    typography: {
        fontFamily: 'Roboto'
    }
});
themeDownloadButton = responsiveFontSizes(themeDownloadButton);

function Formato4(props) {
    const { theme, path } = props;
    const refresh = props.refresh;
    const anticipoId = props.anticipoId;
    const id = props.id;
    const documentos = props.documentos;
    const valor = props.valor;
    const titulo = props.titulo;
    const [openDialog, setOpenDialog] = useState(false);
    const [pathFile, setPathFile] = useState('');
    const [title, setTitle] = useState('');
    const [openDialog2, setOpenDialog2] = useState(false);
    const [aprobado, setAprobado] = useState(false);
    const postRevision = props.postRevision;
    const setSnack = props.setSnack;
    const setSeverity = props.setSeverity;
    const setMessage = props.setMessage;
    const totalDatos = props.totalDatos;

    useEffect(() => { window.scrollTo(0, 0) }, [])

    return (
        <React.Fragment>
            <Divider style={{ marginTop: theme.spacing(2) }} />
            <CardHeader
                style={{ paddingTop: 8, paddingBottom: 8 }}
                title={
                    <Typography variant='h6'>{titulo}</Typography>
                }
            />
            <Divider />
            <CardContent>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <Typography variant='body1'>Valor que devuelve:</Typography>
                        <CurrencyTextField
                            modifyValueOnWheel={false}
                            size='small'
                            variant='outlined'
                            currencySymbol='$'
                            outputFormat='string'
                            minimumValue='0'
                            textAlign='left'
                            decimalPlaces={0}
                            fullWidth
                            value={valor}
                            selectOnFocus={false}
                            readOnly={true}
                        />
                    </Grid>
                </Grid>
                <Typography variant='body1' style={{ marginRight: theme.spacing(2), marginTop: theme.spacing(3) }}>
                    <span style={{ marginRight: theme.spacing(1) }}>Comprobante de consignación:</span>
                    <IconButton size='small' onClick={() => { setPathFile(documentos[0].documento); setTitle('Comprobante de consignación'); setOpenDialog(true) }}>
                        <PictureAsPdfIcon style={{ height: 25, width: 'auto' }} />
                    </IconButton>
                    <IconButton onClick={() => saveAs(documentos[0].documento, documentos[0].documento.split('/').slice(-1)[0])} size='small'>
                        <GetAppIcon style={{ height: 25, width: 'auto' }} />
                    </IconButton>
                </Typography>
                <Divider style={{ marginTop: theme.spacing(0.5) }} />
            </CardContent>
            {totalDatos.observacion !== null?  
                <React.Fragment>
                    <Divider />
                    <CardHeader
                        style={{ paddingTop: 8, paddingBottom: 8 }}
                        title={
                            <Typography style={{ fontSize: 18 }} variant='h6'>Observación</Typography>
                        }
                    />
                    <CardContent style={{ paddingBottom: theme.spacing(2) }}>
                        <TextField
                            value={totalDatos.observacion}
                            size='small'
                            variant='outlined'
                            fullWidth
                            multiline
                            rows={2}
                            inputProps={{
                                readOnly: true
                            }}
                        />
                    </CardContent>
                    <Divider style={{ marginTop: theme.spacing(0.5) }} />
                </React.Fragment>
                :
                    null
            }
            <Grid
                container
                direction='row'
                alignItems='center'
                justify='flex-end'
                spacing={1}
            >
                <Grid item style={{ display: 'flex' }}>
                    {totalDatos.rechazada === true || totalDatos.aprobado === true ?
                        null
                        :
                        <React.Fragment>
                            <Button style={{ marginRight: theme.spacing(1) }} variant='contained' color='primary' onClick={() => { setAprobado(true); setOpenDialog2(true) }}>Aprobar</Button>
                            <ThemeProvider theme={themeDownloadButton}>
                                <Button style={{ marginRight: theme.spacing(1) }} variant='contained' color='primary' onClick={() => { setAprobado(false); setOpenDialog2(true) }}>Rechazar</Button>
                            </ThemeProvider>
                        </React.Fragment>
                    }
                    <Button component={Link} to={path} variant='contained' color='secondary' onClick={() => { refresh(anticipoId) }}>Regresar</Button>
                </Grid>
            </Grid>
            <DialogoDocumentos
                theme={theme}
                title={title}
                open={[openDialog, setOpenDialog]}
                path={[pathFile, setPathFile]}
            />
            <DialogoRevision
                theme={theme}
                open={[openDialog2, setOpenDialog2]}
                id={id}
                setSeverity={setSeverity}
                setMessage={setMessage}
                setSnack={setSnack}
                aprobado={aprobado}
                postRevision={postRevision}
            />
        </React.Fragment>
    );
}

export default Formato4;