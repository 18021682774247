import React, { useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Typography,
    Button
} from '@material-ui/core';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';

function ResumenDineroEnviado(props) {
    const theme = props.theme;
    const [open, setOpen] = props.open;
    const [dineroAlojamiento, setDineroAlojamiento] = props.dineroAlojamiento;
    const [dineroManutencion, setDineroManutencion] = props.dineroManutencion;
    const [dineroUrbano, setDineroUrbano] = props.dineroUrbano;
    const [dineroIntermunicipal, setDineroIntermunicipal] = props.dineroIntermunicipal;
    const [dineroRural, setDineroRural] = props.dineroRural;
    const [dineroOtros, setDineroOtros] = props.dineroOtros;
    const [dineroEnviado, setDineroEnviado] = props.dineroEnviado;
    const [erroresPadre, setErroresPadre] = props.setErrorEnviado;

    function limpiar() {
        setDineroAlojamiento('');
        setDineroManutencion('');
        setDineroUrbano('');
        setDineroIntermunicipal('');
        setDineroRural('');
        setDineroOtros('');
        setDineroEnviado('');
    }

    useEffect(() => {
        let dinero = (dineroAlojamiento !== '' ? parseFloat(dineroAlojamiento) : 0) + (dineroManutencion !== '' ? parseFloat(dineroManutencion) : 0) + (dineroUrbano !== '' ? parseFloat(dineroUrbano) : 0) + (dineroIntermunicipal !== '' ? parseFloat(dineroIntermunicipal) : 0) + (dineroRural !== '' ? parseFloat(dineroRural) : 0) + (dineroOtros !== '' ? parseFloat(dineroOtros) : 0);
        setDineroEnviado(dinero > 0 ? dinero + '' : '')
        erroresPadre[1] = false; setErroresPadre([...erroresPadre]);
    },
        [dineroAlojamiento, dineroManutencion, dineroUrbano, dineroIntermunicipal, dineroRural, dineroOtros]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Dialog
            open={open}
            onClose={() => { setOpen(false) }}
            fullWidth={true}
            maxWidth='xs'
        >
            <DialogTitle>Dinero enviado</DialogTitle>
            <DialogContent>
                <Typography style={{ marginTop: theme.spacing(2) }} variant='body1'>Alojamiento:</Typography>
                <CurrencyTextField
                    value={dineroAlojamiento}
                    onChange={(e, v) => { setDineroAlojamiento(v) }}
                    modifyValueOnWheel={false}
                    size='small'
                    variant='outlined'
                    currencySymbol='$'
                    outputFormat='string'
                    minimumValue='0'
                    textAlign='left'
                    decimalPlaces={0}
                    fullWidth
                    selectOnFocus={false}
                    readOnly={true}
                />
                <Typography style={{ marginTop: theme.spacing(2) }} variant='body1'>Manutención:</Typography>
                <CurrencyTextField
                    value={dineroManutencion}
                    onChange={(e, v) => { setDineroManutencion(v) }}
                    modifyValueOnWheel={false}
                    size='small'
                    variant='outlined'
                    currencySymbol='$'
                    outputFormat='string'
                    minimumValue='0'
                    textAlign='left'
                    decimalPlaces={0}
                    fullWidth
                    selectOnFocus={false}
                    readOnly={true}
                />
                <Typography style={{ marginTop: theme.spacing(2) }} variant='body1'>Transporte interno/urbano:</Typography>
                <CurrencyTextField
                    value={dineroUrbano}
                    onChange={(e, v) => { setDineroUrbano(v) }}
                    modifyValueOnWheel={false}
                    size='small'
                    variant='outlined'
                    currencySymbol='$'
                    outputFormat='string'
                    minimumValue='0'
                    textAlign='left'
                    decimalPlaces={0}
                    fullWidth
                    selectOnFocus={false}
                    readOnly={true}
                />
                <Typography style={{ marginTop: theme.spacing(2) }} variant='body1'>Transporte intermunicipal:</Typography>
                <CurrencyTextField
                    value={dineroIntermunicipal}
                    onChange={(e, v) => { setDineroIntermunicipal(v) }}
                    modifyValueOnWheel={false}
                    size='small'
                    variant='outlined'
                    currencySymbol='$'
                    outputFormat='string'
                    minimumValue='0'
                    textAlign='left'
                    decimalPlaces={0}
                    fullWidth
                    selectOnFocus={false}
                    readOnly={true}
                />
                <Typography style={{ marginTop: theme.spacing(2) }} variant='body1'>Transporte rural:</Typography>
                <CurrencyTextField
                    value={dineroRural}
                    onChange={(e, v) => { setDineroRural(v) }}
                    modifyValueOnWheel={false}
                    size='small'
                    variant='outlined'
                    currencySymbol='$'
                    outputFormat='string'
                    minimumValue='0'
                    textAlign='left'
                    decimalPlaces={0}
                    fullWidth
                    selectOnFocus={false}
                    readOnly={true}
                />
                <Typography style={{ marginTop: theme.spacing(2) }} variant='body1'>Otros:</Typography>
                <CurrencyTextField
                    value={dineroOtros}
                    onChange={(e, v) => { setDineroOtros(v) }}
                    modifyValueOnWheel={false}
                    size='small'
                    variant='outlined'
                    currencySymbol='$'
                    outputFormat='string'
                    minimumValue='0'
                    textAlign='left'
                    decimalPlaces={0}
                    fullWidth
                    selectOnFocus={false}
                    readOnly={true}
                />
                <Typography style={{ marginTop: theme.spacing(2), fontWeight: 500 }} variant='body1'>Dinero enviado:</Typography>
                <CurrencyTextField
                    value={dineroEnviado}
                    modifyValueOnWheel={false}
                    size='small'
                    variant='outlined'
                    currencySymbol='$'
                    outputFormat='string'
                    minimumValue='0'
                    textAlign='left'
                    decimalPlaces={0}
                    fullWidth
                    readOnly={true}
                />
            </DialogContent>
            <DialogActions style={{ marginBottom: theme.spacing(2), marginRight: theme.spacing(1) }}>
                <Button variant='contained' onClick={() => {limpiar(); setOpen(false);}} color='primary'>
                    Aceptar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ResumenDineroEnviado;