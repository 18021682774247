const departamentos = [
    { dane: 91, departamento: "Amazonas" },
    { dane: 5, departamento: "Antioquia" },
    { dane: 81, departamento: "Arauca" },
    { dane: 88, departamento: "Archipiélago de San Andrés, Providencia y Santa Catalina" },
    { dane: 8, departamento: "Atlántico" },
    { dane: 11, departamento: "Bogotá D.C." },
    { dane: 13, departamento: "Bolívar" },
    { dane: 15, departamento: "Boyacá" },
    { dane: 17, departamento: "Caldas" },
    { dane: 18, departamento: "Caquetá" },
    { dane: 85, departamento: "Casanare" },
    { dane: 19, departamento: "Cauca" },
    { dane: 20, departamento: "Cesar" },
    { dane: 27, departamento: "Chocó" },
    { dane: 23, departamento: "Córdoba" },
    { dane: 25, departamento: "Cundinamarca" },
    { dane: 94, departamento: "Guainía" },
    { dane: 95, departamento: "Guaviare" },
    { dane: 41, departamento: "Huila" },
    { dane: 44, departamento: "La Guajira" },
    { dane: 47, departamento: "Magdalena" },
    { dane: 50, departamento: "Meta" },
    { dane: 52, departamento: "Nariño" },
    { dane: 54, departamento: "Norte de Santander" },
    { dane: 86, departamento: "Putumayo" },
    { dane: 63, departamento: "Quindío" },
    { dane: 66, departamento: "Risaralda" },
    { dane: 68, departamento: "Santander" },
    { dane: 70, departamento: "Sucre" },
    { dane: 73, departamento: "Tolima" },
    { dane: 76, departamento: "Valle del Cauca" },
    { dane: 97, departamento: "Vaupés" },
    { dane: 99, departamento: "Vichada" },
];

export default departamentos;