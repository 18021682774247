import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Typography, TextField, Divider, Collapse} from '@material-ui/core';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import capitalize from '../../../styles/capitalize';
import municipios from '../../../data/municipios';
import ResumenDineroEnviado from '../ConsultarAnticipos/ResumenDineroEnviado'

function DialogoDetalles(props) {
    const { theme, getAccessTokenWithRefresh, setShowBackdrop, API_DEFAULT,  validRole, revision} = props;
    const [accessToken, setAccessToken] = props.accessToken;
    const [open, setOpen] = props.open;
   
    const [encuestador, setEncuestador] = useState('');
    const [tipoContrato, setTipoContrato] = useState('');
    const [cargo, setCargo] = useState('');
    const [dineroAlojamiento, setDineroAlojamiento] = useState('');
    const [dineroManutencion, setDineroManutencion] = useState('');
    const [dineroUrbano, setDineroUrbano] = useState('');
    const [dineroIntermunicipal, setDineroIntermunicipal] = useState('');
    const [dineroRural, setDineroRural] = useState('');
    const [dineroOtros, setDineroOtros] = useState('');
    const [dineroEnviado, setDineroEnviado] = useState('');
    const [openDinero, setOpenDinero] = useState(false);

    const [medioEnvio, setMedioEnvio] = useState('');
    const [otroMedioEnvio, setOtroMedioEnvio] = useState('');
    const [cuenta, setCuenta] = useState('');
    const [fechaEnvio, setFechaEnvio] = useState(new Date());
    const [estudio, setEstudio] = useState(null);
    const [coordinador, setCoordinador] = useState(null);
    const [ciudad, setCiudad] = useState(null);
    const [regional, setRegional] = useState(null);
    const [directorCampo, setDirectorCampo] = useState(null);
    const [directorMisional, setDirectorMisional] = useState(null);
    const [anticipo, setAnticipo] = useState([]);
    const [anticipoId, setAnticipoId] = useState([]);
    const [errores, setErrores] = useState([]);
    const [descripcion, setDescripcion] = useState(null);

    const [aprobado, setAprobado] = useState(false);
    const [rechazado, setRechazado]= useState(false);
    const [validadoPor, setValidadoPor] = useState('');
    const [fechaValidacion, setFechaValidacion] = useState(null);
    const [observacion, setObservacion] = useState('');
    const [girado, setGirado]= useState(false);

    const mediosEnvio = [
        { index: 1, nombre: 'Giro empresarial Banco de Bogotá' },
        { index: 2, nombre: 'Daviplata' },
        { index: 3, nombre: 'Efecty' },
        { index: 4, nombre: 'Otro' }
    ]
    const regionales = [
        { index: 0, nombre: 'Bogotá' },
        { index: 1, nombre: 'Bucaramanga' },
        { index: 2, nombre: 'Medellín' },
        { index: 3, nombre: 'Cali' },
        { index: 4, nombre: 'Barranquilla' }
    ]

    

    async function fetchVersiones(access = accessToken){
        setShowBackdrop(true);
        const res = await fetch(API_DEFAULT + '/legalizaciones/revision_anticipos/?revision='+ revision, {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
        })
        if (res.ok) {
            res.json().then(res => {
                setShowBackdrop(false);
                fetchCampos(res);
            });
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        fetchVersiones(newAccess);
                    }
                }
            });
        }
    }

    function fetchCampos(version){
        
        var sd = []
        var anticipo = []
        var encuestador_dict = []
        var coordinador_dict = []
        var director_dict = []
        var director_aprueba_dict = []

        version.forEach(v => {
            sd = JSON.parse(v['serialized_data'])[0];
            if(sd['model'] === 'legalizaciones.anticipo'){
                anticipo = v
            }
        });
        
        setAnticipo(anticipo);

        version.forEach(v => {
            sd = JSON.parse(v['serialized_data'])[0];
            if(sd['model'] !== 'legalizaciones.anticipo'){
                if(v.field_dict.id === anticipo['field_dict'].encuestador_id){
                    encuestador_dict = v;
                }
    
                if(v['field_dict']['cedula'] === anticipo['field_dict'].coordinador_id){
                    coordinador_dict = v;
                }
    
                if(v['field_dict']['cedula'] === anticipo['field_dict'].director_id){
                    director_dict = v;
                }
    
                if(v['field_dict']['cedula'] === anticipo['field_dict'].director_aprueba_id){
                    director_aprueba_dict = v;
                }
            }
            
        });

        setEncuestador(encuestador_dict.object_repr);
        fetchInfoEnc(encuestador_dict['field_dict'].persona_id);
        setMedioEnvio(mediosEnvio.find(m => m.index === anticipo['field_dict'].medio_envio).nombre);
        setDineroAlojamiento(anticipo['field_dict'].valor_alojamiento);
        setDineroManutencion(anticipo['field_dict'].valor_manutencion);
        setDineroUrbano(anticipo['field_dict'].valor_transporte_urbano);
        setDineroIntermunicipal(anticipo['field_dict'].valor_transporte_intermunicipal);
        setDineroRural(anticipo['field_dict'].valor_transporte_rural);
        setDineroOtros(anticipo['field_dict'].valor_otro);
        setDineroEnviado(anticipo['field_dict'].valor);
        setOtroMedioEnvio(anticipo['field_dict'].otro_medio_envio);
        setCuenta(anticipo['field_dict'].cuenta);
        setEstudio(anticipo['field_dict'].estudio_id);
        setCoordinador(coordinador_dict.object_repr);
        setDirectorCampo(director_dict.object_repr);
        setDirectorMisional(director_aprueba_dict.object_repr);
        var date = new Date(anticipo['field_dict'].fecha);
        setFechaEnvio(date.toLocaleString('es-ES'));
        setCiudad(municipios.find(m => m.daneMuni === anticipo['field_dict'].municipio).municipio);
        setRegional(regionales.find(r => r.index === anticipo['field_dict'].regional).nombre);
        setAnticipoId(anticipo['field_dict'].id);
        setDescripcion(anticipo['field_dict'].descripcion);
        setAprobado(anticipo['field_dict'].aprobado);
        setRechazado(anticipo['field_dict'].rechazado);
        setValidadoPor(anticipo['field_dict'].validado_por);
        setFechaValidacion(anticipo['field_dict'].fecha_validacion);
        setObservacion(anticipo['field_dict'].observacion);
        setGirado(anticipo['field_dict'].girado);
    }

    async function fetchInfoEnc(cedula, access = accessToken) {
        const res = await fetch(API_DEFAULT + '/productividad/informacion_personal/cedula=' + cedula + '/', {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
        });
        if (res.ok) {
            res.json().then(res => {
                setCargo(res.cargo);
                setTipoContrato(res.tipo_contrato);
            });
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        fetchInfoEnc(cedula, newAccess);
                    }
                }
            });
        }
    }

    const limpiar = () => {
        setDineroAlojamiento(anticipo.field_dict.valor_alojamiento)
        setDineroManutencion(anticipo.field_dict.valor_manutencion)
        setDineroUrbano(anticipo.field_dict.valor_transporte_urbano)
        setDineroIntermunicipal(anticipo.field_dict.valor_transporte_intermunicipal)
        setDineroRural(anticipo.field_dict.valor_transporte_rural)
        setDineroOtros(anticipo.field_dict.valor_otro)
        setDineroEnviado(anticipo.field_dict.valor)
        setOtroMedioEnvio(anticipo.field_dict.otro_medio_envio)
        setCuenta(anticipo.field_dict.cuenta)
        setEstudio(anticipo.field_dict.estudio)
        setDescripcion(anticipo.field_dict.descripcion);
        setAprobado(anticipo.field_dict.aprobado);
        setRechazado(anticipo.field_dict.rechazado);
        setValidadoPor(anticipo.field_dict.validado_por);
        setFechaValidacion(anticipo.field_dict.fecha_validacion);
        setObservacion(anticipo.field_dict.observacion);
        setGirado(anticipo.field_dict.girado);
        setOpen(false);
        //fetchCampos();
    }
    
    useEffect(() => {
        if(open && validRole){
            fetchVersiones();
        }
    },[open, validRole]);// eslint-disable-line react-hooks/exhaustive-deps


    return (
        <Dialog
            open={open}
            onClose={() => limpiar()}
            fullWidth={true}
            maxWidth='xl'
        >
        <DialogTitle 
            style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }}
            >
                Detalle Historial Anticipo {anticipoId}
            </DialogTitle>
            <Divider />
            <DialogContent style={{ paddingBottom: theme.spacing(2) }}>
            <Typography variant='h6' style={{ marginRight: theme.spacing(1), marginTop: theme.spacing(1) }}>Estado: <span style={{ fontSize: 'inherit', color: (girado ? '#0051FF': (rechazado ? '#0277BD' : (aprobado?'#00695C':'#FF8700')))}}>{girado?'Girado':(rechazado ? 'Rechazado' : (aprobado?'Aprobado':'Pendiente'))}</span></Typography>
                <Typography style={{fontSize: 14, paddingBottom: theme.spacing(1.5)}}>
                    {aprobado ?
                        'Aprobado por ' +validadoPor+' '+(new Date(fechaValidacion).toLocaleString('es-ES'))
                        : 
                        (rechazado?
                            'Rechazado por '+validadoPor+' '+ (new Date(fechaValidacion).toLocaleString('es-ES'))
                            : 
                            null
                        )
                    }
                </Typography>
                <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={2}
                    >
                        <Grid item xs={12} md={5}>
                            <Typography variant='body1'>Supervisor o encuestador:</Typography>
                            <TextField
                                value={encuestador}
                                size='small'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                        <Grid item md={3} sm xs={12}>
                            <Typography variant='body1'>Tipo de contrato:</Typography>
                            <TextField
                                value={capitalize(tipoContrato)}
                                size='small'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                        <Grid item xs>
                            <Typography variant='body1'>Cargo:</Typography>
                            <TextField
                                value={capitalize(cargo)}
                                size='small'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={2}
                        style={{ marginTop: theme.spacing(1) }}
                    >
                    <Grid item md={5} sm={12} xs={12}>
                            <Grid
                                container
                                direction='row'
                                alignItems='center'
                                spacing={1}
                            >
                                <Grid item xs>
                                    <Typography variant='body1'>Dinero enviado:</Typography>
                                    <CurrencyTextField
                                        value={dineroEnviado}
                                        modifyValueOnWheel={false}
                                        size='small'
                                        variant='outlined'
                                        currencySymbol='$'
                                        outputFormat='string'
                                        minimumValue='0'
                                        textAlign='left'
                                        decimalPlaces={0}
                                        fullWidth
                                        selectOnFocus={false}
                                        readOnly={true}
                                        placeholder='Presione el botón INGRESAR'
                                    />
                                </Grid>
                                <Grid item>
                                    <Button onClick={() => setOpenDinero(true)} style={{ marginTop: theme.spacing(3) }} size='small' variant='outlined' color='primary'>Ingresar</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <Typography variant='body1'>Medio de envío:</Typography>
                            <TextField
                                value={medioEnvio}
                                size='small'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                        <Grid item md xs sm>
                            <Collapse in={medioEnvio === "Otro"}>
                                <Typography variant='body1'>¿Cuál?:</Typography>
                                <TextField
                                    value={otroMedioEnvio}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    inputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Collapse>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={2}
                        style={{ marginTop: theme.spacing(1) }}
                    >
                        <Grid item md sm={6} xs={12}>
                            <Typography variant='body1'>No. cuenta:</Typography>
                            <TextField
                                value={cuenta}
                                size='small'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                        <Grid item xs>
                            <Typography variant='body1'>Fecha de envío:</Typography>
                            <TextField
                                value={fechaEnvio}
                                size='small'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={2}
                        style={{ marginTop: theme.spacing(1) }}
                    >
                        <Grid item xs={12} md={3}>
                            <Typography variant='body1'>Estudio:</Typography>
                            <TextField
                                value={estudio}
                                size='small'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                        <Grid item md sm={7} xs={12}>
                            <Typography variant='body1'>Coordinador(a) que solicitó el anticipo:</Typography>
                            <TextField
                                value={coordinador}
                                size='small'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                        <Grid item xs>
                            <Typography variant='body1'>Ciudad:</Typography>
                            <TextField
                                value={ciudad}
                                size='small'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={2}
                        style={{ marginTop: theme.spacing(1) }}
                    >
                        <Grid item xs={12} sm={5} md={3}>
                            <Typography variant='body1'>Regional:</Typography>
                            <TextField
                                value={regional}
                                size='small'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md sm={7}>
                            <Typography variant='body1'>Director(a) de campo que aprobó:</Typography>
                            <TextField
                                value={directorCampo}
                                size='small'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                        <Grid item xs>
                            <Typography variant='body1'>Director(a) de proyecto que aprobó:</Typography>
                            <TextField
                                value={directorMisional}
                                size='small'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={2}
                        style={{ marginTop: theme.spacing(1) }}
                    >
                        <Grid item xs>
                            <Typography variant='body1'>Descripción:</Typography>
                            <TextField
                                style={{ marginBottom: theme.spacing(1) }}
                                value={descripcion}
                                size='small'
                                variant='outlined'
                                fullWidth
                                multiline
                                rows={4}
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                    </Grid>
                    
                    { rechazado?
                        <Grid
                            container
                            direction='row'
                            alignItems='center'
                            spacing={2}
                            style={{ marginTop: theme.spacing(1) }}
                        >
                            <Grid item xs>
                                <Typography variant='body1'>Observación:</Typography>
                                <TextField
                                    value={observacion}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    inputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                            </Grid>:''}
                    
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={2}
                        style={{ marginTop: theme.spacing(1) }}
                    >
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        justify='flex-end'
                        spacing={1}
                        style={{ marginTop: theme.spacing(1.5) }}
                    >
                    </Grid>
                   <ResumenDineroEnviado
                        theme={theme}
                        open={[openDinero, setOpenDinero]}
                        dineroAlojamiento={[dineroAlojamiento, setDineroAlojamiento]}
                        dineroManutencion={[dineroManutencion, setDineroManutencion]}
                        dineroUrbano={[dineroUrbano, setDineroUrbano]}
                        dineroIntermunicipal={[dineroIntermunicipal, setDineroIntermunicipal]}
                        dineroRural={[dineroRural, setDineroRural]}
                        dineroOtros={[dineroOtros, setDineroOtros]}
                        dineroEnviado={[dineroEnviado, setDineroEnviado]}
                        setErrorEnviado={[errores, setErrores]}
                    />
                   
            </DialogContent>
            <Divider />
            <DialogActions style={{ padding: theme.spacing(1.5) }}>
                <Button size='small' variant='contained' color='secondary' onClick={() => {limpiar()}} >
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
        
    );
}

export default DialogoDetalles;