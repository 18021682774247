import React from 'react';
import { Dialog, DialogContent, DialogTitle, Divider, Typography, DialogActions, Button } from '@material-ui/core';

function DialogoEliminar(props) {
    const { theme, anticipo, setShowBackdrop, getAccessTokenWithRefresh, API_DEFAULT, setMessage, setSeverity, setSnack, fetchAnticipos } = props;
    const [accessToken, setAccessToken] = props.accessToken;
    const [open, setOpen] = props.open;

    const eliminarAnticipo = async (access = accessToken) => {
        setShowBackdrop(true);

        const res = await fetch(`${API_DEFAULT}/legalizaciones/anticipos/`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
            body: JSON.stringify({
                "id": anticipo.id
            })
        });

        if (res.ok) {
            fetchAnticipos(access);
            setMessage('Se ha eliminado exitosamente.');
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);
            setOpen(false);
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        eliminarAnticipo(newAccess);
                    }
                }
                else if (res['error'] === 'El anticipo tiene legalizaciones activas, no se puede eliminar.') {
                    setShowBackdrop(false);
                    setMessage('El anticipo tiene legalizaciones activas, no se puede eliminar.');
                    setSeverity('warning');
                    setTimeout(() => { setSnack(true) }, 0);
                }
                else {
                    setShowBackdrop(false);
                    setMessage('Ocurrio un error, intente de nuevo.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            });
        }
    }

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            fullWidth={true}
            maxWidth='sm'
        >
            <DialogTitle style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5), color: '#C62828' }}>Eliminar anticipo</DialogTitle>
            <Divider />
            <DialogContent style={{ paddingBottom: theme.spacing(2) }}>
                <Typography variant='body1'>Está seguro de que desea eliminar el anticipo <span style={{ fontWeight: 500 }}>{anticipo.anticipo}</span></Typography>
            </DialogContent>
            <Divider />
            <DialogActions style={{ padding: theme.spacing(1.5) }}>
                <Button size='small' variant='contained' color='primary' onClick={() => eliminarAnticipo()}>
                    Eliminar
                </Button>
                <Button size='small' variant='contained' color='secondary' onClick={() => setOpen(false)} >
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogoEliminar;