import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import Formato1 from './Formato1';
import Formato2 from './Formato2';
import Formato3 from './Formato3';
import Formato4 from './Formato4';

function BodyFormato(props) {
    const { theme, path, getAccessTokenWithRefresh } = props;
    const iva = props.iva;
    const refresh = props.refresh;
    const anticipoId = props.anticipoId;
    const id = props.id;
    const [accessToken, setAccessToken] = props.accessToken;
    const API_DEFAULT = props.API_DEFAULT;
    const setShowBackdrop = props.setShowBackdrop;
    const [datos, setDatos] = useState([]);
    const [documentos, setDocumentos] = useState([]);
    const [totalDatos, setTotalDatos] = useState([]);
    const [tipo, setTipo] = useState('');
    const valor = props.valor;
    const rubro = props.rubro;
    const numFactura = props.numFactura;
    const [regresar, setRegresar] = useState(false);

    const setSnack = props.setSnack;
    const setSeverity = props.setSeverity;
    const setMessage = props.setMessage;

    const dict = {
        1: ['datos_alojamiento', 'Alojamiento'],
        2: ['datos_manutencion', 'Manutención'],
        5: ['', 'Me sobró dinero']
    }

    async function fetchDatos(access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(API_DEFAULT + `/legalizaciones/legalizacion/id=${id}/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
        })
        if (res.ok) {
            
            res.json().then(res => {
                setDocumentos(res['documentos']);
                let tipoTemp = rubro === 3 ? (res['datos_transporte_urbano'].length > 0 ? ['datos_transporte_urbano', res['datos_transporte_urbano'][0].tipo] : res['datos_transporte_intermunicipal'].length > 0 ? ['datos_transporte_intermunicipal', res['datos_transporte_intermunicipal'][0].tipo] : res['datos_transporte_rural'].length > 0 ? ['datos_transporte_rural', res['datos_transporte_rural'][0].tipo] : '') : rubro === 4 ? ['datos_otros', `Otros - ${res['datos_otros'][0].tipo}`] : dict[rubro];
                setTipo(tipoTemp)
                setDatos(rubro !== 5 ? res[tipoTemp[0]][0] : []);
                setTotalDatos(res);
                setShowBackdrop(false);
            });
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        fetchDatos(newAccess);
                    }
                }
            });
        }
    }

    async function postRevision(idLegal, aprobado, observacion, access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(API_DEFAULT + `/legalizaciones/actualizar/id=${idLegal}/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
            body: JSON.stringify({
                'aprobado_financiera': aprobado,
                'rechazado_financiera': !aprobado,
                'observacion': observacion
            })
        })
        if (res.ok) {
            setMessage(`La legalización fue ${aprobado ? 'aprobada' : 'rechazada'} exitosamente.`);
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);
            setShowBackdrop(false);
            refresh(anticipoId);
            setRegresar(true);
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        postRevision(idLegal, aprobado, observacion, newAccess);
                    }
                }
                else {
                    setMessage('Hubo un problema cargando los datos, intente nuevamente.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                    setShowBackdrop(false);
                }
            });
        }
    }

    useEffect(() => { fetchDatos() }, []); // eslint-disable-line react-hooks/exhaustive-deps
    if (regresar) {
        return (
            <Redirect to={path} />
        );
    }
    if (rubro <= 2 || (rubro === 3 && tipo[0] === 'datos_transporte_urbano') || rubro === 4) {
        return (
            <Formato1
                refresh={refresh}
                theme={theme}
                anticipoId={anticipoId}
                id={id}
                API_DEFAULT={API_DEFAULT}
                accessToken={accessToken}
                setShowBackdrop={setShowBackdrop}
                setSeverity={setSeverity}
                setMessage={setMessage}
                setSnack={setSnack}
                rubro={rubro}
                titulo={tipo[1]}
                valor={valor}
                iva={iva}
                numFactura={numFactura}
                path={path}
                datos={datos}
                totalDatos={totalDatos}
                documentos={documentos}
                postRevision={postRevision}
            />
        );
    }
    else if ((rubro === 3 && tipo[0] === 'datos_transporte_intermunicipal')) {
        return (
            <Formato2
                refresh={refresh}
                theme={theme}
                anticipoId={anticipoId}
                id={id}
                API_DEFAULT={API_DEFAULT}
                accessToken={accessToken}
                setShowBackdrop={setShowBackdrop}
                setSeverity={setSeverity}
                setMessage={setMessage}
                setSnack={setSnack}
                rubro={rubro}
                titulo={tipo[1]}
                valor={valor}
                numFactura={numFactura}
                iva={iva}
                path={path}
                datos={datos}
                totalDatos={totalDatos}
                documentos={documentos}
                postRevision={postRevision}
            />
        );
    }
    else if ((rubro === 3 && tipo[0] === 'datos_transporte_rural')) {
        return (
            <Formato3
                refresh={refresh}
                theme={theme}
                anticipoId={anticipoId}
                id={id}
                API_DEFAULT={API_DEFAULT}
                accessToken={accessToken}
                setShowBackdrop={setShowBackdrop}
                setSeverity={setSeverity}
                setMessage={setMessage}
                setSnack={setSnack}
                rubro={rubro}
                titulo={tipo[1]}
                valor={valor}
                numFactura={numFactura}
                iva={iva}
                path={path}
                datos={datos}
                totalDatos={totalDatos}
                documentos={documentos}
                postRevision={postRevision}
            />
        );
    }
    else if (rubro === 5) {
        return (
            <Formato4
                refresh={refresh}
                theme={theme}
                anticipoId={anticipoId}
                id={id}
                API_DEFAULT={API_DEFAULT}
                accessToken={accessToken}
                setShowBackdrop={setShowBackdrop}
                setSeverity={setSeverity}
                setMessage={setMessage}
                setSnack={setSnack}
                rubro={rubro}
                titulo={tipo[1]}
                valor={valor}
                iva={iva}
                path={path}
                datos={datos}
                totalDatos={totalDatos}
                documentos={documentos}
                postRevision={postRevision}
            />
        );
    }
    else {
        return (
            null
        );
    }
}

export default BodyFormato;