import React, { useEffect } from 'react';
import {
    Typography,
    Button,
    Grid,
    Card,
    CardContent,
    CardHeader,
    Divider,
    TextField,
    Collapse
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import {
    Link
} from "react-router-dom";

function RegistrarPpto(props) {
    const theme = props.theme;
    const paths = props.paths;
    const estudios = props.estudios;
    const [estudio, setEstudio] = props.estudio;
    const [estudioError, setEstudioError] = props.estudioError;

    const [presupuestoAlojamiento, setPresupuestoAlojamiento] = props.presupuestoAlojamiento;
    const [presupuestoManutencion, setPresupuestoManutencion] = props.presupuestoManutencion;
    const [presupuestoUrbano, setPresupuestoUrbano] = props.presupuestoUrbano;
    const [presupuestoIntermunicipal, setPresupuestoIntermunicipal] = props.presupuestoIntermunicipal;
    const [presupuestoRural, setPresupuestoRural] = props.presupuestoRural;
    const [presupuestoOtros, setPresupuestoOtros] = props.presupuestoOtros;

    const [presupuestoLegalizaciones, setPresupuestoLegalizaciones] = props.presupuestoLegalizaciones;
    const [presupuestoError, setPresupuestoError] = props.presupuestoError;
    const validateSend = props.validateSend;
    const fetchEstudiosLibres = props.fetchEstudiosLibres;
    const validRole = props.validRole;
    const limpiar = props.limpiar;

    useEffect(() => { limpiar() }, []); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => { if (validRole) { fetchEstudiosLibres() } }, [validRole]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let presupuesto = (presupuestoAlojamiento !== '' ? parseFloat(presupuestoAlojamiento) : 0) + (presupuestoManutencion !== '' ? parseFloat(presupuestoManutencion) : 0) + (presupuestoUrbano !== '' ? parseFloat(presupuestoUrbano) : 0) + (presupuestoIntermunicipal !== '' ? parseFloat(presupuestoIntermunicipal) : 0) + (presupuestoRural !== '' ? parseFloat(presupuestoRural) : 0) + (presupuestoOtros !== '' ? parseFloat(presupuestoOtros) : 0);
        setPresupuestoLegalizaciones(presupuesto > 0 ? presupuesto + '' : '')
        //erroresPadre[1] = false; setErroresPadre([...erroresPadre]);
    }, [presupuestoAlojamiento, presupuestoManutencion, presupuestoUrbano, presupuestoIntermunicipal, presupuestoRural, presupuestoOtros]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setPresupuestoError(false);
    }, [estudio]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Card style={{ marginBottom: theme.spacing(2) }}>
            <CardHeader
                title={
                    <Typography variant='h6'>Registrar presupuesto</Typography>
                }
            />
            <Divider />
            <CardContent>
                <Typography variant='body1'>Seleccione el estudio:</Typography>
                <Autocomplete
                    value={estudio}
                    onChange={(event, value) => {
                        setEstudio(value);
                        setEstudioError(false);
                    }}
                    options={
                        estudios.map(option => option.nombre_integra)
                    }
                    noOptionsText='No hay coincidencias'
                    renderInput={params => (
                        <TextField
                            {...params}
                            size='small'
                            variant='outlined'
                            fullWidth
                            error={estudioError}
                        />
                    )}
                />
                <Collapse in={estudio}>
                    <Card variant='outlined' style={{ marginTop: theme.spacing(2) }}>
                        <CardHeader
                            style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}
                            title={
                                <Typography variant='h6' style={{ fontSize: 18 }}>Presupuesto legalizaciones</Typography>
                            }
                        />
                        <Divider />
                        <CardContent>
                            <Typography variant='body1'>Alojamiento:</Typography>
                            <CurrencyTextField
                                modifyValueOnWheel={false}
                                size='small'
                                variant='outlined'
                                currencySymbol='$'
                                outputFormat='string'
                                minimumValue='0'
                                textAlign='left'
                                decimalPlaces={0}
                                fullWidth
                                onChange={(e, v) => { setPresupuestoAlojamiento(v); setPresupuestoError(false) }}
                                value={presupuestoAlojamiento}
                                selectOnFocus={false}
                            />
                            <Typography variant='body1' style={{ marginTop: theme.spacing(2) }}>Manutención:</Typography>
                            <CurrencyTextField
                                modifyValueOnWheel={false}
                                size='small'
                                variant='outlined'
                                currencySymbol='$'
                                outputFormat='string'
                                minimumValue='0'
                                textAlign='left'
                                decimalPlaces={0}
                                fullWidth
                                onChange={(e, v) => { setPresupuestoManutencion(v); setPresupuestoError(false) }}

                                value={presupuestoManutencion}
                                selectOnFocus={false}
                            />
                            <Typography variant='body1' style={{ marginTop: theme.spacing(2) }}>Transporte interno/urbano:</Typography>
                            <CurrencyTextField
                                modifyValueOnWheel={false}
                                size='small'
                                variant='outlined'
                                currencySymbol='$'
                                outputFormat='string'
                                minimumValue='0'
                                textAlign='left'
                                decimalPlaces={0}
                                fullWidth
                                onChange={(e, v) => { setPresupuestoUrbano(v); setPresupuestoError(false) }}
                                value={presupuestoUrbano}
                                selectOnFocus={false}
                            />
                            <Typography variant='body1' style={{ marginTop: theme.spacing(2) }}>Transporte intermunicipal:</Typography>
                            <CurrencyTextField
                                modifyValueOnWheel={false}
                                size='small'
                                variant='outlined'
                                currencySymbol='$'
                                outputFormat='string'
                                minimumValue='0'
                                textAlign='left'
                                decimalPlaces={0}
                                fullWidth
                                onChange={(e, v) => { setPresupuestoIntermunicipal(v); setPresupuestoError(false) }}
                                value={presupuestoIntermunicipal}
                                selectOnFocus={false}
                            />
                            <Typography variant='body1' style={{ marginTop: theme.spacing(2) }}>Transporte rural:</Typography>
                            <CurrencyTextField
                                modifyValueOnWheel={false}
                                size='small'
                                variant='outlined'
                                currencySymbol='$'
                                outputFormat='string'
                                minimumValue='0'
                                textAlign='left'
                                decimalPlaces={0}
                                fullWidth
                                onChange={(e, v) => { setPresupuestoRural(v); setPresupuestoError(false) }}
                                value={presupuestoRural}
                                selectOnFocus={false}
                            />
                            <Typography variant='body1' style={{ marginTop: theme.spacing(2) }}>Otros:</Typography>
                            <CurrencyTextField
                                modifyValueOnWheel={false}
                                size='small'
                                variant='outlined'
                                currencySymbol='$'
                                outputFormat='string'
                                minimumValue='0'
                                textAlign='left'
                                decimalPlaces={0}
                                fullWidth
                                onChange={(e, v) => { setPresupuestoOtros(v); setPresupuestoError(false) }}
                                value={presupuestoOtros}
                                selectOnFocus={false}
                            />
                            <Divider style={{ marginTop: theme.spacing(2) }} />
                            <Typography variant='body1' style={{ marginTop: theme.spacing(2), fontWeight: 500 }}>Presupuesto legalizaciones:</Typography>
                            <CurrencyTextField
                                modifyValueOnWheel={false}
                                size='small'
                                variant='outlined'
                                currencySymbol='$'
                                outputFormat='string'
                                minimumValue='0'
                                textAlign='left'
                                decimalPlaces={0}
                                fullWidth
                                value={presupuestoLegalizaciones}
                                error={presupuestoError}
                                selectOnFocus={false}
                                readOnly={true}
                            />
                        </CardContent>
                    </Card>
                </Collapse>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    justify='flex-end'
                    spacing={1}
                    style={{ marginTop: theme.spacing(1.5) }}
                >
                    <Grid item style={{ display: 'flex' }}>
                        <Button style={{ marginRight: theme.spacing(1) }} fullWidth={true} variant='contained' color='primary' disabled={!estudio} onClick={() => validateSend(true)}>Registrar</Button>
                        <Button component={Link} fullWidth={true} to={paths[0]} variant='contained' color='secondary' onClick={() => setEstudio(null)}>Regresar</Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default RegistrarPpto;