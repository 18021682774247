import React, { useEffect, useState } from 'react';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import {
    CardHeader,
    CardContent,
    Divider,
    Typography,
    Grid,
    TextField,
    Radio,
    RadioGroup,
    FormControlLabel,
    Button,
    IconButton
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import {
    Link
} from "react-router-dom";
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { saveAs } from 'file-saver';

import departamentos from '../../../data/departamentos';
import municipios from '../../../data/municipios';

import DialogoDocumentos from './DialogoDocumento';
import DialogoRevision from './DialogoRevision';

let themeDownloadButton = createMuiTheme({
    palette: {
        primary: {
            main: '#0277BD'
        }
    },
    typography: {
        fontFamily: 'Roboto'
    }
});
themeDownloadButton = responsiveFontSizes(themeDownloadButton);

function Formato2(props) {
    const { theme, path, numFactura } = props;
    const iva = props.iva;
    const refresh = props.refresh;
    const anticipoId = props.anticipoId;
    const id = props.id;
    const datos = props.datos;
    const documentos = props.documentos;
    const valor = props.valor;
    const titulo = props.titulo;
    const rubro = props.rubro;
    const [openDialog, setOpenDialog] = useState(false);
    const [pathFile, setPathFile] = useState('');
    const [title, setTitle] = useState('');
    const [openDialog2, setOpenDialog2] = useState(false);
    const [aprobado, setAprobado] = useState(false);
    const postRevision = props.postRevision;
    const setSnack = props.setSnack;
    const setSeverity = props.setSeverity;
    const setMessage = props.setMessage;
    const totalDatos = props.totalDatos;

    useEffect(() => { window.scrollTo(0, 0) }, [])

    return (
        <React.Fragment>
            <Divider style={{ marginTop: theme.spacing(2) }} />
            <CardHeader
                style={{ paddingTop: 8, paddingBottom: 8 }}
                title={rubro !== 3 && rubro !== 4 ?
                    <Typography variant='h6'>{titulo}</Typography>
                    :
                    <React.Fragment>
                        <Typography variant='subtitle2'>{titulo !== undefined ? titulo.split(' - ')[0] : ''}</Typography>
                        <Typography variant='h6'>&nbsp;{titulo !== undefined ? titulo.split(' - ')[1] : ''}</Typography>
                    </React.Fragment>
                }
            />
            <Divider />
            <CardContent>
                {totalDatos.lancha !== 1 ?
                    <RadioGroup
                        row
                        value={0}
                        style={{ marginBottom: theme.spacing(2) }}
                    >
                        <FormControlLabel value={0} control={<Radio color='primary' />} label={totalDatos.lancha === 2 ? 'Legalizado por alquiler de lancha' : 'Legalizado por pago por personas'} />
                    </RadioGroup>
                    :
                    null
                }
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Typography variant='body1'>Número de Factura o Soporte:</Typography>
                            <TextField
                                value={numFactura}
                                size='small'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        
                        </Grid>
                </Grid>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                >
                    <Grid item xs={12} sm={7}>
                        <Typography variant='body1'>Nombre o razón social - Beneficiario:</Typography>
                        <TextField
                            value={datos.nombre +' '+datos.apellidos}
                            size='small'
                            variant='outlined'
                            fullWidth
                            inputProps={{
                                readOnly: true
                            }}
                        />
                    </Grid>
                    <Grid item xs>
                        <Typography variant='body1'>Número del NIT / RUT:</Typography>
                        <TextField
                            value={datos.nit+((datos.d_verificacion !== null)?'-'+datos.d_verificacion:'')}
                            size='small'
                            variant='outlined'
                            fullWidth
                            inputProps={{
                                readOnly: true
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                    style={{ marginTop: theme.spacing(1) }}
                >
                    <Grid item xs={12} md={5}>
                        <Typography variant='body1'>Dirección:</Typography>
                        <TextField
                            value={datos.direccion}
                            size='small'
                            variant='outlined'
                            fullWidth
                            inputProps={{
                                readOnly: true
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm>
                        <Typography variant='body1'>Departamento:</Typography>
                        <TextField
                            value={departamentos.filter(d => d.dane === datos.departamento)[0] !== undefined ? departamentos.filter(d => d.dane === datos.departamento)[0].departamento : ''}
                            size='small'
                            variant='outlined'
                            fullWidth
                            inputProps={{
                                readOnly: true
                            }}
                        />
                    </Grid>
                    <Grid item xs>
                        <Typography variant='body1'>Municipio:</Typography>
                        <TextField
                            value={municipios.filter(m => m.daneMuni === datos.municipio)[0] !== undefined ? municipios.filter(m => m.daneMuni === datos.municipio)[0].municipio : ''}
                            size='small'
                            variant='outlined'
                            fullWidth
                            inputProps={{
                                readOnly: true
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                    style={{ marginTop: theme.spacing(1) }}
                >
                    <Grid item xs={12} sm={6}>
                        <Typography variant='body1'>Teléfono o celular:</Typography>
                        <TextField
                            value={datos.telefono}
                            size='small'
                            variant='outlined'
                            fullWidth
                            inputProps={{
                                readOnly: true
                            }}
                        />
                    </Grid>
                    <Grid item xs>
                        <Typography variant='body1'>Correo electrónico:</Typography>
                        <TextField
                            value={datos.email}
                            size='small'
                            variant='outlined'
                            fullWidth
                            inputProps={{
                                readOnly: true
                            }}
                        />
                    </Grid>
                </Grid>
                <Typography style={{ marginTop: theme.spacing(2), fontWeight: 500, fontSize: 17 }} variant='body1'>Origen</Typography>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                    style={{ paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1) }}
                >
                    <Grid item xs={12} sm={6}>
                        <Typography variant='body1'>Departamento:</Typography>
                        <TextField
                            value={departamentos.filter(d => d.dane === datos.origen_departamento)[0] !== undefined ? departamentos.filter(d => d.dane === datos.origen_departamento)[0].departamento : ''}
                            size='small'
                            variant='outlined'
                            fullWidth
                            inputProps={{
                                readOnly: true
                            }}
                        />
                    </Grid>
                    <Grid item xs>
                        <Typography variant='body1'>Municipio:</Typography>
                        <TextField
                            value={municipios.filter(m => m.daneMuni === datos.origen_municipio)[0] !== undefined ? municipios.filter(m => m.daneMuni === datos.origen_municipio)[0].municipio : ''} size='small'
                            variant='outlined'
                            fullWidth
                            inputProps={{
                                readOnly: true
                            }}
                        />
                    </Grid>
                </Grid>
                <Typography style={{ marginTop: theme.spacing(2), fontWeight: 500, fontSize: 17 }} variant='body1'>Destino</Typography>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                    style={{ paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1) }}
                >
                    <Grid item xs={12} sm={6}>
                        <Typography variant='body1'>Departamento:</Typography>
                        <TextField
                            value={departamentos.filter(d => d.dane === datos.destino_departamento)[0] !== undefined ? departamentos.filter(d => d.dane === datos.destino_departamento)[0].departamento : ''}
                            size='small'
                            variant='outlined'
                            fullWidth
                            inputProps={{
                                readOnly: true
                            }}
                        />
                    </Grid>
                    <Grid item xs>
                        <Typography variant='body1'>Municipio:</Typography>
                        <TextField
                            value={municipios.filter(m => m.daneMuni === datos.destino_municipio)[0] !== undefined ? municipios.filter(m => m.daneMuni === datos.destino_municipio)[0].municipio : ''}
                            size='small'
                            variant='outlined'
                            fullWidth
                            inputProps={{
                                readOnly: true
                            }}
                        />
                    </Grid>
                </Grid>
                <Typography style={{ marginTop: theme.spacing(2) }} variant='body1'>Descripción del gasto:</Typography>
                <TextField
                    value={datos.descripcion}
                    size='small'
                    variant='outlined'
                    fullWidth
                    multiline
                    rows={4}
                    inputProps={{
                        readOnly: true
                    }}
                />
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                    style={{ marginTop: theme.spacing(1) }}
                >
                    <Grid item xs={12} md={4}>
                        <Typography variant='body1'>Valor:</Typography>
                        <CurrencyTextField
                            modifyValueOnWheel={false}
                            size='small'
                            variant='outlined'
                            currencySymbol='$'
                            outputFormat='string'
                            minimumValue='0'
                            textAlign='left'
                            decimalPlaces={0}
                            fullWidth
                            value={valor}
                            selectOnFocus={false}
                            readOnly={true}
                        />
                    </Grid>
                    <Grid item xs>
                        <Typography variant='body1'>Tipo de documento legalizado:</Typography>
                        <Grid
                            container
                            direction='row'
                            alignItems='center'
                            spacing={1}
                            style={{ marginLeft: theme.spacing(0) }}
                        >
                            <Grid item>
                                <RadioGroup
                                    row
                                    value={0}
                                >
                                    <FormControlLabel value={0} control={<Radio color='primary' />} label={iva ? 'Responsable de IVA' : 'NO responsable de IVA'} />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
            {iva ?
                <React.Fragment>
                    <Divider />
                    <CardHeader
                        style={{ paddingTop: 8, paddingBottom: 8 }}
                        title={
                            <Typography style={{ fontSize: 18 }} variant='h6'>Responsable de IVA</Typography>
                        }
                    />
                    <Divider />
                    <CardContent style={{ paddingBottom: theme.spacing(2) }}>
                        <Typography variant='body1' style={{ marginRight: theme.spacing(2) }}>
                            <span style={{ marginRight: theme.spacing(1) }}>Factura:</span>
                            <IconButton size='small' onClick={() => { setPathFile(documentos[0].documento); setTitle('Factura'); setOpenDialog(true) }}>
                                <PictureAsPdfIcon style={{ height: 25, width: 'auto' }} />
                            </IconButton>
                            <IconButton onClick={() => saveAs(documentos[0].documento, documentos[0].documento.split('/').slice(-1)[0])} size='small'>
                                <GetAppIcon style={{ height: 25, width: 'auto' }} />
                            </IconButton>
                        </Typography>
                        {documentos[1] !== undefined ?
                            <React.Fragment>
                                <Divider style={{ marginTop: theme.spacing(0.5), marginBottom: theme.spacing(0.5) }} />
                                <Typography variant='body1' style={{ marginRight: theme.spacing(2) }}>
                                    <span style={{ marginRight: theme.spacing(1) }}>Tiquete con el que viajó:</span>
                                    <IconButton size='small' onClick={() => { setPathFile(documentos[0].documento); setTitle('Factura'); setOpenDialog(true) }}>
                                        <PictureAsPdfIcon style={{ height: 25, width: 'auto' }} />
                                    </IconButton>
                                    <IconButton onClick={() => saveAs(documentos[0].documento, documentos[0].documento.split('/').slice(-1)[0])} size='small'>
                                        <GetAppIcon style={{ height: 25, width: 'auto' }} />
                                    </IconButton>
                                </Typography>
                            </React.Fragment>
                            :
                            null
                        }
                        <Divider style={{ marginTop: theme.spacing(0.5) }} />
                    </CardContent>
                </React.Fragment>
                :
                <React.Fragment>
                    <Divider />
                    <CardHeader
                        style={{ paddingTop: 8, paddingBottom: 8 }}
                        title={
                            <Typography style={{ fontSize: 18 }} variant='h6'>NO responsable de IVA</Typography>
                        }
                    />
                    <Divider />
                    <CardContent style={{ paddingBottom: theme.spacing(2) }}>
                        <Typography variant='body1' style={{ marginRight: theme.spacing(2) }}>
                            <span style={{ marginRight: theme.spacing(1) }}>RUT:</span>
                            <IconButton size='small' onClick={() => { setPathFile(documentos[0].documento); setTitle('RUT'); setOpenDialog(true) }}>
                                <PictureAsPdfIcon style={{ height: 25, width: 'auto' }} />
                            </IconButton>
                            <IconButton onClick={() => saveAs(documentos[0].documento, documentos[0].documento.split('/').slice(-1)[0])} size='small'>
                                <GetAppIcon style={{ height: 25, width: 'auto' }} />
                            </IconButton>
                        </Typography>
                        <Divider style={{ marginTop: theme.spacing(0.5), marginBottom: theme.spacing(0.5) }} />
                        <Typography variant='body1' style={{ marginRight: theme.spacing(2) }}>
                            <span style={{ marginRight: theme.spacing(1) }}>Soporte de la compra (recibo):</span>
                            <IconButton size='small' onClick={() => { setPathFile(documentos[1].documento); setTitle('Soporte de la compra (recibo)'); setOpenDialog(true) }}>
                                <PictureAsPdfIcon style={{ height: 25, width: 'auto' }} />
                            </IconButton>
                            <IconButton onClick={() => saveAs(documentos[1].documento, documentos[1].documento.split('/').slice(-1)[0])} size='small'>
                                <GetAppIcon style={{ height: 25, width: 'auto' }} />
                            </IconButton>
                        </Typography>
                        <Divider style={{ marginTop: theme.spacing(0.5) }} />
                        {console.log("alguna vaina")}
                        
                    </CardContent>
                </React.Fragment>
            }
            {(totalDatos.observacion !== null)?  
                <React.Fragment>
                    <Divider />
                    <CardHeader
                        style={{ paddingTop: 8, paddingBottom: 8 }}
                        title={
                            <Typography style={{ fontSize: 18 }} variant='h6'>Observación</Typography>
                        }
                    />
                    <CardContent style={{ paddingBottom: theme.spacing(2) }}>
                        <TextField
                            value={totalDatos.observacion}
                            size='small'
                            variant='outlined'
                            fullWidth
                            multiline
                            rows={2}
                            inputProps={{
                                readOnly: true
                            }}
                        />
                    </CardContent>
                    <Divider style={{ marginTop: theme.spacing(0.5) }} />
                </React.Fragment>
                :
                    null
            }
            <Grid
                container
                direction='row'
                alignItems='center'
                justify='flex-end'
                spacing={1}
            >
                <Grid item style={{ display: 'flex' }}>
                    {totalDatos.aprobado_financiera === true?
                        <React.Fragment>
                            <ThemeProvider theme={themeDownloadButton}>
                                <Button style={{ marginRight: theme.spacing(1) }} variant='contained' color='primary' onClick={() => { setAprobado(false); setOpenDialog2(true) }}>Rechazar</Button>
                            </ThemeProvider>
                        </React.Fragment>
                    :
                        totalDatos.rechazado_financiera === true?
                            <React.Fragment>
                                <Button style={{ marginRight: theme.spacing(1) }} variant='contained' color='primary' onClick={() => { setAprobado(true); setOpenDialog2(true) }}>Aprobar</Button>
                            </React.Fragment>
                        :
                        <React.Fragment>
                            <Button style={{ marginRight: theme.spacing(1) }} variant='contained' color='primary' onClick={() => { setAprobado(true); setOpenDialog2(true) }}>Aprobar</Button>
                            <ThemeProvider theme={themeDownloadButton}>
                                <Button style={{ marginRight: theme.spacing(1) }} variant='contained' color='primary' onClick={() => { setAprobado(false); setOpenDialog2(true) }}>Rechazar</Button>
                            </ThemeProvider>
                        </React.Fragment>
                    }
                    <Button component={Link} to={path} variant='contained' color='secondary' onClick={() => { refresh(anticipoId) }}>Regresar</Button>
                </Grid>
            </Grid>
            <DialogoDocumentos
                theme={theme}
                title={title}
                open={[openDialog, setOpenDialog]}
                path={[pathFile, setPathFile]}
            />
            <DialogoRevision
                theme={theme}
                open={[openDialog2, setOpenDialog2]}
                id={id}
                setSeverity={setSeverity}
                setMessage={setMessage}
                setSnack={setSnack}
                aprobado={aprobado}
                postRevision={postRevision}
            />
        </React.Fragment >
    );
}

export default Formato2;