import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Typography, TextField, Divider, Collapse, IconButton} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import GetAppIcon from '@material-ui/icons/GetApp';
import { saveAs } from 'file-saver';
import NumberFormat from 'react-number-format';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import capitalize from '../../../styles/capitalize';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import EsLocale from 'date-fns/locale/es';

import municipios from '../../../data/municipios';

import DineroEnviado from '../../CoordAdm/DineroEnviado';
import conceptos from '../../../data/conceptos';

function DialogoEditar(props) {
    const { theme, anticipoId, setMessage, setSeverity, getAccessTokenWithRefresh, setSnack, setShowBackdrop, API_DEFAULT, fetchAnticipos,  validRole } = props;
    const [accessToken, setAccessToken] = props.accessToken;
    const [open, setOpen] = props.open;
    const [openDinero, setOpenDinero] = useState(false);
    const [encuestadores, setEncuestadores] = useState([]);
    const [estudios, setEstudios] = useState([]);
    const [coordinadores, setCoordinadores] = useState([]);
    const [directoresCampo, setDirectoresCampo] = useState([]);
    const [directoresMisional, setDirectoresMisional] = useState([]);

    const [anticipo, setAnticipo] = useState(null);
    const [fechaEnvio, setFechaEnvio] = useState(new Date());
    const [encuestador, setEncuestador] = useState('');
    const [tipoContrato, setTipoContrato]   = useState('');
    const [cargo, setCargo]   = useState('');
    const [dineroAlojamiento, setDineroAlojamiento] = useState('');
    const [dineroManutencion, setDineroManutencion] = useState('');
    const [dineroUrbano, setDineroUrbano] = useState('');
    const [dineroIntermunicipal, setDineroIntermunicipal] = useState('');
    const [dineroRural, setDineroRural] = useState('');
    const [dineroOtros, setDineroOtros] = useState('');
    const [dineroEnviado, setDineroEnviado] = useState('');
    const [medioEnvio, setMedioEnvio] = useState([]);
    const [otroMedioEnvio, setOtroMedioEnvio] = useState('');
    const [cuenta, setCuenta] = useState('');
    const [estudio, setEstudio] = useState('');
    const [coordinador, setCoordinador] = useState('');
    const [ciudad, setCiudad] = useState(null);
    const [regional, setRegional] = useState(null);
    const [directorCampo, setDirectorCampo] = useState('');
    const [directorAprueba, setDirectorAprueba] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [soporte, setSoporte] = useState(null);
    const [detallesAlojamiento, setDetallesAlojamiento] = useState([]);
    const [detallesManutencion, setDetallesManutencion] = useState([]);
    const [detallesUrbano, setDetallesUrbano] = useState([]);
    const [detallesIntermunicipal, setDetallesIntermunicipal] = useState([]);
    const [detallesRural, setDetallesRural] = useState([]);
    const [detallesOtros, setDetallesOtros] = useState([]);

    const [errores, setErrores] = useState(Array(11).fill(false))

    const mediosEnvio = [
        { index: 1, nombre: 'Giro empresarial Banco de Bogotá' },
        { index: 2, nombre: 'Daviplata' },
        { index: 3, nombre: 'Efecty' },
        { index: 4, nombre: 'Otro' }
    ]
    const regionales = [
        { index: 0, nombre: 'Bogotá D.C.' },
        { index: 1, nombre: 'Bucaramanga' },
        { index: 2, nombre: 'Medellín' },
        { index: 3, nombre: 'Cali' },
        { index: 4, nombre: 'Barranquilla' }
    ]

    const limpiar = () => {
        setFechaEnvio(new Date());
        setEncuestador("");
        setTipoContrato("");
        setCargo("");
        setMedioEnvio("");
        setOtroMedioEnvio("");
        setCuenta("");
        setEstudio("");
        setCoordinador("");
        setCiudad("");
        setRegional("");
        setDirectorCampo("");
        setDirectorAprueba("");
        setDescripcion("");
        setSoporte("");
        setDetallesAlojamiento([]);
        setDetallesManutencion([]);
        setDetallesUrbano([]);
        setDetallesIntermunicipal([]);
        setDetallesRural([]);
        setDetallesOtros([]);
        setOpen(false);
    }

    async function fetchAnticipo(access = accessToken, id=anticipoId) {
        setShowBackdrop(true);
        const res = await fetch(API_DEFAULT + `/legalizaciones/anticipo/id=${id}/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
        })
        if (res.ok) {
            res.json().then(res => {

                setFechaEnvio(new Date(res.fecha).toISOString());
                setAnticipo(res);
                setEncuestador('' + res.encuestador.cedula + ': ' + res.encuestador.nombre);
                setTipoContrato(res.encuestador.tipo_contrato);
                setCargo(res.encuestador.cargo);
                setMedioEnvio(res.medio_envio === 'Cuenta Bancaria'?'Giro empresarial Banco de Bogotá':res.medio_envio);
                setOtroMedioEnvio(res.otro_medio_envio);
                setCuenta(res.cuenta);
                setEstudio(res.estudio);
                setCoordinador('' + res.coordinador.cedula + ': ' + res.coordinador.nombre);
                setCiudad(res.municipio);
                setRegional(res.regional);
                setDirectorCampo('' + res.director.cedula + ': ' + res.director.nombre);
                setDirectorAprueba('' + res.director_aprueba.cedula + ': ' + res.director_aprueba.nombre);
                setDescripcion(res.descripcion);
                setCamposDinero(res);
                setShowBackdrop(false);
            });
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        fetchAnticipo(newAccess, id);
                    }
                }
            });
        }
    }

    function setCamposDinero(res){
        setDineroAlojamiento(res.valor_alojamiento);
        setDineroManutencion(res.valor_manutencion);
        setDineroUrbano(res.valor_transporte_urbano);
        setDineroIntermunicipal(res.valor_transporte_intermunicipal);
        setDineroRural(res.valor_transporte_rural);
        setDineroOtros(res.valor_otro);
        setDineroEnviado(res.valor);
        setSoporte(res.soporte.length > 0?res.soporte[0].soporte: null);
        setDetallesAlojamiento([]);
        setDetallesManutencion([]);
        setDetallesUrbano([]);
        setDetallesIntermunicipal([]);
        setDetallesRural([]);
        setDetallesOtros([]);
        
        res.detalle.map((row) => {
            let conceptosFiltrado = conceptos.filter(x => x.idRubro === row.rubro);
            row['rubro'] = conceptosFiltrado.length > 0 ? conceptosFiltrado[0].rubro: row['rubro'];
            row['total'] = row.personas * row.unidades * row.valorUnitario;
            if(row.rubro === 'alojamiento'){
                setDetallesAlojamiento(oldArray => [...oldArray, row]);
            }else if(row.rubro === 'manutencion'){
                setDetallesManutencion(oldArray => [...oldArray, row]);
            }else if(row.rubro === 'urbano'){
                setDetallesUrbano(oldArray => [...oldArray, row]);
            }else if(row.rubro === 'intermunicipal'){
                setDetallesIntermunicipal(oldArray => [...oldArray, row]);
            }else if(row.rubro === 'rural'){
                setDetallesRural(oldArray => [...oldArray, row]);
            }else if(row.rubro === 'otros'){
                setDetallesOtros(oldArray => [...oldArray, row]);
            }
            return row;
        });
    }

    async function fetchEncuestadores(access = accessToken) {
        const res = await fetch(API_DEFAULT + '/legalizaciones/encuestadores/', {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access }
        });
        if (res.ok) {
            res.json().then(res => setEncuestadores(res));
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        fetchEncuestadores(newAccess);
                    }
                }
            });
        }
    }

    async function fetchEstudios(access = accessToken) {
        const res = await fetch(API_DEFAULT + '/legalizaciones/estudios_disponibles/', {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access }
        });
        if (res.ok) {
            res.json().then(res => { ; setEstudios(res.filter(r => r.nombre_integra !== 'Vacaciones' && r.nombre_integra !== 'Descuentos' && r.nombre_integra !== 'Calamidades')) });
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        fetchEstudios(newAccess);
                    }
                }
            });
        }
    }

    async function fetchInfoEnc(cedula, access = accessToken) {
        const res = await fetch(API_DEFAULT + '/productividad/informacion_personal/cedula=' + cedula + '/', {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
        });
        if (res.ok) {
            res.json().then(res => {
                setCargo(res.cargo);
                setTipoContrato(res.tipo_contrato);
            });
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        fetchInfoEnc(cedula, newAccess);
                    }
                }
            });
        }
    }

    async function fetchCoordinadores(access = accessToken) {
        const res = await fetch(API_DEFAULT + '/personal/coordinadores/', {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
        })
        if (res.ok) {
            res.json().then(res => { setCoordinadores(res) });
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        fetchCoordinadores(newAccess);
                    }
                }
            });
        }
    }

    async function fetchDirectoresCampo(access = accessToken) {
        const res = await fetch(API_DEFAULT + '/personal/directores/campo/', {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
        })
        if (res.ok) {
            res.json().then(res => { setDirectoresCampo(res) });
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        fetchDirectoresCampo(newAccess);
                    }
                }
            });
        }
    }

    async function fetchDirectoresMisional(access = accessToken) {
        const res = await fetch(API_DEFAULT + '/personal/directores/misional/', {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
        })
        if (res.ok) {
            res.json().then(res => { setDirectoresMisional(res) });
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        fetchDirectoresMisional(newAccess);
                    }
                }
            });
        }
    }

    const putInformacion = async (access = accessToken) => {
        setShowBackdrop(true);
        
        const res = await fetch(`${API_DEFAULT}/legalizaciones/anticipos/`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
            body: JSON.stringify({
                'id': anticipo.id,
                'estudio': estudio,
                'encuestador': parseInt(encuestador.split(': ')[0]),
                'coordinador': parseInt(coordinador.split(': ')[0]),
                'director': parseInt(directorCampo.split(': ')[0]),
                'director_aprueba': parseInt(directorAprueba.split(': ')[0]),
                'valor_alojamiento': dineroAlojamiento === '' ? 0 : parseInt(dineroAlojamiento),
                'valor_manutencion': dineroManutencion === '' ? 0 : parseInt(dineroManutencion),
                'valor_transporte_urbano': dineroUrbano === '' ? 0 : parseInt(dineroUrbano),
                'valor_transporte_intermunicipal': dineroIntermunicipal === '' ? 0 : parseInt(dineroIntermunicipal),
                'valor_transporte_rural': dineroRural === '' ? 0 : parseInt(dineroRural),
                'valor_otro': dineroOtros === '' ? 0 : parseInt(dineroOtros),
                'valor': dineroEnviado === '' ? 0 : parseInt(dineroEnviado),
                'medio_envio': mediosEnvio.filter(m => m.nombre === medioEnvio)[0].index,
                'otro_medio_envio': otroMedioEnvio,
                'cuenta': cuenta,
                'fecha': new Date(fechaEnvio).toISOString(),
                'regional': regionales.filter(r => r.nombre === regional)[0].index,
                'municipio': municipios.filter(m => m.municipio === ciudad)[0].daneMuni,
                'descripcion': descripcion
            })
        });

        if (res.ok) {
            let detallesResult = putDetalles(access);
            let responses = await detallesResult;
            if (responses.every((val) => val === 200)) {
                fetchAnticipos(access);
                setMessage('Se ha actualizado exitosamente.');
                setSeverity('success');
                setTimeout(() => { setSnack(true) }, 0);
                setOpen(false);
                limpiar();
            }else{
                setShowBackdrop(false);
                setMessage('Ocurrió un error. Intente nuevamente.');
                setSeverity('error');
                setTimeout(() => { setSnack(true) }, 0);
            }
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        putInformacion(newAccess);
                    }
                }
                else {
                    setShowBackdrop(false);
                    setMessage('Ocurrio un error, intente de nuevo.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            });
        }
    }

    const putDetalles = async (access = accessToken) => {
        let detalles = detallesToList();
        let res;
        let responses = [];

        for(var i = 0; i < detalles.length; i++ ){
            if(detalles[i].id){
                res = await fetch(API_DEFAULT + '/legalizaciones/detalle_anticipo/', {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
                    body: JSON.stringify(detalles[i])
                })
            }else{
                detalles[i].anticipo = anticipo.id;
                res = await fetch(API_DEFAULT + '/legalizaciones/detalle_anticipo/', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
                    body: JSON.stringify(detalles[i])
                })
            }
            
            
            if(res.ok){
                responses.push(res.status);
            }else{
                // eslint-disable-next-line
                res.json().then(async res => {
                    if (res['code'] === 'token_not_valid') {
                        let newAccess = await getAccessTokenWithRefresh();
                        if (newAccess) {
                            setAccessToken(newAccess);
                            i = i - 1;
                        }
                    }else {
                        responses.push(res.status);
                    }
                });
            }
        }

        return responses;
    }

    function detallesToList(){
        let detalles = [].concat(
            detallesAlojamiento, 
            detallesManutencion, 
            detallesIntermunicipal, 
            detallesRural, 
            detallesUrbano, 
            detallesOtros);
        
        detalles = detalles.filter((x) => x.total > 0);

        detalles.map((x)=>{
            return x['rubro'] = conceptos.filter(c => c.concepto === x.concepto)[0].idRubro;
        });

        return detalles;
    }

    function validar() {
        let errorValidar = false;
        if (encuestador === null) {
            errorValidar = true;
            errores[0] = true;
        }
        if (dineroEnviado === '' || dineroEnviado === '0') {
            errorValidar = true;
            errores[1] = true;
        }
        if (medioEnvio === null) {
            errorValidar = true;
            errores[2] = true;
        }
        if (medioEnvio === 'Otro' && otroMedioEnvio === '') {
            errorValidar = true;
            errores[10] = true;
        }
        if (cuenta === '') {
            errorValidar = true;
            errores[3] = true;
        }
        if (estudio === null) {
            errorValidar = true;
            errores[4] = true;
        }
        if (coordinador === null) {
            errorValidar = true;
            errores[5] = true;
        }
        if (ciudad === null) {
            errorValidar = true;
            errores[6] = true;
        }
        if (regional === null) {
            errorValidar = true;
            errores[7] = true;
        }
        if (directorCampo === null) {
            errorValidar = true;
            errores[8] = true;
        }
        if (directorAprueba === null) {
            errorValidar = true;
            errores[9] = true;
        }
        if (!errorValidar) {
            putInformacion();
        }
        else {
            setErrores([...errores]);
            setMessage('Existen campos sin diligenciar o con algún error.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
        }
    }

    useEffect(()=> {
        if (validRole && open) {
            fetchAnticipo();
            fetchEncuestadores();
            fetchEstudios();
            fetchCoordinadores();
            fetchDirectoresCampo();
            fetchDirectoresMisional();
        }
    },[validRole, open]);// eslint-disable-line react-hooks/exhaustive-deps
  

    return (
        <Dialog
            open={open}
            onClose={() => limpiar()}
            fullWidth={true}
            maxWidth='xl'
        >
            <DialogTitle style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }}>Está editando el anticipo {anticipoId}</DialogTitle>
            <Divider />
            <DialogContent style={{ paddingBottom: theme.spacing(2) }}>
            <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={2}
                    >
                        <Grid item xs={12} md={5}>
                            <Typography variant='body1'>Seleccione el supervisor o encuestador:</Typography>
                            <Autocomplete
                                value={encuestador}
                                onChange={(e, v) => {
                                    setEncuestador(v); errores[0] = false; setErrores([...errores]);
                                    if (v === null) {
                                        setCargo('');
                                        setTipoContrato('');
                                    }
                                    else {
                                        fetchInfoEnc(parseInt(v.split(': ')[0]))
                                    }
                                }}
                                options={
                                    encuestadores.map(option => '' + option.cedula + ': ' + option.nombre)
                                }
                                noOptionsText='No hay coincidencias'
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        error={errores[0]}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item md={3} sm xs={12}>
                            <Typography variant='body1'>Tipo de contrato:</Typography>
                            <TextField
                                value={tipoContrato}
                                size='small'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                        <Grid item xs>
                            <Typography variant='body1'>Cargo:</Typography>
                            <TextField
                                value={cargo}
                                size='small'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={2}
                        style={{ marginTop: theme.spacing(1) }}
                    >
                        <Grid item md={5} sm={12} xs={12}>
                            <Grid
                                container
                                direction='row'
                                alignItems='center'
                                spacing={1}
                            >
                                <Grid item xs>
                                    <Typography variant='body1'>Dinero enviado:</Typography>
                                    <CurrencyTextField
                                        value={dineroEnviado}
                                        modifyValueOnWheel={false}
                                        size='small'
                                        variant='outlined'
                                        currencySymbol='$'
                                        outputFormat='string'
                                        minimumValue='0'
                                        textAlign='left'
                                        decimalPlaces={0}
                                        fullWidth
                                        error={errores[1]}
                                        selectOnFocus={false}
                                        readOnly={true}
                                        placeholder='Presione el botón INGRESAR'
                                    />
                                </Grid>
                                <Grid item>
                                    <Button onClick={() => setOpenDinero(true)} style={{ marginTop: theme.spacing(3) }} size='small' variant='outlined' color='primary'>Ingresar</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <Typography variant='body1'>Medio de envío:</Typography>
                            <Autocomplete
                                value={medioEnvio}
                                onChange={(e, v) => { setMedioEnvio(v); errores[2] = false; setErrores([...errores]) }}
                                options={
                                    mediosEnvio.map(option => option.nombre)
                                }
                                noOptionsText='No hay coincidencias'
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        error={errores[2]}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item md xs sm>
                            <Collapse in={medioEnvio === "Otro"}>
                                <Typography variant='body1'>¿Cuál?:</Typography>
                                <TextField
                                    value={otroMedioEnvio}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    onChange={(e, v) => { setOtroMedioEnvio(e.target.value); errores[10] = false; setErrores([...errores]) }}
                                    error={errores[10]}
                                />
                            </Collapse>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={2}
                        style={{ marginTop: theme.spacing(1) }}
                    >
                        <Grid item md sm={6} xs={12}>
                            <Typography variant='body1'>No. cuenta:</Typography>
                            <NumberFormat
                                customInput={TextField}
                                decimalSeparator={false}
                                allowNegative={false}
                                value={cuenta}
                                onChange={(e) => { setCuenta(e.target.value); errores[3] = false; setErrores([...errores]) }}
                                size='small'
                                variant='outlined'
                                fullWidth
                                error={errores[3]}
                            />
                        </Grid>
                        <Grid item xs>
                            <Typography variant='body1'>Fecha de envío:</Typography>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={EsLocale}>
                                <DatePicker
                                    value={fechaEnvio}
                                    onChange={(date) => setFechaEnvio(date)}
                                    size='small'
                                    inputVariant='outlined'
                                    format="EEEE, dd 'de' MMM 'del' yyyy"
                                    fullWidth={true}
                                    disableToolbar={true}
                                    minDate={(new Date()).setDate((new Date()).getDate() - 30)}
                                    disableFuture={true}
                                    autoOk={true}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={2}
                        style={{ marginTop: theme.spacing(1) }}
                    >
                        <Grid item xs={12} md={3}>
                            <Typography variant='body1'>Seleccione el estudio:</Typography>
                            <Autocomplete
                                value={estudio}
                                onChange={(e, v) => { setEstudio(v); errores[4] = false; setErrores([...errores]) }}
                                onInputChange={(e, v) => { setEstudio(v); errores[4] = false; setErrores([...errores]) }}
                                options={
                                    estudios.map(option => option.nombre_integra)
                                }
                                noOptionsText='No hay coincidencias'
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        error={errores[4]}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item md sm={7} xs={12}>
                            <Typography variant='body1'>Coordinador(a) que solicitó el anticipo:</Typography>
                            <Autocomplete
                                value={coordinador}
                                onChange={(e, v) => { setCoordinador(v); errores[5] = false; setErrores([...errores]) }}
                                options={
                                    coordinadores.map(option => '' + option.cedula + ': ' + capitalize(option.p_nombre) + (capitalize(option.s_nombre) !== '' ? ' ' + capitalize(option.s_nombre) : '') + ' ' + capitalize(option.p_apellido) + (capitalize(option.s_apellido) !== '' ? ' ' + capitalize(option.s_apellido) : ''))
                                }
                                noOptionsText='No hay coincidencias'
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        error={errores[5]}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs>
                            <Typography variant='body1'>Ciudad:</Typography>
                            <Autocomplete
                                value={ciudad}
                                onChange={(e, v) => { setCiudad(v); errores[6] = false; setErrores([...errores]) }}
                                options={
                                    municipios.map(option => option.municipio)
                                }
                                noOptionsText='No hay coincidencias'
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        error={errores[6]}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={2}
                        style={{ marginTop: theme.spacing(1) }}
                    >
                        <Grid item xs={12} sm={5} md={3}>
                            <Typography variant='body1'>Regional:</Typography>
                            <Autocomplete
                                value={regional}
                                onChange={(e, v) => { setRegional(v); errores[7] = false; setErrores([...errores]) }}
                                options={
                                    regionales.map(option => option.nombre)
                                }
                                noOptionsText='No hay coincidencias'
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        error={errores[7]}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md sm={7}>
                            <Typography variant='body1'>Director(a) de campo que aprobó:</Typography>
                            <Autocomplete
                                value={directorCampo}
                                onChange={(e, v) => { setDirectorCampo(v); errores[8] = false; setErrores([...errores]) }}
                                options={
                                    directoresCampo.map(option => '' + option.cedula + ': ' + capitalize(option.p_nombre) + (capitalize(option.s_nombre) !== '' ? ' ' + capitalize(option.s_nombre) : '') + ' ' + capitalize(option.p_apellido) + (capitalize(option.s_apellido) !== '' ? ' ' + capitalize(option.s_apellido) : ''))
                                }
                                noOptionsText='No hay coincidencias'
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        error={errores[8]}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs>
                            <Typography variant='body1'>Director(a) de proyecto que aprobó:</Typography>
                            <Autocomplete
                                value={directorAprueba}
                                onChange={(e, v) => { setDirectorAprueba(v); errores[9] = false; setErrores([...errores]) }}
                                options={
                                    directoresMisional.map(option => '' + option.cedula + ': ' + capitalize(option.p_nombre) + (capitalize(option.s_nombre) !== '' ? ' ' + capitalize(option.s_nombre) : '') + ' ' + capitalize(option.p_apellido) + (capitalize(option.s_apellido) !== '' ? ' ' + capitalize(option.s_apellido) : ''))
                                }
                                noOptionsText='No hay coincidencias'
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        error={errores[9]}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={2}
                        style={{ marginTop: theme.spacing(1) }}
                    >
                        <Grid item xs={12} md={soporte !== null?11:12} sm={soporte !== null?11:12}>
                            <Typography variant='body1'>Descripción:</Typography>
                            <TextField
                                style={{ marginBottom: theme.spacing(1) }}
                                value={descripcion}
                                onChange={(e) => { setDescripcion(e.target.value);}}
                                size='small'
                                variant='outlined'
                                fullWidth
                                multiline
                                rows={2}
                                
                            />
                        </Grid>
                        {
                            soporte !== null?
                                <Grid item xs={12} md={1} sm={1}>
                                    <Typography variant='body1'>Soporte:</Typography>
                                    <IconButton onClick={() => saveAs(soporte, soporte.split('/').slice(-1)[0])} size='small'>
                                        <GetAppIcon style={{ height: 25, width: 'auto'}} color='primary' />
                                        <Typography variant='body1'>Descargar</Typography>
                                    </IconButton>
                                </Grid>
                            :
                            null
                        }
                        
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        justify='flex-end'
                        spacing={1}
                        style={{ marginTop: theme.spacing(1.5) }}
                    >
                    </Grid>
                    <DineroEnviado
                        theme={theme}
                        open={[openDinero, setOpenDinero]}
                        dineroAlojamiento={[dineroAlojamiento, setDineroAlojamiento]}
                        dineroManutencion={[dineroManutencion, setDineroManutencion]}
                        dineroUrbano={[dineroUrbano, setDineroUrbano]}
                        dineroIntermunicipal={[dineroIntermunicipal, setDineroIntermunicipal]}
                        dineroRural={[dineroRural, setDineroRural]}
                        dineroOtros={[dineroOtros, setDineroOtros]}
                        dineroEnviado={[dineroEnviado, setDineroEnviado]}
                        rowsAlojamiento={[detallesAlojamiento, setDetallesAlojamiento]}
                        rowsManutencion={[detallesManutencion, setDetallesManutencion]}
                        rowsUrbano={[detallesUrbano, setDetallesUrbano]}
                        rowsIntermunicipal={[detallesIntermunicipal, setDetallesIntermunicipal]}
                        rowsRural={[detallesRural, setDetallesRural]}
                        rowsOtros={[detallesOtros, setDetallesOtros]}
                        setErrorEnviado={[errores, setErrores]}
                        fetchCampos={fetchAnticipo}
                    />
            </DialogContent>
            <Divider />
            <DialogActions style={{ padding: theme.spacing(1.5) }}>
                <Button size='small' variant='contained' color='primary' onClick={() => validar()}>
                    Aceptar
                </Button>
                <Button size='small' variant='contained' color='secondary' onClick={() => {limpiar()}} >
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
        
    );
}

export default DialogoEditar;