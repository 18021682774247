import React, { useState } from 'react';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import {
    Dialog,
    DialogTitle,
    Divider,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    TextField
} from '@material-ui/core';

let themeDownloadButton = createMuiTheme({
    palette: {
        primary: {
            main: '#0277BD'
        }
    },
    typography: {
        fontFamily: 'Roboto'
    }
});
themeDownloadButton = responsiveFontSizes(themeDownloadButton);

function DialogoRevision(props) {
    const theme = props.theme;
    const id = props.id;
    const [open, setOpen] = props.open;
    const aprobado = props.aprobado;
    const [observacion, setObservacion] = useState('');
    const postRevision = props.postRevision;
    const [error, setError] = useState(false);
    const setSnack = props.setSnack;
    const setSeverity = props.setSeverity;
    const setMessage = props.setMessage;

    function validar() {
        let errorValidar = false;
        if (!aprobado) {
            if (observacion === '') {
                errorValidar = true;
            }
        }

        if (errorValidar) {
            setError(true);
            setMessage('Existen campos sin diligenciar o con algún error.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
        }
        else {
            postRevision(id, aprobado, observacion);
            setObservacion('');
            setOpen(false);
        }
    }

    return (
        <Dialog
            open={open}
            onClose={() => { setObservacion(''); setError(false); setOpen(false) }}
            fullWidth={true}
            maxWidth='sm'
        >
            <DialogTitle>{aprobado ? 'Aprobar' : 'Rechazar'}</DialogTitle>
            <Divider />
            <DialogContent>
                    <Typography style={{ marginTop: theme.spacing(1) }} variant='body1'>¿Está seguro(a) de que quiere <span style={{ fontWeight: 500 }}>{aprobado ? 'aprobar' : 'rechazar'}</span> la legalización?</Typography>
                    <Typography style={{ marginTop: theme.spacing(2) }} variant='body1'>Observacion:</Typography>
                    <TextField
                        style={{ marginBottom: theme.spacing(1) }}
                        value={observacion}
                        onChange={(e) => { setObservacion(e.target.value); setError(false)}}
                        size='small'
                        variant='outlined'
                        fullWidth
                        multiline
                        rows={4}
                        error={error}
                    />
            </DialogContent>
            <Divider />
            <DialogActions style={{ paddingRight: 16, paddingLeft: 16, paddingBottom: 24, paddingTop: 16 }}>
                {aprobado ?
                    <Button onClick={() => validar()} size='small' color='primary' variant='contained'>Aprobar</Button>
                    :
                    <ThemeProvider theme={themeDownloadButton}>
                        <Button onClick={() => validar()} variant='contained' color='primary'>Rechazar</Button>
                    </ThemeProvider>
                }
                <Button onClick={() => { setObservacion(''); setError(false); setOpen(false) }} size='small' color='secondary' variant='contained'>Cancelar</Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogoRevision