import React, { useEffect } from 'react';
import {
    Typography,
    Grid,
    TextField
} from '@material-ui/core';
import { format } from 'date-fns';
import EsLocale from 'date-fns/locale/es';

function General(props) {
    const { validRole, getAccessTokenWithRefresh } = props;
    const estudio = props.estudio;
    const fecha = props.fecha;
    const id = props.id;
    const [accessToken, setAccessToken] = props.accessToken;
    const API_DEFAULT = props.API_DEFAULT;

    async function fetchObservar(access = accessToken) {
        const res = await fetch(API_DEFAULT + `/legalizaciones/observar/id=${id}/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access }
        });
        if (res.ok) {
            res.json().then(res => { return });
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        fetchObservar(newAccess);
                    }
                }
            });
        }
    }

    useEffect(() => { if (validRole) { fetchObservar() } }, [validRole]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <Grid
                container
                direction='row'
                alignItems='center'
                spacing={2}
            >
                <Grid item xs={12} md={7}>
                    <Typography variant='body1'>Estudio:</Typography>
                    <TextField
                        value={estudio}
                        size='small'
                        variant='outlined'
                        fullWidth
                        inputProps={{
                            readOnly: true
                        }}
                    />
                </Grid>
                <Grid item xs>
                    <Typography variant='body1'>Fecha de legalización:</Typography>
                    <TextField
                        value={format(new Date(fecha), "EEEE, dd 'de' MMMM 'del' yyyy", { locale: EsLocale })}
                        size='small'
                        variant='outlined'
                        fullWidth
                        inputProps={{
                            readOnly: true
                        }}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default General;