import React, { useEffect, useState } from 'react';
import {
    Typography,
    Button,
    Grid,
    Card,
    CardContent,
    CardHeader,
    Divider,
    TextField
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
    Link
} from "react-router-dom";

import municipios from '../../data/municipios';

function ConstultarRepo(props) {
    const { theme, validRole, getAccessTokenWithRefresh } = props;
    const paths = props.paths;
    const [accessToken, setAccessToken] = props.accessToken;
    const API_DEFAULT = props.API_DEFAULT;
    const setSnack = props.setSnack;
    const setSeverity = props.setSeverity;
    const setMessage = props.setMessage;
    // const setShowBackdrop = props.setShowBackdrop;

    const [encuestadores, setEncuestadores] = useState([{ cedula: 'TODOS LOS ENCUESTADORES' }]);
    const [estudios, setEstudios] = useState([]);
    const [coordinadores, setCoordinadores] = useState([]);
    const [directoresCampo, setDirectoresCampo] = useState([]);
    const [directoresMisional, setDirectoresMisional] = useState([]);

    const [estudio, setEstudio] = useState(null);
    const [encuestador, setEncuestador] = useState([]);
    const [ciudad, setCiudad] = useState(null);
    const [regional, setRegional] = useState(null);
    const [coordinador, setCoordinador] = useState(null);
    const [directorCampo, setDirectorCampo] = useState(null);
    const [directorProyecto, setDirectorProyecto] = useState(null);
    const [vicepresidencia, setVicepresidencia] = useState(null);

    const [errores, setErrores] = useState(Array(8).fill(false));

    async function fetchEncuestadores(access = accessToken) {
        const res = await fetch(API_DEFAULT + '/productividad/encuestadores/', {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access }
        });
        if (res.ok) {
            res.json().then(res => { const aux = encuestadores.concat(res); setEncuestadores(aux) });
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        fetchEncuestadores(newAccess);
                    }
                }
            });
        }
    }

    async function fetchEstudios(access = accessToken) {
        const res = await fetch(API_DEFAULT + '/productividad/estudios/', {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access }
        });
        if (res.ok) {
            res
                .json()
                .then(res => { setEstudios(res.filter(r => r.nombre_integra !== 'Vacaciones' && r.nombre_integra !== 'Descuentos' && r.nombre_integra !== 'Calamidades')) });
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        fetchEstudios(newAccess);
                    }
                }
            });
        }
    }

    async function fetchCoordinadores(access = accessToken) {
        const res = await fetch(API_DEFAULT + '/personal/coordinadores/', {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
        })
        if (res.ok) {
            res
                .json()
                .then(res => { setCoordinadores(res) });
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        fetchCoordinadores(newAccess);
                    }
                }
            });
        }
    }

    async function fetchDirectoresCampo(access = accessToken) {
        const res = await fetch(API_DEFAULT + '/personal/directores/campo/', {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
        })
        if (res.ok) {
            res
                .json()
                .then(res => { setDirectoresCampo(res) });
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        fetchDirectoresCampo(newAccess);
                    }
                }
            });
        }
    }

    async function fetchDirectoresMisional(access = accessToken) {
        const res = await fetch(API_DEFAULT + '/personal/directores/misional/', {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
        })
        if (res.ok) {
            res
                .json()
                .then(res => { setDirectoresMisional(res) });
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        fetchDirectoresMisional(newAccess);
                    }
                }
            });
        }
    }

    function validar() {
        let errorValidar = false;
        if (estudio === null) {
            errorValidar = true;
            errores[0] = true;
        }
        if (encuestador === null) {
            errorValidar = true;
            errores[1] = true;
        }
        if (ciudad === null) {
            errorValidar = true;
            errores[2] = true;
        }
        if (regional === null) {
            errorValidar = true;
            errores[3] = true;
        }
        if (coordinador === null) {
            errorValidar = true;
            errores[4] = true;
        }
        if (directorCampo === null) {
            errorValidar = true;
            errores[5] = true;
        }
        if (directorProyecto === null) {
            errorValidar = true;
            errores[6] = true;
        }
        if (vicepresidencia === null) {
            errorValidar = true;
            errores[7] = true;
        }
        if (!errorValidar) {
            alert('yes');
            //limpiar();
        }
        else {
            setErrores([...errores]);
            setMessage('Existen campos sin diligenciar o con algún error.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
        }
    }

    useEffect(() => { if (validRole) { fetchEncuestadores(); fetchEstudios(); fetchCoordinadores(); fetchDirectoresCampo(); fetchDirectoresMisional() } }, [validRole]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Card style={{ marginBottom: theme.spacing(2) }}>
            <CardHeader
                title={
                    <Typography variant='h6'>Consultar Reportes</Typography>
                }
            />
            <Divider />
            <CardContent>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                >
                    <Grid item xs={12} md={6}>
                        <Typography variant='body1'>Filtro por estudio:</Typography>
                        <Autocomplete
                            value={estudio}
                            onChange={(e, v) => { setEstudio(v); errores[0] = false; setErrores([...errores]) }}
                            options={
                                estudios.map(option => option.nombre_integra)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    error={errores[0]}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant='body1'>Filtro por encuestador:</Typography>
                        <Autocomplete
                            value={encuestador}
                            onChange={(e, v) => { setEncuestador(v); errores[1] = false; setErrores([...errores]) }}
                            options={
                                encuestador.length === 0 ?
                                    encuestadores.map(option => option.cedula === 'TODOS LOS ENCUESTADORES' ? 'TODOS LOS ENCUESTADORES' : '' + option.cedula + ': ' + option.p_nombre + (option.s_nombre !== '' ? ' ' + option.s_nombre : '') + ' ' + option.p_apellido + (option.s_apellido !== '' ? ' ' + option.s_apellido : ''))
                                    :
                                    encuestador.length === 1 && encuestador[0] === 'TODOS LOS ENCUESTADORES' ?
                                        []
                                        :
                                        encuestadores.slice(1).map(option => '' + option.cedula + ': ' + option.p_nombre + (option.s_nombre !== '' ? ' ' + option.s_nombre : '') + ' ' + option.p_apellido + (option.s_apellido !== '' ? ' ' + option.s_apellido : ''))
                            }
                            renderOption={(option) => {
                                if (option === 'TODOS LOS ENCUESTADORES')
                                    return (<span style={{ fontWeight: 500 }}>{option}</span>);
                                else
                                    return (<span>{option}</span>);
                            }}
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    error={errores[1]}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                    style={{ marginTop: theme.spacing(1) }}
                >
                    <Grid item xs={12} sm={6} md>
                        <Typography variant='body1'>Filtro por ciudad:</Typography>
                        <Autocomplete
                            value={ciudad}
                            onChange={(e, v) => { setCiudad(v); errores[2] = false; setErrores([...errores]) }}
                            options={
                                municipios.map(option => option.municipio)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    error={errores[2]}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md>
                        <Typography variant='body1'>Filtro por regional:</Typography>
                        <Autocomplete
                            value={regional}
                            onChange={(e, v) => { setRegional(v); errores[3] = false; setErrores([...errores]) }}
                            options={
                                [
                                    'Bogotá',
                                    'Medellín',
                                    'Bucaramanga',
                                    'Cali',
                                    'Barranquilla'
                                ].map(option => option)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    error={errores[3]}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs>
                        <Typography variant='body1'>Filtro por coordinador(a) de campo:</Typography>
                        <Autocomplete
                            value={coordinador}
                            onChange={(e, v) => { setCoordinador(v); errores[4] = false; setErrores([...errores]) }}
                            options={
                                coordinadores.map(option => '' + option.cedula + ': ' + option.p_nombre + (option.s_nombre !== '' ? ' ' + option.s_nombre : '') + ' ' + option.p_apellido + (option.s_apellido !== '' ? ' ' + option.s_apellido : ''))
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    error={errores[4]}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                    style={{ marginTop: theme.spacing(1) }}
                >
                    <Grid item xs={12} sm={6} md>
                        <Typography variant='body1'>Filtro por director(a) de campo:</Typography>
                        <Autocomplete
                            value={directorCampo}
                            onChange={(e, v) => { setDirectorCampo(v); errores[5] = false; setErrores([...errores]) }}
                            options={
                                directoresCampo.map(option => '' + option.cedula + ': ' + option.p_nombre + (option.s_nombre !== '' ? ' ' + option.s_nombre : '') + ' ' + option.p_apellido + (option.s_apellido !== '' ? ' ' + option.s_apellido : ''))
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    error={errores[5]}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md>
                        <Typography variant='body1'>Filtro por director(a) de proyecto:</Typography>
                        <Autocomplete
                            value={directorProyecto}
                            onChange={(e, v) => { setDirectorProyecto(v); errores[6] = false; setErrores([...errores]) }}
                            options={
                                directoresMisional.map(option => '' + option.cedula + ': ' + option.p_nombre + (option.s_nombre !== '' ? ' ' + option.s_nombre : '') + ' ' + option.p_apellido + (option.s_apellido !== '' ? ' ' + option.s_apellido : ''))
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    error={errores[6]}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs>
                        <Typography variant='body1'>Filtro por vicepresidencia misional:</Typography>
                        <Autocomplete
                            value={vicepresidencia}
                            onChange={(e, v) => { setVicepresidencia(v); errores[7] = false; setErrores([...errores]) }}
                            options={
                                [
                                    'Marca y medios',
                                    'Consultoría empresarial',
                                    'Lealtad'
                                ].map(option => option)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    error={errores[7]}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    justify='flex-end'
                    spacing={1}
                    style={{ marginTop: theme.spacing(1.5) }}
                >
                    <Grid item style={{ display: 'flex' }}>
                        <Button style={{ marginRight: theme.spacing(1) }} fullWidth={true} variant='contained' color='primary' onClick={() => validar()}>Consultar</Button>
                        <Button component={Link} fullWidth={true} to={paths[0]} variant='contained' color='secondary'>Regresar</Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default ConstultarRepo;