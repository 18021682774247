import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Divider} from '@material-ui/core';
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    TableContainer,
    TablePagination,
    Paper,
} from '@material-ui/core';

import {Link} from "react-router-dom";
import { Visibility } from '@material-ui/icons';
import DialogoDetalles from './DialogoDetalles';


const useStyles = makeStyles({
    table: {
        minWidth: 500,
    },
    menuItem: {
        fontSize: 14
    }
});


function TablaHistorial(props) {
    const classes = useStyles();
    const { theme, validRole, getAccessTokenWithRefresh, anticipo, paths} = props;
    const [open, setOpen] = props.open;
    const [accessToken, setAccessToken] = props.accessToken;
    const API_DEFAULT = props.API_DEFAULT;
    const [revisiones, setRevisiones] = useState([]);
    const setSnack = props.setSnack;
    const setSeverity = props.setSeverity;
    const setMessage = props.setMessage;
    const setShowBackdrop = props.setShowBackdrop;
    const [revisionDetalles, setRevisionDetalles] = useState({})
    const [openDetalles,setOpenDetalles] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const emptyRows = rowsPerPage < revisiones.length ? rowsPerPage - Math.min(rowsPerPage, revisiones.length - page * rowsPerPage) : 0;

   async function fetchRevisiones(access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(API_DEFAULT + '/legalizaciones/revision_anticipos/?anticipo='+anticipo.id, {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
        })
        if (res.ok) {
            res.json().then(res => {
                setRevisiones(res)
                setShowBackdrop(false);
            });
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        fetchRevisiones(newAccess);
                    }
                }
            });
        }
        
    }

    
    

    useEffect(() => {
        if(open && validRole){
            fetchRevisiones();
        }
    },[open, validRole]); // eslint-disable-line react-hooks/exhaustive-deps

    const limpiar = () => {
        setOpen(false);
    }
    
    return (
        <Dialog
            open={open}
            maxWidth='m'
        >
            <DialogTitle style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }}>Historial anticipo {anticipo.id}</DialogTitle>
            <Divider />
            <DialogContent style={{ paddingBottom: theme.spacing(2) }}>
                <Typography variant='subtitle1'>Creador:</Typography> {anticipo.creador}
            <TableContainer component={Paper} elevation={0} variant="outlined">
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ fontSize: 16 }} align="center">Fecha de modificación</TableCell>
                                                <TableCell style={{ fontSize: 16 }} align="right">Editor</TableCell>
                                                <TableCell style={{ fontSize: 16 }} align="right">Comentario</TableCell>
                                                <TableCell style={{ fontSize: 16 }} align="right">Ver Detalles</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {revisiones.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row) => (
                                                    <TableRow key={row.id}>
                                                        <TableCell style={{ fontSize: 16, paddingTop: 0, paddingBottom: 0 }} component="th" scope="row">
                                                            {new Date(row.fecha_edicion).toLocaleString()}
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: 16, paddingTop: 0, paddingBottom: 0 }} component="th" scope="row">
                                                            {row.editor}
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: 16, paddingTop: 0, paddingBottom: 0 }} component="th" scope="row">
                                                            {row.comentario}
                                                        </TableCell>                                                     
                                                        <TableCell style={{ fontSize: 16, paddingTop: 0, paddingBottom: 0 }} component="th" scope="row">
                                                            <IconButton color='primary' style={{ marginRight: 12, marginLeft: 8 }} component={Link} size='small' onClick={() => { setRevisionDetalles(row.revision); setOpenDetalles(true) }}>
                                                                <Visibility style={{ height: 24, width: 'auto' }} />
                                                            </IconButton>
                                                           
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            {emptyRows > 0 && (
                                                <TableRow style={{ height: 52.8 * emptyRows }}>
                                                    <TableCell />
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                    <TablePagination
                                        component="div"
                                        //style={{ minWidth: 985 }}
                                        count={revisiones.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        SelectProps={{
                                            style: {
                                                fontSize: 14
                                            }
                                        }}
                                        classes={{ menuItem: classes.menuItem }}
                                    />
                                </TableContainer>
            </DialogContent>
            <Divider />
            <DialogActions style={{ padding: theme.spacing(1.5) }}>
                <Button size='m' variant='contained' color='secondary' onClick={() => {limpiar()}} >
                    Cancelar
                </Button>
            </DialogActions>
            <DialogoDetalles
                theme={theme}
                revision={revisionDetalles}
                open={[openDetalles, setOpenDetalles]}
                setMessage={setMessage}
                setSeverity={setSeverity}
                setSnack={setSnack}
                API_DEFAULT={API_DEFAULT}
                accessToken={[accessToken, setAccessToken]}
                setShowBackdrop={setShowBackdrop}
                getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                paths={paths}
                validRole={validRole}
            />
        </Dialog>
    );
}

export default TablaHistorial;