import React from 'react';
import {
    Dialog,
    DialogTitle,
    Divider,
    DialogContent,
    DialogActions,
    Button,
    Typography
} from '@material-ui/core';

function DialogoRevisionGiro(props) {
    const theme = props.theme;
    const id = props.id;
    const [open, setOpen] = props.open;
    const setOpenDetalle = props.setOpenDetalle;
    const postGirado = props.postGirado;
    const girado = props.girado;

    function enviar() {
        postGirado(id);
        setOpen(false);
        setOpenDetalle(false);
    }

    return (
        <Dialog
            open={open}
            onClose={() => {  setOpen(false) }}
            fullWidth={true}
            maxWidth='sm'
        >
            <DialogTitle>{!girado?'No ':null}Girado</DialogTitle>
            <Divider />
            <DialogContent>
                <Typography style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }} variant='body1'>¿Está seguro(a) de que quiere actualizar el anticipo?</Typography>
            </DialogContent>
            <Divider />
            <DialogActions style={{ paddingRight: 16, paddingLeft: 16, paddingBottom: 24, paddingTop: 16 }}>
                <Button onClick={() => enviar()} size='small' color='primary' variant='contained'>Aceptar</Button>
                <Button onClick={() => { setOpen(false) }} size='small' color='secondary' variant='contained'>Cancelar</Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogoRevisionGiro;