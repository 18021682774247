import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Collapse,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import EsLocale from "date-fns/locale/es";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import CurrencyTextField from "@unicef/material-ui-currency-textfield/dist/CurrencyTextField";
import capitalize from "../../styles/capitalize";

import municipios from "../../data/municipios";

import DineroEnviado from "./DineroEnviado";
import DialogoSuperaPresupuesto from "./dialogoSuperaPresupuesto";
import conceptos from "../../data/conceptos";

function RegistrarAnt(props) {
  const { theme, getAccessTokenWithRefresh, validRole } = props;
  const paths = props.paths;
  const [accessToken, setAccessToken] = props.accessToken;
  const API_DEFAULT = props.API_DEFAULT;
  const setSnack = props.setSnack;
  const setSeverity = props.setSeverity;
  const setMessage = props.setMessage;
  const setShowBackdrop = props.setShowBackdrop;

  const [encuestadores, setEncuestadores] = useState([]);
  const [estudios, setEstudios] = useState([]);
  const [coordinadores, setCoordinadores] = useState([]);
  const [directoresCampo, setDirectoresCampo] = useState([]);
  const [directoresMisional, setDirectoresMisional] = useState([]);

  const [encuestador, setEncuestador] = useState(null);
  const [tipoContrato, setTipoContrato] = useState("");
  const [cargo, setCargo] = useState("");

  const [dineroAlojamiento, setDineroAlojamiento] = useState("");
  const [dineroManutencion, setDineroManutencion] = useState("");
  const [dineroUrbano, setDineroUrbano] = useState("");
  const [dineroIntermunicipal, setDineroIntermunicipal] = useState("");
  const [dineroRural, setDineroRural] = useState("");
  const [dineroOtros, setDineroOtros] = useState("");
  const [dineroEnviado, setDineroEnviado] = useState("");
  const [openDinero, setOpenDinero] = useState(false);
  const [detallesAlojamiento, setDetallesAlojamiento] = useState([]);
  const [detallesManutencion, setDetallesManutencion] = useState([]);
  const [detallesUrbano, setDetallesUrbano] = useState([]);
  const [detallesIntermunicipal, setDetallesIntermunicipal] = useState([]);
  const [detallesRural, setDetallesRural] = useState([]);
  const [detallesOtros, setDetallesOtros] = useState([]);

  const [medioEnvio, setMedioEnvio] = useState(null);
  const [otroMedioEnvio, setOtroMedioEnvio] = useState("");
  const [cuenta, setCuenta] = useState("");
  const [fechaEnvio, setFechaEnvio] = useState(new Date());
  const [estudio, setEstudio] = useState(null);
  const [coordinador, setCoordinador] = useState(null);
  const [ciudad, setCiudad] = useState(null);
  const [regional, setRegional] = useState(null);
  const [directorCampo, setDirectorCampo] = useState(null);
  const [directorMisional, setDirectorMisional] = useState(null);
  const [descripcion, setDescripcion] = useState(null);
  const [valoresPresupuesto, setValoresPresupuesto] = useState([]);
  const [errores, setErrores] = useState(Array(11).fill(false));

  const [openSuperaPresupuesto, setOpenSuperaPresupuesto] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [soporte, setSoporte] = useState(null);
  const hiddenInput = useRef(null);
  const mediosEnvio = [
    { index: 1, nombre: "Giro empresarial Banco de Bogotá" },
    { index: 2, nombre: "Daviplata" },
    { index: 3, nombre: "Efecty" },
    { index: 4, nombre: "Otro" },
  ];
  const regionales = [
    { index: 0, nombre: "Bogotá" },
    { index: 1, nombre: "Bucaramanga" },
    { index: 2, nombre: "Medellín" },
    { index: 3, nombre: "Cali" },
    { index: 4, nombre: "Barranquilla" },
  ];

  async function fetchEncuestadores(access = accessToken) {
    const res = await fetch(API_DEFAULT + "/legalizaciones/encuestadores/", {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access,
      },
    });
    if (res.ok) {
      res.json().then((res) => setEncuestadores(res));
    } else {
      res.json().then(async (res) => {
        if (res["code"] === "token_not_valid") {
          let newAccess = await getAccessTokenWithRefresh();
          if (newAccess) {
            setAccessToken(newAccess);
            fetchEncuestadores(newAccess);
          }
        }
      });
    }
  }

  async function fetchEstudios(access = accessToken) {
    const res = await fetch(
      API_DEFAULT + "/legalizaciones/estudios_disponibles/",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + access,
        },
      }
    );
    if (res.ok) {
      res.json().then((res) => {
        setEstudios(
          res.filter(
            (r) =>
              r.nombre_integra !== "Vacaciones" &&
              r.nombre_integra !== "Descuentos" &&
              r.nombre_integra !== "Calamidades"
          )
        );
      });
    } else {
      res.json().then(async (res) => {
        if (res["code"] === "token_not_valid") {
          let newAccess = await getAccessTokenWithRefresh();
          if (newAccess) {
            setAccessToken(newAccess);
            fetchEstudios(newAccess);
          }
        }
      });
    }
  }

  async function fetchInfoEnc(cedula, access = accessToken) {
    const res = await fetch(
      API_DEFAULT +
        "/productividad/informacion_personal/cedula=" +
        cedula +
        "/",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + access,
        },
      }
    );
    if (res.ok) {
      res.json().then((res) => {
        setCargo(res.cargo);
        setTipoContrato(res.tipo_contrato);
        setCuenta(res.celular);
      });
    } else {
      res.json().then(async (res) => {
        if (res["code"] === "token_not_valid") {
          let newAccess = await getAccessTokenWithRefresh();
          if (newAccess) {
            setAccessToken(newAccess);
            fetchInfoEnc(cedula, newAccess);
          }
        }
      });
    }
  }

  async function fetchCoordinadores(access = accessToken) {
    const res = await fetch(API_DEFAULT + "/personal/coordinadores/", {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access,
      },
    });
    if (res.ok) {
      res.json().then((res) => {
        setCoordinadores(res);
      });
    } else {
      res.json().then(async (res) => {
        if (res["code"] === "token_not_valid") {
          let newAccess = await getAccessTokenWithRefresh();
          if (newAccess) {
            setAccessToken(newAccess);
            fetchCoordinadores(newAccess);
          }
        }
      });
    }
  }

  async function fetchValoresPresupuesto(search, access = accessToken) {
    const res = await fetch(
      API_DEFAULT + "/legalizaciones/valores_presupuesto/?estudio=" + search,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + access,
        },
      }
    );
    if (res.ok) {
      res.json().then((res) => {
        setValoresPresupuesto(res);
      });
    } else {
      res.json().then(async (res) => {
        if (res["code"] === "token_not_valid") {
          let newAccess = await getAccessTokenWithRefresh();
          if (newAccess) {
            setAccessToken(newAccess);
            fetchValoresPresupuesto(search, newAccess);
          }
        }
      });
    }
  }

  async function fetchDirectoresCampo(access = accessToken) {
    const res = await fetch(API_DEFAULT + "/personal/directores/campo/", {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access,
      },
    });
    if (res.ok) {
      res.json().then((res) => {
        setDirectoresCampo(res);
      });
    } else {
      res.json().then(async (res) => {
        if (res["code"] === "token_not_valid") {
          let newAccess = await getAccessTokenWithRefresh();
          if (newAccess) {
            setAccessToken(newAccess);
            fetchDirectoresCampo(newAccess);
          }
        }
      });
    }
  }

  async function fetchDirectoresMisional(access = accessToken) {
    const res = await fetch(API_DEFAULT + "/personal/directores/misional/", {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access,
      },
    });
    if (res.ok) {
      res.json().then((res) => {
        setDirectoresMisional(res);
      });
    } else {
      res.json().then(async (res) => {
        if (res["code"] === "token_not_valid") {
          let newAccess = await getAccessTokenWithRefresh();
          if (newAccess) {
            setAccessToken(newAccess);
            fetchDirectoresMisional(newAccess);
          }
        }
      });
    }
  }

  async function postAnticipo(access = accessToken) {
    setShowBackdrop(true);
    let res = await fetch(API_DEFAULT + "/legalizaciones/anticipos/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access,
      },
      body: JSON.stringify({
        estudio: estudio,
        encuestador: parseInt(encuestador.split(": ")[0]),
        coordinador: parseInt(coordinador.split(": ")[0]),
        director: parseInt(directorCampo.split(": ")[0]),
        director_aprueba: parseInt(directorMisional.split(": ")[0]),
        valor_alojamiento:
          dineroAlojamiento === "" ? 0 : parseInt(dineroAlojamiento),
        valor_manutencion:
          dineroManutencion === "" ? 0 : parseInt(dineroManutencion),
        valor_transporte_urbano:
          dineroUrbano === "" ? 0 : parseInt(dineroUrbano),
        valor_transporte_intermunicipal:
          dineroIntermunicipal === "" ? 0 : parseInt(dineroIntermunicipal),
        valor_transporte_rural: dineroRural === "" ? 0 : parseInt(dineroRural),
        valor_otro: dineroOtros === "" ? 0 : parseInt(dineroOtros),
        valor: dineroEnviado === "" ? 0 : parseInt(dineroEnviado),
        medio_envio: mediosEnvio.filter((m) => m.nombre === medioEnvio)[0]
          .index,
        otro_medio_envio: otroMedioEnvio,
        cuenta: cuenta,
        fecha: fechaEnvio.toISOString(),
        regional: regionales.filter((r) => r.nombre === regional)[0].index,
        municipio: municipios.filter((m) => m.municipio === ciudad)[0].daneMuni,
        descripcion: descripcion,
      }),
    });

    if (res.ok) {
      let id = "";
      await res.json().then((res) => {
        id = res["id"];
      });
      let responses = [];
      if (soporte !== null) {
        let detallesResult = postDetalles(id, access);
        responses = await detallesResult;

        let soporteResult = postSoporte(id, access);
        responses.push((await soporteResult).status);
      } else {
        let detallesResult = postDetalles(id, access);
        responses = await detallesResult;
      }

      if (responses.every((val) => val === 200)) {
        setShowBackdrop(false);
        setMessage("El anticipo se registró exitosamente.");
        setSeverity("success");
        setTimeout(() => {
          setSnack(true);
        }, 0);
        limpiar();
      } else {
        res = await fetch(API_DEFAULT + "/legalizaciones/anticipos/", {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + access,
          },
          body: JSON.stringify({
            id: id,
          }),
        });
        setShowBackdrop(false);
        setMessage("Ocurrió un error. Intente nuevamente.");
        setSeverity("error");
        setTimeout(() => {
          setSnack(true);
        }, 0);
      }
    } else {
      res.json().then(async (res) => {
        if (res["code"] === "token_not_valid") {
          let newAccess = await getAccessTokenWithRefresh();
          if (newAccess) {
            setAccessToken(newAccess);
            postAnticipo(newAccess);
          }
        } else {
          setMessage(
            "Hubo un problema cargando los datos, intente nuevamente."
          );
          setSeverity("error");
          setTimeout(() => {
            setSnack(true);
          }, 0);
          setShowBackdrop(false);
        }
      });
    }
  }

  async function postSoporte(id, access = accessToken) {
    const formData = new FormData();
    formData.append("anticipo", id);
    formData.append("soporte", soporte);

    const res = await fetch(API_DEFAULT + "/legalizaciones/soporte_anticipo/", {
      method: "POST",
      headers: { Authorization: "Bearer " + access },
      body: formData,
    });

    res.json().then(async (res) => {
      if (res["code"] === "token_not_valid") {
        let newAccess = await getAccessTokenWithRefresh();
        if (newAccess) {
          setAccessToken(newAccess);
          postSoporte(id, newAccess);
        }
      } else {
        return res;
      }
    });

    return res;
  }

  async function postDetalles(id, access = accessToken) {
    let detalles = detallesToList(id);
    let res;
    let responses = [];

    for (var i = 0; i < detalles.length; i++) {
      res = await fetch(API_DEFAULT + "/legalizaciones/detalle_anticipo/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + access,
        },
        body: JSON.stringify(detalles[i]),
      });

      if (res.ok) {
        responses.push(res.status);
      } else {
        // eslint-disable-next-line
        res.json().then(async (res) => {
          if (res["code"] === "token_not_valid") {
            let newAccess = await getAccessTokenWithRefresh();
            if (newAccess) {
              setAccessToken(newAccess);
              i = i - 1;
            }
          } else {
            responses.push(res.status);
          }
        });
      }
    }

    return responses;
  }

  function detallesToList(id) {
    let detalles = [].concat(
      detallesAlojamiento,
      detallesManutencion,
      detallesIntermunicipal,
      detallesRural,
      detallesUrbano,
      detallesOtros
    );
    detalles = detalles.filter((x) => x.total > 0);
    detalles.map((x) => {
      return (x["anticipo"] = id);
    });
    detalles.map((x) => {
      return (x["rubro"] = conceptos.filter(
        (c) => c.concepto === x.concepto
      )[0].idRubro);
    });

    return detalles;
  }

  function cargarArchivo(file) {
    if (file !== undefined) {
      if (
        file.type.split("/")[1] ===
          "vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type.split("/")[1] === "vnd.ms-excel"
      ) {
        setSoporte(file);
      } else {
        setMessage("El tipo de archivo seleccionado no es reconocido.");
        setSeverity("warning");
        setTimeout(() => {
          setSnack(true);
        }, 0);
      }
    }
  }

  function validar() {
    let errorValidar = false;
    if (encuestador === null) {
      errorValidar = true;
      errores[0] = true;
    }
    if (dineroEnviado === "" || dineroEnviado === "0") {
      errorValidar = true;
      errores[1] = true;
    }
    if (medioEnvio === null) {
      errorValidar = true;
      errores[2] = true;
    }
    if (medioEnvio === "Otro" && otroMedioEnvio === "") {
      errorValidar = true;
      errores[10] = true;
    }
    if (cuenta === "") {
      errorValidar = true;
      errores[3] = true;
    }
    if (estudio === null) {
      errorValidar = true;
      errores[4] = true;
    }
    if (coordinador === null) {
      errorValidar = true;
      errores[5] = true;
    }
    if (ciudad === null) {
      errorValidar = true;
      errores[6] = true;
    }
    if (regional === null) {
      errorValidar = true;
      errores[7] = true;
    }
    if (directorCampo === null) {
      errorValidar = true;
      errores[8] = true;
    }
    if (directorMisional === null) {
      errorValidar = true;
      errores[9] = true;
    }
    if (!errorValidar) {
      validarValores();
    } else {
      setErrores([...errores]);
      setMessage("Existen campos sin diligenciar o con algún error.");
      setSeverity("error");
      setTimeout(() => {
        setSnack(true);
      }, 0);
    }
  }

  function validarValores() {
    let superaPresupuesto = false;
    let msg = "";

    if (dineroAlojamiento > valoresPresupuesto.disponible_alojamiento) {
      superaPresupuesto = true;
      msg = "alojamiento";
    }

    if (dineroManutencion > valoresPresupuesto.disponible_manutencion) {
      superaPresupuesto = true;
      msg = msg === "" ? "manutención" : msg + ", manutención";
    }

    if (dineroUrbano > valoresPresupuesto.disponible_transporte_urbano) {
      superaPresupuesto = true;
      msg = msg === "" ? "transporte urbano" : msg + ", transporte urbano";
    }

    if (
      dineroIntermunicipal >
      valoresPresupuesto.disponible_transporte_intermunicipal
    ) {
      superaPresupuesto = true;
      msg =
        msg === ""
          ? "transporte intermunicipal"
          : msg + ", transporte intermunicipal";
    }

    if (dineroRural > valoresPresupuesto.disponible_transporte_rural) {
      superaPresupuesto = true;
      msg = msg === "" ? "transporte rural" : msg + ", transporte rural";
    }

    if (dineroOtros > valoresPresupuesto.disponible_otro) {
      superaPresupuesto = true;
      msg = msg === "" ? "otros" : msg + ", otros";
    }

    if (superaPresupuesto === true) {
      setMensaje(msg);
      setOpenSuperaPresupuesto(true);
    } else {
      postAnticipo();
    }
  }

  function handleChangeEstudio(value) {
    setEstudio(value);
    fetchValoresPresupuesto(value);
  }

  function limpiar() {
    setErrores(Array(10).fill(false));
    setEncuestador(null);
    setTipoContrato("");
    setCargo("");
    setDineroEnviado("");
    setMedioEnvio(null);
    setCuenta("");
    setFechaEnvio(new Date());
    setEstudio(null);
    setCoordinador(null);
    setCiudad(null);
    setRegional(null);
    setDirectorCampo(null);
    setDirectorMisional(null);
    setSoporte(null);
    setDescripcion("");
  }

  useEffect(() => {
    if (validRole) {
      fetchEncuestadores();
      fetchEstudios();
      fetchCoordinadores();
      fetchDirectoresCampo();
      fetchDirectoresMisional();
    }
  }, [validRole]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <Card style={{ marginBottom: theme.spacing(2) }}>
        <CardHeader
          title={<Typography variant="h6">Registrar anticipo</Typography>}
        />
        <Divider />
        <CardContent>
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item xs={12} md={5}>
              <Typography variant="body1">
                Seleccione el supervisor o encuestador:
              </Typography>
              <Autocomplete
                value={encuestador}
                onChange={(e, v) => {
                  setEncuestador(v);
                  errores[0] = false;
                  setErrores([...errores]);
                  if (v === null) {
                    setCargo("");
                    setTipoContrato("");
                  } else {
                    fetchInfoEnc(parseInt(v.split(": ")[0]));
                  }
                }}
                options={encuestadores.map(
                  (option) => "" + option.cedula + ": " + option.nombre
                )}
                noOptionsText="No hay coincidencias"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="outlined"
                    fullWidth
                    error={errores[0]}
                  />
                )}
              />
            </Grid>
            <Grid item md={3} sm xs={12}>
              <Typography variant="body1">Tipo de contrato:</Typography>
              <TextField
                value={capitalize(tipoContrato)}
                size="small"
                variant="outlined"
                fullWidth
                inputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs>
              <Typography variant="body1">Cargo:</Typography>
              <TextField
                value={capitalize(cargo)}
                size="small"
                variant="outlined"
                fullWidth
                inputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={2}
            style={{ marginTop: theme.spacing(1) }}
          >
            <Grid item md={5} sm={12} xs={12}>
              <Grid container direction="row" alignItems="center" spacing={1}>
                <Grid item xs>
                  <Typography variant="body1">Dinero enviado:</Typography>
                  <CurrencyTextField
                    value={dineroEnviado}
                    // onChange={(e, v) => { setDineroEnviado(v); errores[1] = false; setErrores([...errores]) }}
                    modifyValueOnWheel={false}
                    size="small"
                    variant="outlined"
                    currencySymbol="$"
                    outputFormat="string"
                    minimumValue="0"
                    textAlign="left"
                    decimalPlaces={0}
                    fullWidth
                    error={errores[1]}
                    selectOnFocus={false}
                    readOnly={true}
                    placeholder="Presione el botón INGRESAR"
                  />
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => setOpenDinero(true)}
                    style={{ marginTop: theme.spacing(3) }}
                    size="small"
                    variant="outlined"
                    color="primary"
                  >
                    Ingresar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Typography variant="body1">Medio de envío:</Typography>
              <Autocomplete
                value={medioEnvio}
                onChange={(e, v) => {
                  setMedioEnvio(v);
                  errores[2] = false;
                  setErrores([...errores]);
                }}
                options={mediosEnvio.map((option) => option.nombre)}
                noOptionsText="No hay coincidencias"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="outlined"
                    fullWidth
                    error={errores[2]}
                  />
                )}
              />
            </Grid>
            <Grid item md xs sm>
              <Collapse in={medioEnvio === "Otro"}>
                <Typography variant="body1">¿Cuál?:</Typography>
                <TextField
                  value={otroMedioEnvio}
                  size="small"
                  variant="outlined"
                  fullWidth
                  onChange={(e, v) => {
                    setOtroMedioEnvio(e.target.value);
                    errores[10] = false;
                    setErrores([...errores]);
                  }}
                  error={errores[10]}
                />
              </Collapse>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={2}
            style={{ marginTop: theme.spacing(1) }}
          >
            <Grid item md sm={6} xs={12}>
              <Typography variant="body1">No. cuenta:</Typography>
              <NumberFormat
                customInput={TextField}
                decimalSeparator={false}
                allowNegative={false}
                value={cuenta}
                onChange={(e) => {
                  setCuenta(e.target.value);
                  errores[3] = false;
                  setErrores([...errores]);
                }}
                size="small"
                variant="outlined"
                fullWidth
                error={errores[3]}
              />
            </Grid>
            <Grid item xs>
              <Typography variant="body1">Fecha de envío:</Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={EsLocale}>
                <DatePicker
                  value={fechaEnvio}
                  onChange={(date) => setFechaEnvio(date)}
                  size="small"
                  inputVariant="outlined"
                  format="EEEE, dd 'de' MMM 'del' yyyy"
                  fullWidth={true}
                  disableToolbar={true}
                  // minDate={(new Date()).setDate((new Date()).getDate() - 30)}
                  disableFuture={true}
                  autoOk={true}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={2}
            style={{ marginTop: theme.spacing(1) }}
          >
            <Grid item xs={12} md={3}>
              <Typography variant="body1">Seleccione el estudio:</Typography>
              <Autocomplete
                value={estudio}
                onChange={(e, v) => {
                  handleChangeEstudio(v);
                  errores[4] = false;
                  setErrores([...errores]);
                }}
                options={estudios.map((option) => option.nombre_integra)}
                noOptionsText="No hay coincidencias"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="outlined"
                    fullWidth
                    error={errores[4]}
                  />
                )}
              />
            </Grid>
            <Grid item md sm={7} xs={12}>
              <Typography variant="body1">
                Coordinador(a) que solicitó el anticipo:
              </Typography>
              <Autocomplete
                value={coordinador}
                onChange={(e, v) => {
                  setCoordinador(v);
                  errores[5] = false;
                  setErrores([...errores]);
                }}
                options={coordinadores.map(
                  (option) =>
                    "" +
                    option.cedula +
                    ": " +
                    capitalize(option.p_nombre) +
                    (capitalize(option.s_nombre) !== ""
                      ? " " + capitalize(option.s_nombre)
                      : "") +
                    " " +
                    capitalize(option.p_apellido) +
                    (capitalize(option.s_apellido) !== ""
                      ? " " + capitalize(option.s_apellido)
                      : "")
                )}
                noOptionsText="No hay coincidencias"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="outlined"
                    fullWidth
                    error={errores[5]}
                  />
                )}
              />
            </Grid>
            <Grid item xs>
              <Typography variant="body1">
                Ciudad de ejecución del proyecto:
              </Typography>
              <Autocomplete
                value={ciudad}
                onChange={(e, v) => {
                  setCiudad(v);
                  errores[6] = false;
                  setErrores([...errores]);
                }}
                options={municipios.map((option) => option.municipio)}
                noOptionsText="No hay coincidencias"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="outlined"
                    fullWidth
                    error={errores[6]}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={2}
            style={{ marginTop: theme.spacing(1) }}
          >
            <Grid item xs={12} sm={5} md={3}>
              <Typography variant="body1">Regional:</Typography>
              <Autocomplete
                value={regional}
                onChange={(e, v) => {
                  setRegional(v);
                  errores[7] = false;
                  setErrores([...errores]);
                }}
                options={regionales.map((option) => option.nombre)}
                noOptionsText="No hay coincidencias"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="outlined"
                    fullWidth
                    error={errores[7]}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md sm={7}>
              <Typography variant="body1">
                Director(a) de campo que aprobó:
              </Typography>
              <Autocomplete
                value={directorCampo}
                onChange={(e, v) => {
                  setDirectorCampo(v);
                  errores[8] = false;
                  setErrores([...errores]);
                }}
                options={directoresCampo.map(
                  (option) =>
                    "" +
                    option.cedula +
                    ": " +
                    capitalize(option.p_nombre) +
                    (capitalize(option.s_nombre) !== ""
                      ? " " + capitalize(option.s_nombre)
                      : "") +
                    " " +
                    capitalize(option.p_apellido) +
                    (capitalize(option.s_apellido) !== ""
                      ? " " + capitalize(option.s_apellido)
                      : "")
                )}
                noOptionsText="No hay coincidencias"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="outlined"
                    fullWidth
                    error={errores[8]}
                  />
                )}
              />
            </Grid>
            <Grid item xs>
              <Typography variant="body1">
                Director(a) de proyecto que aprobó:
              </Typography>
              <Autocomplete
                value={directorMisional}
                onChange={(e, v) => {
                  setDirectorMisional(v);
                  errores[9] = false;
                  setErrores([...errores]);
                }}
                options={directoresMisional.map(
                  (option) =>
                    "" +
                    option.cedula +
                    ": " +
                    capitalize(option.p_nombre) +
                    (capitalize(option.s_nombre) !== ""
                      ? " " + capitalize(option.s_nombre)
                      : "") +
                    " " +
                    capitalize(option.p_apellido) +
                    (capitalize(option.s_apellido) !== ""
                      ? " " + capitalize(option.s_apellido)
                      : "")
                )}
                noOptionsText="No hay coincidencias"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="outlined"
                    fullWidth
                    error={errores[9]}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={2}
            style={{ marginTop: theme.spacing(1) }}
          >
            <Grid item xs={12} md sm={8}>
              <Typography variant="body1">Descripción:</Typography>
              <TextField
                style={{ marginBottom: theme.spacing(1) }}
                value={descripcion}
                onChange={(e) => {
                  setDescripcion(e.target.value);
                }}
                size="small"
                variant="outlined"
                fullWidth
                multiline
                rows={2}
                inputProps={{ maxLength: 250 }}
              />
            </Grid>
            <Grid item xs={12} md sm={4}>
              <Typography variant="body1">Soporte:</Typography>
              <Typography
                variant="body1"
                style={{ marginRight: theme.spacing(2) }}
              >
                <Button
                  size="small"
                  variant="outlined"
                  color={soporte ? "secondary" : ""}
                  style={{ marginRight: theme.spacing(1), height: 25 }}
                  onClick={() =>
                    soporte ? setSoporte(null) : hiddenInput.current.click()
                  }
                >
                  <Typography variant="button">
                    {soporte ? "Limpiar" : "Cargar archivo"}
                  </Typography>
                </Button>
                <span style={{ fontSize: 14 }}>
                  {soporte ? soporte.name : "Ningún archivo cargado"}
                </span>
              </Typography>
              <input
                type="file"
                ref={hiddenInput}
                style={{ display: "none" }}
                onChange={(e) => {
                  cargarArchivo(e.target.files[0]);
                  e.target.value = null;
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            alignItems="center"
            justify="flex-end"
            spacing={1}
            style={{ marginTop: theme.spacing(1.5) }}
          >
            <Grid item style={{ display: "flex" }}>
              <Button
                style={{ marginRight: theme.spacing(1) }}
                fullWidth={true}
                variant="contained"
                color="primary"
                onClick={() => validar()}
              >
                Registrar
              </Button>
              <Button
                component={Link}
                fullWidth={true}
                to={paths[0]}
                variant="contained"
                color="secondary"
              >
                Regresar
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <DineroEnviado
        theme={theme}
        open={[openDinero, setOpenDinero]}
        dineroAlojamiento={[dineroAlojamiento, setDineroAlojamiento]}
        dineroManutencion={[dineroManutencion, setDineroManutencion]}
        dineroUrbano={[dineroUrbano, setDineroUrbano]}
        dineroIntermunicipal={[dineroIntermunicipal, setDineroIntermunicipal]}
        dineroRural={[dineroRural, setDineroRural]}
        dineroOtros={[dineroOtros, setDineroOtros]}
        dineroEnviado={[dineroEnviado, setDineroEnviado]}
        setErrorEnviado={[errores, setErrores]}
        rowsAlojamiento={[detallesAlojamiento, setDetallesAlojamiento]}
        rowsManutencion={[detallesManutencion, setDetallesManutencion]}
        rowsUrbano={[detallesUrbano, setDetallesUrbano]}
        rowsIntermunicipal={[detallesIntermunicipal, setDetallesIntermunicipal]}
        rowsRural={[detallesRural, setDetallesRural]}
        rowsOtros={[detallesOtros, setDetallesOtros]}
      />
      <DialogoSuperaPresupuesto
        theme={theme}
        open={[openSuperaPresupuesto, setOpenSuperaPresupuesto]}
        postAnticipo={postAnticipo}
        mensaje={mensaje}
      />
    </React.Fragment>
  );
}

export default RegistrarAnt;
