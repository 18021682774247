import React from 'react';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  BrowserRouter as Router
} from "react-router-dom";
import { esES } from '@material-ui/core/locale';

import Legalizaciones from './Legalizaciones/Legalizaciones';

let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#D84315'
    },
    secondary: {
      main: '#C62828'
    },
    background: {
      default: '#F4F6F8'
    },
    error: {
      main: '#e53935'
    },
    success: {
      main: '#43A047'
    }
  },
  typography: {
    fontFamily: 'Roboto'
  }
}, esES);

theme = responsiveFontSizes(theme);

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <div className='App'>
          <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
          <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
          <CssBaseline />
          <Legalizaciones theme={theme} />
        </div>
      </ThemeProvider>
    </Router>
  );
}

export default App;
