import React, { useState } from 'react';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import {
    Typography,
    Button,
    Grid,
    Card,
    CardContent,
    CardHeader,
    Divider
} from '@material-ui/core';
import {
    Route,
    Link,
    Switch,
    Redirect
} from "react-router-dom";

import RegistrarPpto from './RegistrarPpto';
import ModificarPpto from './ModificarPpto';

let themeDownloadButton = createMuiTheme({
    palette: {
        primary: {
            main: '#0277BD'
        }
    },
    typography: {
        fontFamily: 'Roboto'
    }
});
themeDownloadButton = responsiveFontSizes(themeDownloadButton);

function CoordCP(props) {
    const { theme, getAccessTokenWithRefresh, validRole } = props;
    const username = props.username;
    const paths = props.paths;
    const [accessToken, setAccessToken] = props.accessToken;
    const API_DEFAULT = props.API_DEFAULT;
    const setSnack = props.setSnack;
    const setSeverity = props.setSeverity;
    const setMessage = props.setMessage;
    const setShowBackdrop = props.setShowBackdrop;
    const [estudiosLibres, setEstudiosLibres] = useState([]);
    const [estudiosPresupuesto, setEstudiosPresupuesto] = useState([]);
    const [estudio, setEstudio] = useState(null);
    const [estudioError, setEstudioError] = useState(false);

    const [presupuestoAlojamiento, setPresupuestoAlojamiento] = useState('');
    const [presupuestoManutencion, setPresupuestoManutencion] = useState('');
    const [presupuestoUrbano, setPresupuestoUrbano] = useState('');
    const [presupuestoIntermunicipal, setPresupuestoIntermunicipal] = useState('');
    const [presupuestoRural, setPresupuestoRural] = useState('');
    const [presupuestoOtros, setPresupuestoOtros] = useState('');

    // const [presupuesto, setPresupuesto] = useState('');
    const [presupuestoLegalizaciones, setPresupuestoLegalizaciones] = useState('');
    const [presupuestoError, setPresupuestoError] = useState(false);

    function limpiar() {
        setEstudio(null);
        setEstudioError(false);
        setPresupuestoAlojamiento('');
        setPresupuestoManutencion('');
        setPresupuestoUrbano('');
        setPresupuestoIntermunicipal('');
        setPresupuestoRural('');
        setPresupuestoOtros('');
        setPresupuestoError(false);
    }

    function validateSend(post) {
        let errorSend = false;
        if (estudio === null) {
            errorSend = true;
            setEstudioError(true);
        }
        if (presupuestoLegalizaciones === '' || presupuestoLegalizaciones === '0') {
            console.log(presupuestoLegalizaciones)
            errorSend = true;
            setPresupuestoError(true);
        }
        if (!errorSend) {
            post ? postPresupuesto() : putPresupuesto()
            setMessage('El presupuesto del estudio se actualizó exitosamente.');
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);
            limpiar();
        }
        else {
            setMessage('Existen campos sin diligenciar o con algún error.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
        }
    }

    async function fetchEstudiosLibres(access = accessToken) {
        const res = await fetch(API_DEFAULT + '/legalizaciones/estudios_libres/', {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
        })
        if (res.ok) {
            res.json().then(res => { setEstudiosLibres(res) });
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        fetchEstudiosLibres(newAccess);
                    }
                }
            });
        }
    }

    async function fetchPresupuestos(access = accessToken) {
        const res = await fetch(API_DEFAULT + '/legalizaciones/presupuestos/', {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
        })
        if (res.ok) {
            res.json().then(res => { setShowBackdrop(false); setEstudiosPresupuesto(res) });
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        fetchPresupuestos(newAccess);
                    }
                }
            });
        }
    }

    async function postPresupuesto(access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(API_DEFAULT + '/legalizaciones/presupuestos/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
            body: JSON.stringify({
                'estudio': estudio,
                'valor_alojamiento': presupuestoAlojamiento === '' ? 0 : parseFloat(presupuestoAlojamiento),
                'valor_manutencion': presupuestoManutencion === '' ? 0 : parseFloat(presupuestoManutencion),
                'valor_transporte_urbano': presupuestoUrbano === '' ? 0 : parseFloat(presupuestoUrbano),
                'valor_transporte_intermunicipal': presupuestoIntermunicipal === '' ? 0 : parseFloat(presupuestoIntermunicipal),
                'valor_transporte_rural': presupuestoRural === '' ? 0 : parseFloat(presupuestoRural),
                'valor_otro': presupuestoOtros === '' ? 0 : parseFloat(presupuestoOtros),
                'valor_total': presupuestoLegalizaciones === '' ? 0 : parseFloat(presupuestoLegalizaciones)
            })
        })
        if (res.ok) {
            res.json().then(res => { setShowBackdrop(false); fetchEstudiosLibres() });
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        postPresupuesto(newAccess);
                    }
                }
            });
        }
    }

    async function putPresupuesto(access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(API_DEFAULT + '/legalizaciones/presupuestos/', {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
            body: JSON.stringify({
                'id': estudiosPresupuesto.filter(e => e.estudio === estudio)[0].id,
                'estudio': estudio,
                'valor_alojamiento': presupuestoAlojamiento === '' ? 0 : parseFloat(presupuestoAlojamiento),
                'valor_manutencion': presupuestoManutencion === '' ? 0 : parseFloat(presupuestoManutencion),
                'valor_transporte_urbano': presupuestoUrbano === '' ? 0 : parseFloat(presupuestoUrbano),
                'valor_transporte_intermunicipal': presupuestoIntermunicipal === '' ? 0 : parseFloat(presupuestoIntermunicipal),
                'valor_transporte_rural': presupuestoRural === '' ? 0 : parseFloat(presupuestoRural),
                'valor_otro': presupuestoOtros === '' ? 0 : parseFloat(presupuestoOtros),
                'valor_total': presupuestoLegalizaciones === '' ? 0 : parseFloat(presupuestoLegalizaciones)
            })
        })
        if (res.ok) {
            res.json().then(res => { console.log(res); fetchPresupuestos() });
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        putPresupuesto(newAccess);
                    }
                }
            });
        }
    }

    return (
        <React.Fragment>
            <Switch>
                <Route
                    exact
                    path={paths[0]}
                    render={() => (
                        <Card style={{ marginBottom: theme.spacing(2) }}>
                            <CardHeader
                                title={
                                    <Typography variant='h6'>Rol: Coordinador de costos y presupuesto</Typography>
                                }
                            />
                            <Divider />
                            <CardContent>
                                <Grid
                                    container
                                    direction='column'
                                    alignItems='center'
                                    justify='center'
                                    style={{ marginTop: theme.spacing(1) }}
                                >
                                    <Grid item>
                                        <Typography style={{ fontSize: 17 }} variant='body1'>¡Hola {username}!</Typography>
                                    </Grid>
                                    <Grid item style={{ marginBottom: theme.spacing(3) }}>
                                        <Typography style={{ fontSize: 18 }} align='center' variant='body1'>Bienvenido(a) al aplicativo de legalizaciones</Typography>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    direction='row'
                                    alignItems='center'
                                    justify='center'
                                    spacing={1}
                                >
                                    <Grid item style={{ display: 'flex', justifyContent: 'center' }} xs={12} md={6}>
                                        <Button component={Link} style={{ maxWidth: 500 }} fullWidth={true} to={paths[1]} variant='contained' color='primary'>Registrar presupuesto de un estudio</Button>
                                    </Grid>
                                    <Grid item style={{ display: 'flex', justifyContent: 'center' }} xs={12} md={6}>
                                        <ThemeProvider theme={themeDownloadButton}>
                                            <Button component={Link} style={{ maxWidth: 500 }} fullWidth={true} to={paths[2]} variant='contained' color='primary'>Modificar presupuesto de un estudio</Button>
                                        </ThemeProvider>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    )}
                />
                <Route
                    exact
                    path={paths[1]}
                    render={() => (
                        <RegistrarPpto
                            fetchEstudiosLibres={fetchEstudiosLibres}
                            validRole={validRole}
                            theme={theme}
                            paths={paths}
                            estudios={estudiosLibres}
                            estudio={[estudio, setEstudio]}
                            estudioError={[estudioError, setEstudioError]}
                            presupuestoAlojamiento={[presupuestoAlojamiento, setPresupuestoAlojamiento]}
                            presupuestoManutencion={[presupuestoManutencion, setPresupuestoManutencion]}
                            presupuestoUrbano={[presupuestoUrbano, setPresupuestoUrbano]}
                            presupuestoIntermunicipal={[presupuestoIntermunicipal, setPresupuestoIntermunicipal]}
                            presupuestoRural={[presupuestoRural, setPresupuestoRural]}
                            presupuestoOtros={[presupuestoOtros, setPresupuestoOtros]}
                            presupuestoLegalizaciones={[presupuestoLegalizaciones, setPresupuestoLegalizaciones]}
                            presupuestoError={[presupuestoError, setPresupuestoError]}
                            validateSend={validateSend}
                            limpiar={limpiar}
                        />
                    )}
                />
                <Route
                    exact
                    path={paths[2]}
                    render={() => (
                        <ModificarPpto
                            fetchPresupuestos={fetchPresupuestos}
                            validRole={validRole}
                            theme={theme}
                            paths={paths}
                            estudios={estudiosPresupuesto}
                            estudio={[estudio, setEstudio]}
                            estudioError={[estudioError, setEstudioError]}
                            presupuestoAlojamiento={[presupuestoAlojamiento, setPresupuestoAlojamiento]}
                            presupuestoManutencion={[presupuestoManutencion, setPresupuestoManutencion]}
                            presupuestoUrbano={[presupuestoUrbano, setPresupuestoUrbano]}
                            presupuestoIntermunicipal={[presupuestoIntermunicipal, setPresupuestoIntermunicipal]}
                            presupuestoRural={[presupuestoRural, setPresupuestoRural]}
                            presupuestoOtros={[presupuestoOtros, setPresupuestoOtros]}
                            presupuestoLegalizaciones={[presupuestoLegalizaciones, setPresupuestoLegalizaciones]}
                            presupuestoError={[presupuestoError, setPresupuestoError]}
                            validateSend={validateSend}
                            limpiar={limpiar}
                        />
                    )}
                />
                <Route render={() => (<Redirect to={paths[0]} />)} />
            </Switch>
        </React.Fragment>
    );
}

export default CoordCP;