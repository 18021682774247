import React, { useState, useRef, useEffect } from 'react';
import {
    Dialog, DialogActions, DialogContent, Button, DialogTitle, Divider, IconButton
} from '@material-ui/core';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import Pagination from '@material-ui/lab/Pagination';
import Zoom from 'react-medium-image-zoom';
import throttle from 'lodash.throttle';
import 'react-medium-image-zoom/dist/styles.css';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function DialogoDocumento(props) {
    const title = props.title;
    const path = props.path[0];
    const [open, setOpen] = props.open;
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [display, setDisplay] = useState(false);
    const [zoom, setZoom] = useState(4);
    const refContent = useRef(null);
    const [widthPadre, setWidthPadre] = useState(0);
    const zoomOptions = [0.5, 0.75, 1, 1.25, 1.5, 2, 3, 4]
    const widthPDF = zoomOptions[zoom] * (895 / 1.5)

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setDisplay(true);
    }

    useEffect(() => {
        if (open) setTimeout(() => {
            setWidthPadre(refContent.current.getBoundingClientRect().width);
        }, 0);
    }, [open]);

    useEffect(() => {
        const handleResize = throttle(() => {
            setWidthPadre(refContent.current ? refContent.current.getBoundingClientRect().width : 0);
        }, 200);

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Dialog
            open={open}
            onClose={() => { setOpen(false); setZoom(4) }}
            fullWidth={true}
            maxWidth='md'
        >
            <DialogTitle>{title}</DialogTitle>
            <Divider />
            <DialogContent ref={refContent} style={{ background: 'dimgray', paddingLeft: 0, paddingRight: 0 /* , borderLeft: '2px solid #f4f6f8', borderRight: '2px solid #f4f6f8' */ }}>
                {path.split('.')[path.split('.').length - 1] === 'pdf' ?
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16, marginBottom: 16, paddingLeft: 24, paddingRight: 24, width: widthPadre < widthPDF + 65 ? 'max-content' : '100%' }}>
                        <IconButton disabled={zoom === zoomOptions.length - 1} onClick={() => {
                            setZoom(zoom + 1); setWidthPadre(refContent.current.getBoundingClientRect().width)
                        }}
                            style={{ display: display ? null : 'none', position: 'absolute', top: 90, right: 45 + 45, zIndex: 1, width: 40, height: 40 }}>
                            <ZoomInIcon style={{ width: 30, height: '100%' }} />
                        </IconButton>
                        <IconButton disabled={zoom === 0} onClick={() => {
                            setZoom(zoom - 1); setWidthPadre(refContent.current.getBoundingClientRect().width)
                        }}
                            style={{ display: display ? null : 'none', position: 'absolute', top: 90, right: 45, zIndex: 1, width: 40, height: 40 }}>
                            <ZoomOutIcon style={{ width: 30, height: '100%' }} />
                        </IconButton>
                        <Document
                            file={path}
                            onLoadSuccess={onDocumentLoadSuccess}
                            loading={<span style={{ color: "white" }}>Cargando PDF...</span>}
                        >
                            <Page renderMode='canvas' pageNumber={pageNumber} width={widthPDF} renderTextLayer={false} renderAnnotationLayer={false} loading={<span style={{ color: "white" }}>Cargando página...</span>} />
                        </Document>
                    </div>
                    :
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16, marginBottom: 16, paddingLeft: 24, paddingRight: 24, width: '100%' }}>
                        <Zoom overlayBgColorEnd='rgba(33, 33, 33, 1)'><img style={{ width: '100%', height: 'auto', maxWidth: widthPDF * 1.5 }} src={path} alt='iva1' /></Zoom>
                    </div>
                }
            </DialogContent>
            <Divider />
            <DialogActions style={{ paddingRight: 16, paddingLeft: 16, paddingBottom: 24, paddingTop: 16 }}>
                {path.split('.')[path.split('.').length - 1] === 'pdf' ?
                    <Pagination
                        color='primary'
                        count={numPages}
                        onChange={(e, v) => { setPageNumber(v) }}
                    />
                    :
                    null
                }
                <Button onClick={() => { setOpen(false); setZoom(4) }} size='small' color='secondary' variant='contained'>Cerrar</Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogoDocumento